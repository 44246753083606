
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class RoleListRow extends Vue {
  public roles = 'Sin rol en Coverme'
  public level = 0

  @Prop()
  public readonly role!: any

  public mounted() {
    this.role.forEach((args: any) => {
      switch (args.name) {
        case 'coverme_admin':
          if (this.level < 1) this.roles = args.formal_name
          this.level = 1
          break
        case 'coverme_client_admin':
          if (this.level < 2) this.roles = args.formal_name
          this.level = 2
          break
        case 'coverme_super_admin':
          if (this.level < 3) this.roles = args.formal_name
          this.level = 3
          break
      }
    })
  }
}
