
import { Vue, Component } from 'vue-property-decorator'
import AccountMenu from '@/components/AccountComponents/AccountMenu.vue'

@Component({
  components: {
    AccountMenu
  }
})
export default class Toolbar extends Vue {}
