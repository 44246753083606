
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'
import Pagination from '@/components/TableComponents/Pagination.vue'

@Component({
  components: {
    Pagination
  }
})
export default class UserPagination extends Vue {
  @Prop(Number)
  public readonly itemsPerPage!: number

  handleUpdatePage($event: any) {
    Store.commit('users/mutate', {
      property: 'root.pagination.currentPage',
      with: $event
    })
    Store.dispatch('users/fetchRoot', () => true)
  }
}
