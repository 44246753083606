import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const devices: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      data: [],
      status: 'fetching'
    },
    search_type: 0,
    clients_data: []
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          data: [],
          status: 'fetching'
        }
      })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        commit('mutate', {
          property: 'root.status',
          with: 'fetching'
        })
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'devices',
          data: {
            company_id: this.state.session.company[0].id,
            role: this.state.session.data.role,
            search_type: this.state.devices.search_type
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root.data',
            with: response.data.original.data
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'root.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchLatest({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'devices/latest', {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'latest',
            with: response.data.original
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchClientsData({ commit, dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'devices/assistance',
          data: {
            company_id: this.state.session.company[0].id,
            role: this.state.session.data.role,
            search_type: this.state.devices.search_type
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'clients_data',
            with: response.data
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
