
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class ActionsForCodes extends Vue {
  public dialogDrop = false
  public valid = true
  public loading = false

  @Prop()
  public readonly value!: any

  onClickClose() {
    this.dialogDrop = false
  }

  public async handleDelete() {
    this.loading = true
    Store.commit('codes/mutate', {
      property: 'openCode.id',
      with: this.value
    })
    await Store.dispatch('codes/burnCode')
    await Store.dispatch('codes/fetchRoot', () => true)
    Store.dispatch('openProduct/fetchDiscounts', () => true)
    this.loading = false
  }
}
