import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const products: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      data: [],
      status: 'fetching'
    },
    companyId: '',
    products_data: []
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          data: [],
          status: 'fetching'
        },
        companyId: ''
      })
    },
    resetProducts(state) {
      Object.assign(state, {
        products_data: []
      })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products',
          data: {
            id: this.state.products.companyId
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root',
            with: response.data.original
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async fetchProductsData({ commit, dispatch }, type) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/all/' + type,
          data: {
            id: this.state.session.company[0].id,
            role: this.state.session.data.role
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'products_data',
            with: response.data.original.data
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
