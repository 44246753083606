import { Module } from 'vuex'
import Axios from 'axios'
import PhoneModels from '@/assets/misc/PhoneModels'
import * as VueDeepSet from 'vue-deepset'

export const phones: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      data: [],
      status: 'fetching'
    },
    selected: {
      data: [],
      status: 'fetching'
    },
    filtered: {
      data: [],
      status: 'fetching'
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          data: [],
          status: 'fetching'
        },
        selected: {
          data: [],
          status: 'fetching'
        },
        filtered: {
          data: [],
          status: 'fetching'
        }
      })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'phones/all', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.state.session.data.access_token
          }
        }).then((response) => {
          response.data.original.data.filter((data: any) => {
            for (let i = 0; i < PhoneModels.length; i++) {
              const phone = PhoneModels[i]
              if (data.name == phone.name) {
                data.brand.name = phone.brand
              }
            }
          })
          commit('mutate', {
            property: 'root.data',
            with: response.data.original.data
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'root.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchFiltered({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'phones/per_test/' + this.state.openProduct.root.data.id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.state.session.data.access_token
          }
        }).then((response) => {
          commit('mutate', {
            property: 'filtered.data',
            with: response.data.original.data
          })
          commit('mutate', {
            property: 'filtered.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'filtered.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchSelected({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'phones/selected/' + this.state.openProduct.root.data.id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.state.session.data.access_token
          }
        }).then((response) => {
          commit('mutate', {
            property: 'selected.data',
            with: response.data.data
          })
          commit('mutate', {
            property: 'selected.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'selected.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async save({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'phones/save',
          data: {
            phones: this.state.phones.selected.data,
            tests: []
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Operación realizada correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
