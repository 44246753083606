
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'
import RoleListRow from '@/components/UserComponents/RoleListRow.vue'
import UsersCardsItemOptionsButton from '@/components/UserComponents/UsersCardsItemOptionsButton.vue'
import UserPagination from '@/components/UserComponents/UserPagination.vue'

@Component({
  components: {
    RoleListRow,
    UsersCardsItemOptionsButton,
    UserPagination
  },
  beforeRouteEnter(to, from, next) {
    Store.commit('users/mutate', {
      property: 'root.pagination.itemsPerPage',
      with: 10
    })
    next()
  }
})
export default class UsersList extends Vue {
  public dialog = false
  public page = 1
  public pageCount = 0

  public get headers() {
    const headers = [
      {
        text: 'Nombre',
        align: 'left',
        value: 'name',
        sortable: true
      },
      {
        text: 'Apellido',
        value: 'last_name',
        sortable: true
      },
      {
        text: 'DNI',
        value: 'dni',
        sortable: true
      },
      {
        text: 'Correo electrónico',
        value: 'email',
        sortable: true
      },
      {
        text: 'Nivel',
        value: 'role',
        sortable: false
      },

      {
        text: 'Opciones',
        value: 'options',
        sortable: false
      }
    ]

    return headers
  }

  public async handleOpenDialog($event: any, item: any) {
    this.$store.dispatch('openUser/setOpenId', item.id)
    await this.$store.dispatch('openUser/fetchDetails', () => true)
    this.dialog = true
  }
}
