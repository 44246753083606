
import { Vue, Component } from 'vue-property-decorator'
import RouterGoDeviceDetailsBtn from '@/components/RouterComponents/RouterGoDeviceDetailsBtn.vue'
import OverlayEmpty from '@/components/AppBaseComponents/OverlayEmpty.vue'
import Store from '@/store'

@Component({
  components: {
    RouterGoDeviceDetailsBtn,
    OverlayEmpty
  }
})
export default class search_devices extends Vue {
  public items = [
    { name: 'Identificador', type: 'id' },
    { name: 'IMEI', type: 'imei' },
    { name: 'Email', type: 'email' }
  ]

  public imei_header = [
    // { text: 'IMEI', value: 'imeis', sortable: false },
    { text: 'IMEI 1', value: 'imei_1' },
    { text: 'IMEI 2', value: 'imei_2' },
    { text: 'Fecha de creación', value: 'created_at' }
  ]
  public email_header = [
    { text: 'Email', value: 'email' },
    { text: 'Fecha de creación', value: 'created_at' }
  ]
  public id_headers = [
    { text: 'Identificador', value: 'trust_id' },
    { text: 'Fecha de creación', value: 'created_at' },
    { text: 'Detalles', value: 'details' }
  ]
  public select = this.items[0]
  public identifier = ''

  public payload = {
    type: this.select.type,
    identifier: this.identifier.toString()
  }

  public search_results = {
    imeis: [],
    emails: [],
    indetifier: []
  }

  get search_null() {
    let results = this.search_results
    if (results.imeis.length == 0 && results.emails.length == 0 && results.indetifier.length == 0) {
      return true
    }
    return false
  }
  public data_status = ''

  async search(payload: unknown) {
    this.data_status = 'loading'
    await Store.dispatch('openDevice/searchDeviceIds', payload).then(() => {
      this.search_results = Store.state.openDevice.identifiers.data
    })
    this.data_status = 'fulfilled'
  }
}
