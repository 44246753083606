
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class RouterGoDeviceDetailsBtn extends Vue {
  @Prop({ type: String, required: true })
  public readonly trustId!: string

  @Prop()
  public readonly type!: any

  public device_type = this.type.toString()
}
