
import { Vue, Component } from 'vue-property-decorator'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import CreatePromotionBtn from '@/components/ProductComponents/Promotions/CreatePromotionBtn.vue'

import PromotionsDataTable from '@/components/ProductComponents/Promotions/PromotionsDataTable.vue'

@Component({
  components: {
    SearchBox,
    CreatePromotionBtn,
    PromotionsDataTable
  }
})
export default class DetailsPromotionCard extends Vue {
  public viewGroup = 'table'
  public error = false
  public loading = false
  public searchPromo = ''
}
