
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'

import numeral from 'numeral'
import moment from 'moment'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import RouterGoProductDetailsBtn from '@/components/RouterComponents/RouterGoProductDetailsBtn.vue'
import ProductOptions from '@/components/ProductComponents/ProductOptions.vue'
import ActionsForPromotion from '@/components/ProductComponents/Promotions/ActionsForPromotion.vue'
import CodesDataTable from '@/components/ProductComponents/Promotions/CodesDataTable.vue'

@Component({
  components: {
    RouterGoProductDetailsBtn,
    ProductOptions,
    ActionsForPromotion,
    CodesDataTable,
    SearchBox
  }
})
export default class PromotionsDataTable extends Vue {
  public viewGroup = 'table'
  public discountName = ''
  public searchPromo = ''

  @Prop(String)
  public readonly search!: string

  @Prop()
  public readonly value!: any

  @Prop(Boolean)
  public readonly hideControls!: boolean

  public formatted_price($price: any, $type: any) {
    if ($type === 1) {
      return (
        parseInt(Store.state.openProduct.root.data.price) -
        (parseInt(Store.state.openProduct.root.data.price) * parseInt($price)) / 100
      )
    }
    return numeral(parseInt(Store.state.openProduct.root.data.price) - parseInt($price)).format('0,0')
  }

  async handleOpenDetail(item: any, view: any) {
    this.viewGroup = view
    if (view === 'details') {
      Store.commit('codes/mutate', {
        property: 'root.data.codes',
        with: item.discount_codes
      })
      Store.commit('codes/mutate', {
        property: 'openCode.discount_id',
        with: item.id
      })
      if (item.date != null) {
        Store.commit('codes/mutate', {
          property: 'root.data.expires',
          with: 1
        })
        Store.commit('codes/mutate', {
          property: 'root.data.expiration_date',
          with: item.date
        })
      } else {
        Store.commit('codes/mutate', {
          property: 'root.data.expires',
          with: 2
        })
        Store.commit('codes/mutate', {
          property: 'root.data.expiration_date',
          with: null
        })
      }
      this.discountName = item.name
    }
    this.$emit(view)
  }

  public formatted_date(date: any) {
    if (date != null) {
      return moment(date).format('DD/MM/YYYY')
    } else {
      return '-'
    }
  }

  public formatted_chip($discount: any, $type: any) {
    if ($type === 1) {
      return '-' + $discount + '%'
    }
    return '-$' + $discount
  }

  public get headers() {
    const headers = [
      {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'N° de usos',
        value: 'stock',
        align: 'center',
        sortable: true
      },
      {
        text: 'Expiración',
        value: 'date',
        align: 'center',
        sortable: true
      },
      {
        text: 'N° de códigos generados',
        align: 'center',
        value: 'gen',
        sortable: false
      },
      {
        text: 'Descuento Aplicado',
        value: 'perc',
        align: 'center',
        sortable: true
      },
      {
        text: 'Fecha de creación',
        value: 'created_at',
        align: 'center',
        sortable: true
      },

      {
        text: 'Eliminar',
        value: 'actions',
        align: 'center',
        sortable: false
      },

      {
        text: 'Códigos generados',
        value: 'details',
        align: 'center',
        sortable: false
      }
    ]

    return headers
  }
}
