import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const openGroup: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      data: {
        id: '',
        status: '',
        name: '',
        price: '',
        created_at: '',
        updated_at: '',
        assistance_id: ''
      },
      status: 'fetching',
      company_uid: 0
    },
    products: {
      data: [],
      status: 'fetching'
    },
    devices: {
      data: [],
      status: 'fetching',
      openDeviceId: ''
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          data: {
            id: '',
            status: '',
            name: '',
            price: '',
            created_at: '',
            updated_at: '',
            assistance_id: ''
          },
          status: 'fetching',
          company_uid: 0
        },
        products: {
          data: [],
          status: 'fetching'
        },
        devices: {
          data: [],
          status: 'fetching',
          openDeviceId: ''
        }
      })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'groups/details/' + this.state.openGroup.root.data.id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root.data.name',
            with: response.data.data[0].name
          })
          commit('mutate', {
            property: 'root.data.price',
            with: parseFloat(response.data.data[0].price)
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'root.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchDevices({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'groups/devices/' + this.state.openGroup.root.data.id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'devices.data',
            with: response.data.data
          })
          commit('mutate', {
            property: 'devices.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'root.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchProducts({ commit, dispatch }) {
      try {
        if (this.state.openGroup.root.company_uid == 0 || !this.state.openGroup.root.company_uid) {
          commit('mutate', {
            property: 'root.company_uid',
            with: this.state.session.company[0].id
          })
        }
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/for_group',
          data: {
            id: this.state.openGroup.root.company_uid
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'products.data',
            with: response.data.original.data
          })
          commit('mutate', {
            property: 'products.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'products.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async create({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'groups/create',
          data: {
            assistance_id: this.state.openGroup.root.data.assistance_id,
            name: this.state.openGroup.root.data.name,
            price: this.state.openGroup.root.data.price
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Grupo creado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async update({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'groups/update',
          data: {
            id: this.state.openGroup.root.data.id,
            name: this.state.openGroup.root.data.name,
            price: this.state.openGroup.root.data.price
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Grupo actualizado correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async delete({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'groups/delete',
          data: {
            id: this.state.openGroup.root.data.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Grupo eliminado correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async disableDevice({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/device/disable',
          data: {
            id: this.state.openProduct.openDevice.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Dispositivo deshabilitado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async disableDeviceFromGroup({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'groups/device/disable',
          data: {
            id: this.state.openGroup.devices.openDeviceId
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Grupo actualizado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
