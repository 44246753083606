
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class Reload extends Vue {
  public mounted() {
    if (!Store.state.dashboard.refresh) {
      Store.commit('dashboard/mutate', {
        property: 'refresh',
        with: true
      })
      location.reload()
    }
  }
}
