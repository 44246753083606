
import { Vue, Component } from 'vue-property-decorator'
import GroupsDataTable from '@/components/ProductComponents/Groups/GroupsDataTable.vue'

@Component({
  components: {
    GroupsDataTable
  }
})
export default class DetailsGroupCard extends Vue {
  public error = false
  public loading = false
}
