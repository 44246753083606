
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'
import axios from 'axios'

export interface INavigationDrawerItem {
  icon: string
  title: string
  to: string
  visible: boolean
  solo: boolean
  subitem: INavigationDrawerItem[]
}

@Component({
  components: {}
})
export default class NavigationDrawer extends Vue {
  public selection: any = ''
  public readonly items: any[] = [
    {
      icon: 'mdi-home',
      title: 'Tablero',
      to: '/tablero',
      hidden: false
    },
    {
      icon: 'mdi-collage',
      title: 'Productos',
      to: '/productos',
      hidden: false
    },
    {
      icon: 'mdi-cellphone',
      title: 'Dispositivos',
      hidden: false,
      to: '/devices/all'
      /* children: [
        {
          icon: 'mdi-format-list-bulleted',
          title: 'Todos',
          to: '/devices/all'
        },
        {
          icon: 'mdi-format-list-bulleted',
          title: 'Por dispositivo',
          to: '/devices/search',
          hidden: true
        }
      ] */
    },
    {
      icon: 'mdi-format-list-bulleted',
      title: 'Diagnósticos',
      hidden: this.$store.state.session.data.role !== 'coverme_client_admin',
      to: '/diagnostics/parameters'
    },
    /* { Sección en desarrollo, inhabilitada por tiempo indefinido
      icon: 'mdi-bell',
      title: 'Alertas',
      hidden: this.$store.state.session.data.role === 'coverme_admin',
      to: '/alerts'
    }, */
    {
      icon: 'mdi-format-list-bulleted',
      title: 'Diagnósticos',
      group: '/diagnostics/*',
      hidden: this.$store.state.session.data.role != 'coverme_super_admin',
      children: [
        {
          icon: 'mdi-format-list-bulleted',
          title: 'Parámetros',
          to: '/diagnostics/parameters'
        },
        {
          icon: 'mdi-account-supervisor',
          title: 'Por dispositivo',
          to: '/diagnostics/devices'
        }
      ]
    },
    {
      icon: 'mdi-cog',
      title: 'Configuración',
      hidden: this.$store.state.session.data.role === 'coverme_admin',
      group: '/config/*',
      children: [
        {
          icon: 'mdi-account-multiple',
          title: 'Usuarios',
          to: '/config/users',
          hidden: this.$store.state.session.data.role === 'coverme_admin'
        },

        {
          icon: 'mdi-account-supervisor',
          title: 'Clientes',
          to: '/config/clients',
          hidden:
            this.$store.state.session.data.role === 'coverme_admin' ||
            this.$store.state.session.data.role === 'coverme_client_admin'
        },
        {
          icon: 'mdi-domain',
          title: 'Compañías',
          to: '/config/companies',
          hidden:
            this.$store.state.session.data.role === 'coverme_admin' ||
            this.$store.state.session.data.role === 'coverme_client_admin'
        }
      ]
    }
  ]
  public showServerStatus = async () => {
    await axios({
      method: 'get',
      url: process.env.VUE_APP_BACKEND + 'healthy/bbdd'
    })
      .then((response) => {
        let status = response.data.isHealthy
        if (status == true) {
          Store.dispatch('system/showSuccess', 'La base de datos se encuentra activa')
        } else {
          Store.dispatch('system/showWarning', 'No se ha podido consultar la base de datos')
        }
      })

      .catch(() => {
        Store.dispatch('system/showError', 'La base de datos no responde')
      })
  }
}
