
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

import RouterGoBackBtn from '@/components/RouterComponents/RouterGoBackBtn.vue'
import IndicatorCards from '@/components/HelpComponents/IndicatorCards.vue'
import CreatePromotionBtn from '@/components/ProductComponents/Promotions/CreatePromotionBtn.vue'
import DetailsGroupCard from '@/components/ProductComponents/Groups/DetailsGroupCard.vue'
import DetailsPhoneCard from '@/components/ProductComponents/Phones/DetailsPhoneCard.vue'
import DetailsDeviceCard from '@/components/ProductComponents/Devices/DetailsDeviceCard.vue'
import DetailsPromotionCard from '@/components/ProductComponents/Promotions/DetailsPromotionCard.vue'
import ProductTests from '@/components/ProductComponents/ProductTests.vue'

@Component({
  components: {
    RouterGoBackBtn,
    IndicatorCards,
    CreatePromotionBtn,
    DetailsGroupCard,
    DetailsPhoneCard,
    DetailsDeviceCard,
    DetailsPromotionCard,
    ProductTests
  }
})
export default class ProductDetailPage extends Vue {
  public items: string[] = []
  public tab = null
  public status = 'fetching'

  async beforeRouteEnter(to: any, from: any, next: any) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.id',
      with: to.params.id
    })

    Store.commit('openProduct/mutate', {
      property: 'reloadSub.phones',
      with: true
    })

    Store.commit('openProduct/mutate', {
      property: 'reloadSub.devices',
      with: true
    })

    Store.commit('openProduct/mutate', {
      property: 'reloadSub.groups',
      with: true
    })

    Store.commit('openProduct/mutate', {
      property: 'reloadSub.discounts',
      with: true
    })

    next((vm: InstanceType<typeof ProductDetailPage>) => {
      vm.status = 'fetching'
    })
  }

  async mounted() {
    await Store.dispatch('openProduct/fetchRoot', () => true)

    if (Store.state.openProduct.root.data.group == 1)
      this.items = ['Dispositivos', 'Grupos', 'Detalle de pagos', 'Códigos']
    else this.items = ['Dispositivos', 'Detalle de pagos', 'Códigos']

    this.status = 'fulfilled'

    await Store.dispatch('phones/fetchFiltered', () => true)
    await Store.dispatch('phones/fetchSelected', () => true)

    Store.commit('openProduct/mutate', {
      property: 'reloadSub.phones',
      with: false
    })

    Store.commit('openProduct/mutate', {
      property: 'status',
      with: 'fulfilled'
    })
  }

  public async handleTab(item: any) {
    if (item === 'Dispositivos' && Store.state.openProduct.reloadSub.phones) {
      Store.commit('phones/mutate', {
        property: 'filtered.status',
        with: 'fetching'
      })
      Store.commit('phones/mutate', {
        property: 'selected.status',
        with: 'fetching'
      })
      await Store.dispatch('phones/fetchFiltered', () => true)
      await Store.dispatch('phones/fetchSelected', () => true)
      Store.commit('openProduct/mutate', {
        property: 'reloadSub.phones',
        with: false
      })
    } else if (item === 'Detalle de pagos' && Store.state.openProduct.reloadSub.devices) {
      Store.commit('openProduct/mutate', {
        property: 'devices.status',
        with: 'fetching'
      })
      await Store.dispatch('openProduct/fetchDevices', () => true)
      Store.commit('openProduct/mutate', {
        property: 'reloadSub.devices',
        with: false
      })
    } else if (item === 'Grupos' && Store.state.openProduct.reloadSub.groups) {
      Store.commit('groups/mutate', {
        property: 'root.status',
        with: 'fetching'
      })
      await Store.dispatch('groups/fetchRoot', () => true)
      Store.commit('openProduct/mutate', {
        property: 'reloadSub.groups',
        with: false
      })
    } else if (item === 'Códigos' && Store.state.openProduct.reloadSub.discounts) {
      Store.commit('openProduct/mutate', {
        property: 'discounts.status',
        with: 'fetching'
      })
      await Store.dispatch('openProduct/fetchDiscounts', () => true)
      Store.commit('openProduct/mutate', {
        property: 'reloadSub.discounts',
        with: false
      })
    }
  }
}
