
import { Component, Vue } from 'vue-property-decorator'
import DevicesDataTable from '@/components/DeviceComponents/DevicesDataTable.vue'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import PendingTable from '@/components/AlertComponents/PendingTable.vue'
import ApprovedTable from '@/components/AlertComponents/ApprovedTable.vue'
import RejectedTable from '@/components/AlertComponents/RejectedTable.vue'

@Component({
  components: {
    DevicesDataTable,
    SearchBox,
    PendingTable,
    ApprovedTable,
    RejectedTable
  }
})
export default class devices extends Vue {
  public search = ''
  public tab = null
}
