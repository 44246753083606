import { render, staticRenderFns } from "./CreatePromotionClientSelect.vue?vue&type=template&id=68d1da9e"
import script from "./CreatePromotionClientSelect.vue?vue&type=script&lang=ts"
export * from "./CreatePromotionClientSelect.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports