import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { navigationDrawer } from '@/store/modules/navigationDrawer'
import { session } from '@/store/modules/session'
import { system } from '@/store/modules/system'

import { dashboard } from '@/store/modules/dashboard'
import { products } from '@/store/modules/products'
import { devices } from '@/store/modules/devices'
import { alerts } from '@/store/modules/alerts'
import { brands } from '@/store/modules/brands'
import { phones } from '@/store/modules/phones'
import { groups } from '@/store/modules/groups'
import { codes } from '@/store/modules/codes'

import { openAlert } from '@/store/modules/openAlert'
import { openProduct } from '@/store/modules/openProduct'
import { openPromotion } from '@/store/modules/openPromotion'
import { openModel } from '@/store/modules/openModel'
import { openCompany } from '@/store/modules/openCompany'
import { openDevice } from '@/store/modules/openDevice'
import { openUser } from '@/store/modules/openUser'
import { openDiagnostic } from '@/store/modules/openDiagnostic'
import { openClient } from '@/store/modules/openClient'
import { openGroup } from '@/store/modules/openGroup'

import { temp } from '@/store/modules/temp'

import { companies } from '@/store/modules/companies'
import { clients } from '@/store/modules/clients'
import { users } from '@/store/modules/users'

import { devicePickers } from '@/store/modules/devicePickers'
import { misc } from '@/store/modules/misc'

import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  modules: {
    navigationDrawer,
    session,
    system,
    dashboard,
    products,
    devices,
    alerts,
    brands,
    groups,
    phones,
    codes,
    openAlert,
    openProduct,
    openPromotion,
    openModel,
    openCompany,
    openDevice,
    openGroup,

    companies,
    clients,
    users,
    openUser,
    openDiagnostic,
    openClient,
    temp,

    devicePickers,
    misc
  },
  plugins: [
    createPersistedState({
      paths: ['session']
    })
  ]
})
