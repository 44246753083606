import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const devicePickers: Module<any, any> = {
  namespaced: true,
  state: () => ({ filter: '', data: [], selected: [] }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, { filter: '', data: [], selected: [] })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'devices/search/' + this.state.devicePickers.filter, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root.data',
            with: response.data.original.data[0]
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
        commit('mutate', {
          property: 'status',
          with: 'fulfilled'
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
