
import { Vue, Component, Ref } from 'vue-property-decorator'
import { IOn, VForm } from '@/types'
import Store from '@/store'
import ClientColorPicker from '@/components/ClientComponents/ClientColorPicker.vue'

@Component({
  components: {
    ClientColorPicker
  }
})
export default class ManageClienteBasic extends Vue {
  public color1 = false
  public color2 = false
  public error = false
  public loading = false
  public dialog = false

  @Ref('clientForm')
  private readonly clientForm!: VForm

  public async handleOpenDialog(on: IOn, event: Event) {
    on.click(event)
  }

  public async onClickHandler(on: IOn, $event: Event) {
    Store.commit('openClient/resetState')
    await Store.dispatch('openClient/fetchDetails', () => true)
    on.click($event)
    this.dialog = true
  }

  public onClickClose() {
    this.dialog = false
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    setTimeout(() => (this.loading = true), 1)
    setTimeout(async () => {
      if (this.clientForm.validate()) {
        try {
          await Store.dispatch('openClient/updateClient')
          this.dialog = false
          Store.commit('openClient/resetState')
          await Store.dispatch('clients/fetchRoot', () => true)
        } catch (e) {
          this.error = true
        }
      }
      this.loading = false
    }, 1000)

    this.loading = false
  }
}
