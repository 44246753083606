
import { Vue, Component } from 'vue-property-decorator'
import CompaniesList from '@/components/CompanyComponents/CompaniesList.vue'
import CreateCompanyBtn from '@/components/CompanyComponents/CreateCompanyBtn.vue'

@Component({
  components: {
    CompaniesList,
    CreateCompanyBtn
  }
})
export default class ConfigurationCompaniesPage extends Vue {}
