
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'
import DropProduct from '@/components/DeviceComponents/DropProduct.vue'
import SinisterProduct from '@/components/DeviceComponents/SinisterProduct.vue'

@Component({
  components: { DropProduct, SinisterProduct }
})
export default class DeviceProducts extends Vue {
  public get headers() {
    const headers = [
      {
        text: 'Nombre',
        align: 'left',
        sortable: false,
        value: 'assistance.name'
      },
      {
        text: 'Moneda',
        value: 'assistance.currency',
        sortable: false
      },
      {
        text: 'Precio',
        value: 'price',
        sortable: false
      },
      {
        text: 'Estado',
        value: 'chip',
        sortable: false
      },
      {
        text: 'Anular',
        value: 'drop',
        sortable: false
      },
      {
        text: 'Siniestrar',
        value: 'sinister',
        sortable: false
      }
    ]

    return headers
  }
  get thousands_sep() {
    return Store.state.openClient.config.data.thousands_sep
  }
}
