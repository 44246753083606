
import { Component, Vue, Prop } from 'vue-property-decorator'
import DiagnosticPicker from '@/components/DiagnosticComponents/DiagnosticPicker.vue'
import OverlayLoading from '@/components/AppBaseComponents/OverlayLoading.vue'

@Component({
  components: {
    DiagnosticPicker,
    OverlayLoading
  }
})
export default class PhoneTable extends Vue {
  public loading = false

  @Prop({ type: Boolean, default: false })
  public readonly new!: boolean

  @Prop(String)
  public readonly search!: string

  public get headers() {
    const headers = [
      {
        text: 'Modelo',
        value: 'name',
        sortable: true
      },
      {
        text: 'Marca',
        value: 'brand.name',
        sortable: true
      },
      {
        text: 'Diagnósticos',
        value: 'tests',
        sortable: false
      }
    ]

    return headers
  }
}
