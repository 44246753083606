
import { Component, Vue } from 'vue-property-decorator'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import RouterGoDeviceDetailsBtn from '@/components/RouterComponents/RouterGoDeviceDetailsBtn.vue'
import Store from '@/store'

@Component({
  components: {
    SearchBox,
    RouterGoDeviceDetailsBtn
  }
})
export default class PhoneSelected extends Vue {
  public loading = false
  public search = ''

  public get headers() {
    const headers = [
      {
        text: 'Quitar',
        value: 'remove',
        sortable: false
      },
      {
        text: 'Modelo',
        value: 'phone.name',
        sortable: true
      },
      {
        text: 'Marca',
        value: 'phone.brand.name',
        sortable: true
      }
    ]

    return headers
  }

  async handleRemove(item: any) {
    const index = Store.state.phones.selected.data.findIndex(
      (obj: any) => obj.assistance_id === item.assistance_id && obj.phone_id === item.phone.id
    )
    const newData = [
      ...Store.state.phones.selected.data.slice(0, index),
      ...Store.state.phones.selected.data.slice(index + 1)
    ]
    Store.commit('phones/mutate', {
      property: 'selected.data',
      with: newData
    })
    Store.commit('temp/mutate', {
      property: 'changes',
      with: true
    })
    /*
    try {
      await Axios({
        method: 'post',
        url: process.env.VUE_APP_BACKEND + 'phones/remove/single',
        data: {
          id: id
        },
        headers: {
          'Content-Type': 'text/plain',
          Authorization: `Bearer ${Store.state.session.data.access_token}`
        }
      }).then(() => {
        Store.dispatch('phones/fetchSelected', () => true)
      })
    } catch (e) {
      Store.dispatch('system/showError', { error: e }, { root: true })
    }
    */
  }
}
