
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'
import RouterGoProductDetailsBtn from '@/components/RouterComponents/RouterGoProductDetailsBtn.vue'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import CreateGroupBtn from '@/components/ProductComponents/Groups/CreateGroupBtn.vue'
import CreateBulkBtn from '@/components/ProductComponents/Groups/CreateBulkBtn.vue'
import GroupOptions from '@/components/ProductComponents/Groups/GroupOptions.vue'
import GroupDetails from '@/components/ProductComponents/Groups/GroupDetails.vue'

@Component({
  components: {
    RouterGoProductDetailsBtn,
    CreateGroupBtn,
    CreateBulkBtn,
    SearchBox,
    GroupOptions,
    GroupDetails
  }
})
export default class GroupsDataTable extends Vue {
  public viewGroup = 'table'
  public searchGroup = ''

  @Prop(String)
  public readonly search!: string

  @Prop()
  public readonly value!: any

  @Prop()
  public readonly status!: any

  async beforeRouteEnter() {
    this.viewGroup = 'table'
  }

  public get headers() {
    const headers = [
      {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Valor',
        value: 'price',
        align: 'center',
        sortable: true
      },
      {
        text: 'Primer ingreso',
        value: 'date',
        sortable: false
      },
      {
        text: 'Dispositivos',
        value: 'bulk',
        sortable: false
      },
      {
        text: 'Opciones',
        value: 'options',
        sortable: false
      },
      {
        text: 'Detalle',
        value: 'details',
        sortable: false
      }
    ]

    return headers
  }

  async handleOpenDetail(group_id: any, group_name: any, view: any) {
    this.viewGroup = view
    await Store.commit('openGroup/mutate', {
      property: 'devices.status',
      with: 'fetching'
    })
    if (view === 'details') {
      await Store.commit('openGroup/mutate', {
        property: 'root.data.id',
        with: group_id
      })
      await Store.commit('openGroup/mutate', {
        property: 'root.data.name',
        with: group_name
      })

      Store.dispatch('openGroup/fetchDevices', () => true)
    } else {
      Store.commit('openGroup/mutate', {
        property: 'devices.data',
        with: []
      })
      Store.commit('openGroup/mutate', {
        property: 'devices.status',
        with: 'fetching'
      })
    }
  }

  get clientConfig() {
    return {
      thousands_sep: Store.state.openClient.config.data.thousands_sep,
      dec_point: Store.state.openClient.config.data.dec_point
    }
  }
}
