
import { Vue, Component, Ref } from 'vue-property-decorator'
import { VForm } from '@/types'
import Store from '@/store'

@Component({
  components: {}
})
export default class DiagnosticCamera extends Vue {
  public cardDeactivation = true
  public error = false
  public loading = false

  @Ref('cameraForm')
  private readonly cameraForm!: VForm

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    setTimeout(() => (this.loading = true), 1)
    this.cameraForm.resetValidation()

    setTimeout(async () => {
      if (this.cameraForm.validate()) {
        try {
          await Store.dispatch('openDiagnostic/saveParameters')
        } catch (e) {
          this.error = true
        }
      }
      this.loading = false
    }, 1000)

    this.loading = false
  }
}
