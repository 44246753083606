import { Module } from 'vuex'
import { mutate } from '../store.mutations'
import moment from 'moment'

const DEFAULT_ERROR_MESSAGE =
  'Ocurrio un error, por favor espera un momento y vuelve a intentar. Si el problema persiste, porfavor contacta al equipo de soporte Coverme.'

export const system: Module<any, any> = {
  namespaced: true,
  state: () => ({
    progressBar: {
      progress: 0,
      error: false
    },
    snackBar: {
      color: '',
      message: '',
      show: false
    },
    appVersion: '',
    appWorker: false
  }),
  mutations: {
    mutate,
    resetState(state) {
      Object.assign(state, {
        progressBar: {
          progress: 0,
          error: false
        },
        snackBar: {
          color: '',
          message: '',
          show: false
        },
        appVersion: '',
        appWorker: false
      })
    }
  },
  getters: {
    fullUrlAuth() {
      return (
        process.env.VUE_APP_USER_SIGN_IN +
        '?return_to=' +
        encodeURIComponent(
          process.env.VUE_APP_RETURN +
            '?client_id=' +
            process.env.VUE_APP_CLIENT_ID +
            '&redirect_uri=' +
            encodeURIComponent(process.env.VUE_APP_REDIRECT_URL) +
            '&response_type=code&scope=' +
            encodeURIComponent(process.env.VUE_APP_SCOPES) +
            '&state=' +
            process.env.VUE_APP_STATE +
            '&code_challenge=' +
            process.env.VUE_APP_CODE_CHALLENGE +
            '&code_challenge_method=' +
            process.env.VUE_APP_CODE_CHALLENGE_METHOD +
            '&response_mode=query'
        )
      )
    }
  },
  actions: {
    showError({ commit }, payload: string | { error: any; message?: string }) {
      console.error(payload)
      commit('mutate', {
        property: 'snackBar',
        with: {
          color: 'error',
          show: true,
          ...(typeof payload === 'string'
            ? { message: payload }
            : {
                message: payload.message ?? DEFAULT_ERROR_MESSAGE,
                error: payload.error ?? {}
              })
        }
      })
      if (moment().isAfter(this.state.session.deadline)) {
        this.commit('session/resetState')
        window.location.replace(this.getters['system/fullUrlAuth'])
      }
    },
    showSuccess({ commit }, message) {
      commit('mutate', {
        property: 'snackBar',
        with: { color: 'success', message, show: true }
      })
    },
    showInfo({ commit }, message) {
      commit('mutate', {
        property: 'snackBar',
        with: { color: 'info', message, show: true }
      })
    },
    showWarning({ commit }, message) {
      console.warn(message)
      commit('mutate', {
        property: 'snackBar',
        with: { color: 'warning', message, show: true }
      })
    }
  }
}
