
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOn } from '@/types'
import Store from '@/store'
import CompanyForm from '@/components/CompanyComponents/CompanyForm.vue'

@Component({
  components: {
    CompanyForm
  }
})
export default class EditCompanyListItem extends Vue {
  public dialog = false

  @Prop()
  private readonly value!: any

  public async handleOpenDialog(on: IOn, event: Event) {
    Store.commit('openCompany/resetState')
    if (this.$refs.formCompany) {
      /* @ts-ignore */
      await this.$refs.formCompany.resetForm()
    }
    Store.commit('openCompany/mutate', {
      property: 'root.id',
      with: this.value.id
    })
    await Store.dispatch('openCompany/fetchDetails', () => true)
    on.click(event)
  }

  public onClickClose() {
    this.dialog = false
  }
}
