
import { Component, Vue, Prop } from 'vue-property-decorator'
import RouterGoDeviceDetailsBtn from '@/components/RouterComponents/RouterGoDeviceDetailsBtn.vue'

@Component({
  components: {
    RouterGoDeviceDetailsBtn
  }
})
export default class BoughtSmallCard extends Vue {
  @Prop()
  public readonly product!: any
}
