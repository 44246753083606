<template>
  <v-snackbar
    bottom
    right
    :color="$store.state.system.snackBar.color"
    :value="$store.state.system.snackBar.show"
    :timeout="$store.state.system.snackBar.color === 'success' ? 5000 : 30000"
    @input="
      $store.commit('system/mutate', {
        property: 'snackBar.show',
        with: $event
      })
    "
  >
    {{ $store.state.system.snackBar.message }}
    <v-btn
      dark
      text
      @click="
        $store.commit('system/mutate', {
          property: 'snackBar.show',
          with: false
        })
      "
    >
      CERRAR
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {}
</script>
