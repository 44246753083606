import { Module } from 'vuex'
import * as VueDeepSet from 'vue-deepset'
import Axios from 'axios'

export const openCompany: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      id: ''
    },
    details: {
      company: {
        name: '',
        description: '',
        img: '',
        email: ''
      }
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          id: ''
        },
        details: {
          company: {
            name: '',
            description: '',
            img: '',
            email: ''
          }
        }
      })
    }
  },
  actions: {
    async fetchDetails({ commit, dispatch }) {
      try {
        await Axios.get(`${process.env.VUE_APP_COMPANY}/${this.state.openCompany.root.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'details',
            with: response.data
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async createCompanyFromDetails({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_COMPANY,
          data: this.state.openCompany.details.company,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Compañía creada', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async updateCompanyFromDetails({ dispatch }) {
      try {
        await Axios({
          method: 'put',
          url: process.env.VUE_APP_COMPANY + '/' + this.state.openCompany.root.id,
          data: this.state.openCompany.details.company,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Compañía modificada exitosamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
