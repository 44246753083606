import { Module } from 'vuex'
import Axios from 'axios'
import { genericMutate, genericReset } from '../store.mutations'

const defaultState = () => ({
  openScreenTest: {
    data: [],
    status: 'fetching'
  },
  openProduct: {
    id: ''
  },
  groups: { state: 'fetching', data: [] },
  groupDiagnoses: { state: 'fetching', data: [] }
})

export const openAlert: Module<any, any> = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    mutate: genericMutate,
    resetState: genericReset(defaultState)
  },
  getters: {
    data(state) {
      return state.openScreenTest
    },
  },
  actions: {
    async fetchScreenTest({ commit, dispatch }, screen_test_id) {
      try {
        commit('mutate', {
          property: 'openScreenTest.data',
          with: {}
        })
        commit('mutate', {
          property: 'openScreenTest.status',
          with: 'empty'
        })
        await Axios.get(process.env.VUE_APP_BACKEND + 'alerts/details/' + screen_test_id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'openScreenTest.data',
            with: response.data.data
          })
          commit('mutate', {
            property: 'openScreenTest.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async fetchNextScreenTest({ commit, dispatch }, payload) {
      try {
        commit('mutate', {
          property: 'openScreenTest.data',
          with: {}
        })
        commit('mutate', {
          property: 'openScreenTest.status',
          with: 'empty'
        })
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'alerts/' + payload.direction + '/' + payload.screen_test_id,
          data: {
            company_id: this.state.session.company[0].id,
          },
          headers: {
            "Content-Type": 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          },
          responseType: 'json',
        }).then((response) => {
          commit('mutate', {
            property: 'openScreenTest.data',
            with: response.data.data
          })
          commit('mutate', {
            property: 'openScreenTest.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async evalScreenTest({ dispatch, state },payload) {
      try {
        console.log('Start evaluating screen ...')
        const result = await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'alerts/evaluate',
          data: {
            id: state.openScreenTest.data.id,
            company_id: this.state.session.company[0].id,
            ...payload
          },
          headers: {
            "Content-Type": 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        if(result.data.status == 'SUCCESSFULLY_EVALUATED') {
          dispatch('system/showSuccess', 'Evaluación enviada exitosamente', {
            root: true
          })
        }else if (result.data.status == 'CANNOT_CONNECT_TELCOPS') {
          dispatch('system/showInfo', 'No se pudo comunicar la respuesta al servicio externo. Intente máa tarde.', {
            root: true
          })
        }else if (result.data.status == 'ALREADY_EVALUATED') {
          dispatch('system/showInfo', 'El item ya ha sido evaluado por otro usuario. Intente con otro.', {
            root: true
          })
        } else {
          dispatch('system/showInfo', 'Hubo un problema con la evaluación del registro.', {
            root: true
          })
        }
        
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchProducts({ commit, dispatch }) {
      try {
        const response = await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'devices/products',
          data: {
            id: this.state.openDevice.root.data.id,
            company_id: this.state.session.company[0].id,
            role: this.state.session.data.role
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        commit('mutate', {
          property: 'products.data',
          with: response.data.original.data
        })

        commit('mutate', {
          property: 'products.status',
          with: 'fulfilled'
        })
      } catch (e) {
        commit('mutate', {
          property: 'products.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    
    async fetchGroups(context, trustId) {
      try {
        context.state.groups.state = 'fetching'
        const response = await Axios({
          method: 'GET',
          baseURL: process.env.VUE_APP_BACKEND,
          url: `/device/${trustId}/groups`,
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        context.state.groups.data = response.data.data
        context.state.groups.state = 'fulfilled'
      } catch (error) {
        context.dispatch('system/showError', { error }, { root: true })
        context.state.groups.state = 'error'
      }
    },
    async fetchGroupDiagnoses(context, groupId) {
      try {
        context.commit('mutate', {
          property: 'groupDiagnoses.data',
          with: []
        })
        context.commit('mutate', {
          property: 'groupDiagnoses.state',
          with: 'fetching'
        })
        const response = await Axios({
          method: 'GET',
          baseURL: process.env.VUE_APP_BACKEND,
          url: `/group/${groupId}/diagnostics`,
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        context.commit('mutate', {
          property: 'groupDiagnoses.data',
          with: response.data.data
        })
        context.commit('mutate', {
          property: 'groupDiagnoses.state',
          with: 'fulfilled'
        })
      } catch (error) {
        context.commit('mutate', {
          property: 'groupDiagnoses.state',
          with: 'error'
        })
        context.dispatch('system/showError', { error }, { root: true })
      }
    }
  }
}
