
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ClientSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
  public readonly rules!: any[]

  public mounted() {
    this.$store.dispatch('clients/fetchRoot', () => true)
    if (this.$store.state.session.data.role !== 'coverme_super_admin') {
      this.$store.commit('openUser/mutate', {
        property: 'details.company_uid',
        with: this.$store.state.session.userinfo.company_uid
      })
    }
  }
}
