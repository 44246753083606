
import { Vue, Component } from 'vue-property-decorator'
import ClientsList from '@/components/ClientComponents/ClientsList.vue'
import CreateClientBtn from '@/components/ClientComponents/CreateClientBtn.vue'

@Component({
  components: {
    ClientsList,
    CreateClientBtn
  }
})
export default class CompaniesClientsPage extends Vue {}
