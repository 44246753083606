import Vue from 'vue'

export class thousandsSeparator {
  static install(vue: typeof Vue): void {
    vue.prototype.$separator = separator
  }
}
const reg_exp = new RegExp(/\B(?=(\d{3})+(?!\d))/g)

function separator(num: string, tho_sep: string, dec_sep: string, show_dec: boolean): string {
  if (num.includes(dec_sep)) {
    const str_num = num.toString().split(dec_sep)
    const int = str_num[0].replace(reg_exp, tho_sep)
    const dec = str_num[1]
    switch (show_dec) {
      case true:
        return int + dec_sep + dec

      case false:
        return int

      default:
        break
    }
  }
  if (!num.includes('.') && show_dec) {
    return num.replace(reg_exp, tho_sep) + '.00'
  }
  return num.replace(reg_exp, tho_sep)
}
