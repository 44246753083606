import Axios from 'axios'
import { Module } from 'vuex'
import { genericMutate, genericReset } from '../store.mutations'

const defaultState = () => ({
  root: {
    id: ''
  },
  details: {
    company_uid: '',
    company_name: '',
    email: '',
    dni: '',
    name: '',
    last_name: '',
    role: '',
    img: '',
    invitation: false,
    groupId: '',
    password: ''
  }
})

export const openUser: Module<any, any> = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    mutate: genericMutate,
    resetState: genericReset(defaultState)
  },
  actions: {
    async createUserFromDetails({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_USERS + '/create',
          data: this.state.openUser.details,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Usuario administrador creado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async EditUserFromDetails({ dispatch }) {
      try {
        await Axios({
          method: 'put',
          url: `${process.env.VUE_APP_USERS}/${this.state.openUser.root.id}`,
          data: this.state.openUser.details,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Usuario administrador actualizado correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchDetails({ commit, dispatch }) {
      try {
        await Axios.get(`${process.env.VUE_APP_USERS}/id/${this.state.openUser.root.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'details',
            with: response.data.user
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }

      try {
        await Axios.get(`${process.env.VUE_APP_USERS}/${this.state.openUser.root.id}/role`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          const roles = response.data.roles.map((userRole: any) => userRole.name)
          commit('mutate', {
            property: 'details.role',
            with: roles
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
