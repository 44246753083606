
import { Component, Vue, Prop } from 'vue-property-decorator'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import RouterGoDeviceDetailsBtn from '@/components/RouterComponents/RouterGoDeviceDetailsBtn.vue'
import Axios from 'axios'
import Store from '@/store'

@Component({
  components: {
    SearchBox,
    RouterGoDeviceDetailsBtn
  }
})
export default class PhoneTable extends Vue {
  public loading = false
  public search = ''
  public assistance_id = 0
  public show = false

  @Prop({ type: Boolean, default: false })
  public readonly new!: boolean

  public get headers() {
    const headers = [
      {
        text: 'Modelo',
        value: 'name',
        sortable: true
      },
      {
        text: 'Marca',
        value: 'brand.name',
        sortable: true
      },
      {
        text: 'Agregar',
        value: 'add',
        sortable: false
      }
    ]

    return headers
  }

  async handleAdding(item: any) {
    const index = Store.state.phones.filtered.data.findIndex((obj: any) => obj.id === item.id)
    const newData = [
      ...Store.state.phones.filtered.data.slice(0, index),
      ...Store.state.phones.filtered.data.slice(index + 1)
    ]
    Store.commit('phones/mutate', {
      property: 'filtered.data',
      with: newData
    })
    if (!this.new) {
      this.assistance_id = Store.state.openProduct.root.data.id
    }
    const phone = {
      assistance_id: this.assistance_id,
      phone_id: item.id,
      phone: item
    }

    const selected = Store.state.phones.selected.data
    if (Store.state.phones.selected.data.length > 0) {
      selected.push(phone)
      Store.commit('phones/mutate', {
        property: 'selected.data.phone',
        with: selected
      })
    } else {
      Store.commit('phones/mutate', {
        property: 'selected.data',
        with: [phone]
      })
    }

    if (!this.new) {
      Store.commit('temp/mutate', {
        property: 'changes',
        with: true
      })
    }

    /*
    try {
      await Axios({
        method: 'post',
        url: process.env.VUE_APP_BACKEND + 'phones/add/single',
        data: {
          id: id,
          assistance_id: Store.state.openProduct.root.data.id
        },
        headers: {
          'Content-Type': 'text/plain',
          Authorization: `Bearer ${Store.state.session.data.access_token}`
        }
      }).then(() => {
        Store.dispatch('phones/fetchSelected', () => true)
      })
    } catch (e) {
      Store.dispatch('system/showError', { error: e }, { root: true })
    }
    */
  }

  async handleAddingBrand(id: any) {
    try {
      await Axios({
        method: 'post',
        url: process.env.VUE_APP_BACKEND + 'phones/add/brand',
        data: {
          brand_id: id,
          assistance_id: Store.state.openProduct.root.data.id
        },
        headers: {
          'Content-Type': 'text/plain',
          Authorization: `Bearer ${Store.state.session.data.access_token}`
        }
      }).then(() => {
        Store.dispatch('phones/fetchSelected', () => true)
      })
    } catch (e) {
      Store.dispatch('system/showError', { error: e }, { root: true })
    }
  }
}
