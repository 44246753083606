
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class RoleSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
  public readonly rules!: any[]

  public get rolItems() {
    const role: any[] = [
      {
        text: 'Administrador de Coverme',
        value: 'coverme_admin'
      },
      {
        text: 'Administrador de Compañía de Coverme',
        value: 'coverme_client_admin'
      }
    ]

    if (this.$store.state.session.data.role === 'coverme_super_admin') {
      role.push({
        text: 'SuperAdministrador de Coverme',
        value: 'coverme_super_admin'
      })
    }

    return role
  }
}
