
import { Vue, Component } from 'vue-property-decorator'
import { IOn } from '@/types'
import GroupForm from '@/components/ProductComponents/Groups/GroupForm.vue'
import Store from '@/store'

@Component({
  components: {
    GroupForm
  }
})
export default class CreateGroupBtn extends Vue {
  public dialog = false

  public async handleOpenDialog(on: IOn, event: Event) {
    Store.commit('openGroup/resetState')
    await Store.commit('openGroup/mutate', {
      property: 'root.data.assistance_id',
      with: Store.state.openProduct.root.data.id
    })
    if (this.$refs.formGroup) {
      /* @ts-ignore */
      await this.$refs.formGroup.resetForm()
    }
    on.click(event)
  }
}
