import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const misc: Module<any, any> = {
  namespaced: true,
  state: () => ({
    currencies: {
      data: [],
      status: 'fetching'
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        currencies: {
          data: [],
          status: 'fetching'
        }
      })
    }
  },
  actions: {
    async fetchCurrencies({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'currencies', {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(response => {
          commit('mutate', {
            property: 'currencies',
            with: response.data.original
          })
          commit('mutate', {
            property: 'status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
