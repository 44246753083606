import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const clients: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: [],
    status: 'fetching'
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, { root: [], status: 'fetching' })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        let query = 'companies'
        if (this.state.session.data.role !== 'coverme_super_admin')
          query = query + '/' + this.state.session.company[0].id
        await Axios.get(process.env.VUE_APP_BACKEND + query, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(response => {
          commit('mutate', {
            property: 'root',
            with: response.data.original
          })
          commit('mutate', {
            property: 'status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
