var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.status == 'fetching')?_c('v-card',{attrs:{"height":"400px"}},[_c('overlay-loading',{attrs:{"value":true}})],1):_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-form',{ref:"productForm",attrs:{"valid":"valid"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-layout',{staticClass:"px-6",attrs:{"row":"","mx-0":"","mb-5":"","mt-2":"","justify-center":"","align-center":""}},[_c('v-flex',[_c('v-container',[_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}},[_c('v-subheader',[_vm._v("Información")])],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Título","value":_vm.$store.state.openProduct.root.data.name,"required":"","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openProduct/mutate', {
                        property: 'root.data.name',
                        with: $event
                      })}}})],1)],1),(_vm.$store.state.openProduct.root.data.free == 0 && _vm.$store.state.openProduct.root.data.group == 0)?_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs5":""}},[(_vm.free || _vm.group)?_c('v-text-field',{attrs:{"disabled":"","color":"primary","label":"Precio","value":_vm.$store.state.openProduct.root.data.price}}):_c('v-text-field',{attrs:{"color":"primary","label":"Precio","value":_vm.$store.state.openProduct.root.data.price,"required":"","rules":[_vm.$rules.required, _vm.$rules.minValue(0), _vm.$rules.maxValue(1000000000)]},on:{"input":function($event){return _vm.$store.commit('openProduct/mutate', {
                        property: 'root.data.price',
                        with: $event
                      })}}})],1),_c('v-flex',{staticClass:"ml-2",attrs:{"xs2":""}},[_c('currency-list',{attrs:{"rules":[_vm.$rules.required]}})],1)],1):_c('v-layout',{staticClass:"mb-4"},[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[_c('currency-list',{attrs:{"rules":[_vm.$rules.required]}})],1)],1),_c('v-layout',{staticClass:"mt-10 mb-2"},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-subheader',[_vm._v(" Diagnósticos ")])],1),_c('v-flex',{attrs:{"xs7":""}},[_c('diagnostic-picker-for-product')],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}},[_c('v-subheader',[_vm._v("Detalles")])],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Subtítulo en aplicación","value":_vm.$store.state.openProduct.root.data.title_drop,"required":"","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openProduct/mutate', {
                        property: 'root.data.title_drop',
                        with: $event
                      })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[_c('v-textarea',{attrs:{"label":"Descripción","color":"primary","rows":"2","value":_vm.$store.state.openProduct.root.data.description},on:{"input":function($event){return _vm.$store.commit('openProduct/mutate', {
                        property: 'root.data.description',
                        with: $event
                      })}}})],1)],1),_c('v-layout',[_c('v-flex',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-1 align-center",attrs:{"cols":"4"}},[_c('v-subheader',{staticClass:"px-3"},[_vm._v("Mostrar decimales:")])],1),_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":"7"}},[_c('v-switch',{attrs:{"color":"secondary"},on:{"change":function($event){return _vm.toggleDecimals($event)}},model:{value:(_vm.switch_decimals),callback:function ($$v) {_vm.switch_decimals=$$v},expression:"switch_decimals"}})],1)],1)],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs7":""}},[_c('product-price-details')],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","mr-2":"","mb-2":"","align-center":"","justify-end":"","mt-5":""}},[_c('v-flex',{staticClass:"mr-5",attrs:{"shrink":""}},[_c('v-btn',{attrs:{"outlined":"","color":"primary","depressed":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancelar")])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","type":"submit","loading":_vm.loading}},[_vm._v("Actualizar")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }