
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOn } from '@/types'
import ProductForm from '@/components/ProductComponents/ProductForm.vue'
import Store from '@/store'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'

@Component({
  components: {
    ProductForm,
    SearchBox
  }
})
export default class CreateProductBtn extends Vue {
  public dialogEdit = false
  public dialogDelete = false
  public valid = true
  public loading = false
  public search = ''

  @Prop()
  public readonly value!: any

  public async handleOpenDialog(on: IOn, event: Event) {
    await Store.dispatch('misc/fetchCurrencies', () => true)
    on.click(event)
  }

  onClickClose() {
    this.dialogEdit = false
    this.dialogDelete = false
  }

  public async handleDelete() {
    await Store.commit('openProduct/mutate', {
      property: 'root.data.id',
      with: this.value
    })
    this.loading = true
    await Store.dispatch('openProduct/deleteProduct')
    Store.commit('openProduct/resetState')
    await Store.dispatch('products/fetchRoot', () => true)
    this.loading = false
    this.dialogDelete = false
  }
}
