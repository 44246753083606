import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const codes: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      data: {
        codes: [],
        expires: 0,
        expiration_date: ''
      },
      status: 'fetching'
    },
    openCode: {
      id: ''
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          data: [],
          status: 'fetching'
        },
        openCode: {
          id: ''
        }
      })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'discounts/code/get/' + this.state.codes.openCode.discount_id, {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root.data',
            with: response.data.original.data
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async burnCode({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'discounts/code/burned',
          data: {
            id: this.state.codes.openCode.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Codigo consumido', { root: true })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
