
import { Vue, Component } from 'vue-property-decorator'
import moment from 'moment'

@Component({})
export default class Greetings extends Vue {
  public greet = ''

  public mounted() {
    const hour = Number.parseInt(moment().format('HH'))
    this.greet = hour >= 5 && hour < 12 ? 'Buenos días' : hour >= 12 && hour < 19 ? 'Buenas tardes' : 'Buenas noches'
  }
}
