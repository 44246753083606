
import { Vue, Component, Prop } from 'vue-property-decorator'
import ActionsForCodes from '@/components/ProductComponents/Promotions/ActionsForCodes.vue'
import DeepLink from '@/components/ProductComponents/Promotions/DeepLink.vue'
import Store from '@/store'
import moment from 'moment'

@Component({
  components: {
    ActionsForCodes,
    DeepLink
  }
})
export default class CodesDataTable extends Vue {
  @Prop(String)
  public readonly search!: string

  get expires() {
    return Store.state.codes.root.data.expires
  }

  public date = Store.state.codes.root.data.expiration_date

  get formatted_date() {
    return moment(this.date).format('DD-MM-YYYY')
  }
  public get headers() {
    let headers = [
      {
        text: 'Código',
        align: 'left',
        sortable: true,
        value: 'code'
      },
      {
        text: 'Estado',
        value: 'state',
        align: 'center',
        sortable: false
      },
      {
        text: 'N° de usos',
        value: 'times_used',
        align: 'center',
        sortable: true
      },
      {
        text: 'Fecha de expiracion',
        value: 'date',
        align: 'center',
        sortable: true
      },
      {
        text: 'Fecha de creación',
        value: 'created_at',
        sortable: true
      },

      {
        text: 'Usar',
        value: 'burn',
        align: 'center',
        sortable: false
      },

      {
        text: 'Link',
        value: 'deep',
        align: 'center',
        sortable: false
      }
    ]
    if (this.expires != 1) {
      headers.splice(3, 1)
    } else {
      headers.splice(2, 1)
    }

    return headers
  }
}
