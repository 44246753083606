var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"slot",style:({
    'font-size': `${_vm.size}px`,
    'letter-spacing': `${_vm.spacing}px`,
    'font-weight': _vm.weight,
    'font-style': _vm.italic && 'italic',
    'text-transform': _vm.transform,
    'text-align': _vm.align
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }