import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const temp: Module<any, any> = {
  namespaced: true,
  state: () => ({ changes: false, test: {}, devices: {} }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        changes: false,
        test: {},
        devices: {}
      })
    }
  },
  actions: {
    async save({ commit, dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'phones/save',
          data: this.state.temp,
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Cambios guardados correctamente', {
            root: true
          })
          commit('mutate', {
            property: 'test',
            with: {}
          })
          commit('mutate', {
            property: 'devices',
            with: {}
          })
          commit('mutate', {
            property: 'changes',
            with: false
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
