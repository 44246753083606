
import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import { VForm } from '@/types'
import Store from '@/store'
import RouterGoBackBtn from '@/components/RouterComponents/RouterGoBackBtn.vue'
import CreatePromotionClientSelect from '@/components/ProductComponents/CreatePromotionClientSelect.vue'
import CurrencyList from '@/components/ProductComponents/CurrencyList.vue'
import DiagnosticPickerForProduct from '@/components/ProductComponents/DiagnosticPickerForProduct.vue'
import ProductPriceDetails from '@/components/ProductComponents/ProductPriceDetails.vue'

@Component({
  components: {
    RouterGoBackBtn,
    CreatePromotionClientSelect,
    CurrencyList,
    DiagnosticPickerForProduct,
    ProductPriceDetails
  }
})
export default class ProductForm extends Vue {
  public error = false
  public loading = false

  public free = false
  public formatFree = 0
  public group = false
  public formatGroup = 0
  public status = 'fetching'
  public switch_decimals = false

  @Prop(Boolean)
  public newly!: boolean

  @Prop()
  public readonly id!: any

  @Ref('productForm')
  private readonly productForm!: VForm

  async beforeRouteEnter() {
    this.status = 'fetching'
  }

  async mounted() {
    await Store.commit('openProduct/mutate', {
      property: 'root.data.id',
      with: this.id
    })
    await Store.dispatch('openProduct/fetchRoot', () => true)
    this.formatFree = Store.state.openProduct.root.data.free
    Store.state.openProduct.root.data.free === 0 ? (this.free = false) : (this.free = true)

    this.formatGroup = Store.state.openProduct.root.data.group
    Store.state.openProduct.root.data.group === 0 ? (this.group = false) : (this.group = true)

    this.switch_decimals = Store.state.openProduct.root.data.is_decimal

    this.status = 'fulfilled'
  }

  public handleFree($event: any) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.price',
      with: 0
    })
    $event ? (this.formatFree = 1) : (this.formatFree = 0)
    if ($event) {
      this.group = false
      this.formatGroup = 0
    }
    Store.commit('openProduct/mutate', {
      property: 'root.data.free',
      with: this.formatFree
    })
    Store.commit('openProduct/mutate', {
      property: 'root.data.group',
      with: this.formatGroup
    })
  }

  public handleGroup($event: any) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.price',
      with: 0
    })
    $event ? (this.formatGroup = 1) : (this.formatGroup = 0)
    if ($event) {
      this.free = false
      this.formatFree = 0
    }
    Store.commit('openProduct/mutate', {
      property: 'root.data.group',
      with: this.formatGroup
    })
    Store.commit('openProduct/mutate', {
      property: 'root.data.free',
      with: this.formatFree
    })
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    setTimeout(() => (this.loading = true), 1)
    this.productForm.resetValidation()
    setTimeout(async () => {
      if (this.productForm.validate()) {
        try {
          if (this.newly) await Store.dispatch('openProduct/createProduct')
          else await Store.dispatch('openProduct/updateProduct')
          this.$emit('cancel')
        } catch (e) {
          this.error = true
        }
      }
      await Store.dispatch('products/fetchRoot', () => true)
      this.loading = false
    }, 1000)
    this.loading = false
  }

  public resetForm() {
    this.$nextTick(() => {
      this.productForm.reset()
    })
  }

  public toggleDecimals(event: boolean) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.is_decimal',
      with: event
    })
  }
}
