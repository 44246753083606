
import { Component, Vue, Prop } from 'vue-property-decorator'

import RouterGoProductDetailsBtn from '@/components/RouterComponents/RouterGoProductDetailsBtn.vue'
import GroupOptions from '@/components/ProductComponents/Groups/GroupOptions.vue'
import DropDevice from '@/components/ProductComponents/Groups/DropDevice.vue'

@Component({
  components: {
    RouterGoProductDetailsBtn,
    GroupOptions,
    DropDevice
  }
})
export default class GroupsDataTable extends Vue {
  @Prop()
  public readonly status!: any

  public get headers() {
    const headers = [
      {
        text: 'Marca',
        align: 'left',
        sortable: false,
        value: 'brand'
      },
      {
        text: 'Modelo',
        value: 'model',
        sortable: false
      },
      {
        text: 'Capacidad',
        align: 'left',
        sortable: false,
        value: 'capacity'
      },
      {
        text: 'Quitar',
        value: 'delete',
        sortable: false
      }
    ]

    return headers
  }
}
