
import { Component, Vue, Prop } from 'vue-property-decorator'
import RouterGoDeviceDetailsBtn from '@/components/RouterComponents/RouterGoDeviceDetailsBtn.vue'

@Component({
  components: {
    RouterGoDeviceDetailsBtn
  }
})
export default class ApprovedTable extends Vue {
  @Prop(String)
  public readonly search!: string

  @Prop()
  public readonly value!: any

  @Prop(Boolean)
  public readonly hideControls!: boolean

  public get headers() {
    const headers = [
        {
          text: 'Marca',
          align: 'start',
          sortable: false,
          value: 'assistance_device.device.code.phone.brand.name'
        },
        {
          text: 'Modelo',
          align: 'start',
          sortable: false,
          value: 'assistance_device.device.code.phone.name'
        },
        {
          text: 'Producto',
          align: 'start',
          sortable: false,
          value: 'assistance_device.assistance.name'
        },
        {
          text: 'Fecha del test',
          value: 'created_at'
        },
        {
          text: 'Revisar',
          value: 'detail',
          sortable: false
        }
    ]
    return headers
  }
}
