<template>
  <div>
    <table>
      <tr>
        <th>Campo 1</th>
        <th>Campo 2</th>
        <th>Campo 3</th>
        <th>Campo 4</th>
        <th>Campo 5</th>
      </tr>
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.campo1 }}</td>
        <td>{{ item.campo2 }}</td>
        <td>{{ item.campo3 }}</td>
        <td>{{ item.campo4 }}</td>
        <td>{{ item.campo5 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      items: []
    }
  },
  mounted() {
    axios.get('/api/items')
      .then(response => {
        this.items = response.data
      })
  }
}
</script>

<style scoped>
table {
  border-collapse: collapse;
}

th, td {
  border: 1px solid black;
}
</style>

