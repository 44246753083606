import * as VueDeepSet from 'vue-deepset'
import getProperty from 'lodash/get'
import { XState } from './store.interface'

export function mutate(state: unknown, payload: { property: string; with: unknown }) {
  const data = getProperty(state, payload.property, null)
  if (data && Array.isArray(data) && !Array.isArray(payload.with)) {
    const shallowCopy = JSON.parse(JSON.stringify(data)) as any[]
    shallowCopy.push(payload.with)
    VueDeepSet.VUEX_DEEP_SET(state, {
      path: payload.property,
      value: shallowCopy
    })
  } else {
    VueDeepSet.VUEX_DEEP_SET(state, {
      path: payload.property,
      value: payload.with
    })
  }
}

export function genericMutate(state: any, payload: { property: string; with: unknown }) {
  VueDeepSet.VUEX_DEEP_SET(state, {
    path: payload.property,
    value: payload.with
  })
}

export const genericReset = (defaultState: any) => (state: any) => {
  Object.assign(state, defaultState())
}

export function mutateMany(state: unknown, payloads: { property: string; with: unknown }[]) {
  payloads.forEach((payload) => mutate(state, payload))
}

export function reset(state: { [key: string]: XState }, payload: { property: string }) {
  if (payload.property in state && isXState(state[payload.property])) {
    state[payload.property].data = JSON.parse(JSON.stringify(state[payload.property].initial))
    state[payload.property].state = 'idle'
  }
}

export function isXState(state: any): state is XState<any> {
  return 'state' in state
}
