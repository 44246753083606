var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-snackbar',{attrs:{"bottom":"","right":"","color":_vm.$store.state.system.snackBar.color,"value":_vm.$store.state.system.snackBar.show,"timeout":_vm.$store.state.system.snackBar.color === 'success' ? 5000 : 30000},on:{"input":function($event){return _vm.$store.commit('system/mutate', {
      property: 'snackBar.show',
      with: $event
    })}}},[_vm._v(" "+_vm._s(_vm.$store.state.system.snackBar.message)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.$store.commit('system/mutate', {
        property: 'snackBar.show',
        with: false
      })}}},[_vm._v(" CERRAR ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }