
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOn } from '@/types'
import GroupForm from '@/components/ProductComponents/Groups/GroupForm.vue'
import Store from '@/store'

@Component({
  components: {
    GroupForm
  }
})
export default class GroupOptions extends Vue {
  public dialogEdit = false
  public dialogDelete = false
  public valid = true
  public loading = false

  @Prop()
  public readonly value!: any

  public async handleOpenDialog(on: IOn, event: Event) {
    setTimeout(() => on.click(event), 1)
  }

  onClickClose() {
    this.dialogEdit = false
    this.dialogDelete = false
  }

  public async handleDelete() {
    this.loading = true
    await Store.dispatch('openGroup/delete')
    this.loading = false
    await Store.dispatch('groups/fetchRoot', () => true)
    this.dialogDelete = false
  }

  public async handleFetch() {
    await Store.commit('openGroup/mutate', {
      property: 'root.data.id',
      with: this.value
    })
    await Store.dispatch('openGroup/fetchRoot', () => true)
  }
}
