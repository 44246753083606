
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'
import RouterGoBackBtn from '@/components/RouterComponents/RouterGoBackBtn.vue'
import DeviceProducts from '@/components/DeviceComponents/DeviceProducts.vue'
import DeviceTests from '@/components/DeviceComponents/DeviceTests.vue'
import DeviceDiagnosesGroups from '@/components/DeviceComponents/DeviceDiagnosesGroups.vue'

@Component({
  components: {
    RouterGoBackBtn,
    DeviceProducts,
    DeviceTests,
    DeviceDiagnosesGroups
  }
})
export default class DevicesDetailPage extends Vue {
  type = this.$route.query.type

  async beforeRouteEnter(to: any, from: any, next: any) {
    Store.commit('openDevice/resetState')
    Store.commit('openDevice/mutate', {
      property: 'status',
      with: 'fetching'
    })
    Store.commit('openDevice/mutate', {
      property: 'root.data.trust_id',
      with: to.params.id
    })
    await Store.dispatch('openDevice/fetchRoot', () => true)
    await Store.dispatch('openDevice/fetchProducts', () => true)
    await Store.dispatch('openDevice/fetchGroups', to.params.id)
    next()
  }
}
