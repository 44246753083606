
import { Vue, Component } from 'vue-property-decorator'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import CreateUserBtn from '@/components/UserComponents/CreateUserBtn.vue'
import UsersDataTable from '@/components/UserComponents/UsersDataTable.vue'
import ClientSelectTable from '@/components/UserComponents/ClientSelectTable.vue'
import Store from '@/store/'

@Component({
  components: {
    SearchBox,
    CreateUserBtn,
    UsersDataTable,
    ClientSelectTable
  }
})
export default class UsersPage extends Vue {
  public async searchEnter(event: string) {
    Store.commit('users/mutate', {
      property: 'root.pagination.currentPage',
      with: 1
    })
    Store.commit('users/mutate', {
      property: 'root.filters.search',
      with: event.toLowerCase()
    })
    Store.dispatch('users/fetchRoot', () => true)
  }
}
