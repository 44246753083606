import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const openPromotion: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      id: '',
      name: '',
      code: '',
      type: '',
      value: '',
      expires: '1',
      date: '',
      formatDate: '',
      stock: '',
      times: ''
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          id: '',
          name: '',
          code: '',
          type: '',
          value: '',
          expires: '1',
          date: '',
          formatDate: '',
          stock: '',
          times: ''
        }
      })
    }
  },
  actions: {
    async createPromotion({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'discounts/create',
          data: {
            company_id: this.state.openProduct.root.data.company_id,
            assistance_id: this.state.openProduct.root.data.id,
            name: this.state.openPromotion.root.name,
            code: this.state.openPromotion.root.code,
            type: parseInt(this.state.openPromotion.root.type),
            value: parseInt(this.state.openPromotion.root.value),
            expires: parseInt(this.state.openPromotion.root.expires),
            date: this.state.openPromotion.root.date,
            stock: parseInt(this.state.openPromotion.root.stock),
            times: parseInt(this.state.openPromotion.root.times)
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Promoción creado', { root: true })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async deletePromotion({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'discounts/delete',
          data: {
            id: this.state.openPromotion.root.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Promoción eliminada', { root: true })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
