
import { Vue, Component } from 'vue-property-decorator'
import PhoneTable from '@/components/ProductComponents/Phones/PhoneTable.vue'
import PhoneSelected from '@/components/ProductComponents/Phones/PhoneSelected.vue'
import SnackbarWarning from '@/components/HelpComponents/SnackbarWarning.vue'

@Component({
  components: {
    PhoneTable,
    PhoneSelected,
    SnackbarWarning
  }
})
export default class DetailsPhoneCard extends Vue {}
