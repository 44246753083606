
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class DText extends Vue {
  @Prop({ type: Number, default: 12 })
  public readonly size!: number

  @Prop({ type: Number, default: 0 })
  public readonly spacing!: number

  @Prop({ type: String, default: 'normal' })
  public readonly weight!: string

  @Prop(Boolean)
  public readonly italic!: string

  @Prop({ type: String, default: 'none' })
  public readonly transform!: string

  @Prop({ type: String, default: 'left' })
  public readonly align!: string
}
