
import { Vue, Component } from 'vue-property-decorator'
import EditCompanyListItem from '@/components/CompanyComponents/EditCompanyListItem.vue'

@Component({
  components: {
    EditCompanyListItem
  }
})
export default class UsersList extends Vue {
  public page = 1

  public pageCount = 0

  public headers: any[] = [
    { text: 'Compañía', value: 'img' },
    { text: 'Nombre', value: 'name' },
    { text: 'Correo electrónico', value: 'email' },
    { text: 'Opciones', value: 'options' }
  ]
}
