
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class OverlayError extends Vue {
  @Prop({ type: Boolean, default: true })
  public readonly value!: boolean

  @Prop({ type: String, default: 'SE PRODUJO UN ERROR DE CARGA' })
  public readonly text!: string

  @Prop({ type: [Number, String], default: '8' })
  public readonly btnOffset!: number | string

  @Prop({ type: String, default: '' })
  public readonly btnAction!: string

  @Prop({ type: String, default: '' })
  public readonly btnParam!: string

  @Prop({ type: String, default: 'normal' })
  public readonly size!: string

  @Prop({ type: Number, default: 0.8 })
  public readonly opacity!: number
}
