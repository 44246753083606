
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class StateMachineOnFetchingOverlay extends Vue {
  @Prop({ type: String, default: 'CARGANDO DATOS' })
  public readonly text!: string

  @Prop({ type: Boolean, default: true })
  public readonly value!: boolean

  @Prop({ type: Boolean, default: true })
  public readonly animated!: boolean

  @Prop({ type: String, default: 'normal' })
  public readonly size!: string
}
