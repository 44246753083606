
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { IOn, VForm } from '@/types'
import Store from '@/store'

import RouterGoBackBtn from '@/components/RouterComponents/RouterGoBackBtn.vue'
import CompanySelect from '@/components/ClientComponents/CompanySelect.vue'
import ClientColorPicker from '@/components/ClientComponents/ClientColorPicker.vue'
//import FileUploadFab from '@/components/TrustComponents/FileUploadFab.vue'

@Component({
  components: {
    RouterGoBackBtn,
    CompanySelect,
    ClientColorPicker
    //FileUploadFab
  }
})
export default class ClientForm extends Vue {
  public color1 = false
  public color2 = false
  public error = false
  public loading = false

  @Prop(Boolean)
  public readonly newly!: boolean

  @Ref('clientForm')
  private readonly clientForm!: VForm

  public async handleOpenDialog(on: IOn, event: Event) {
    on.click(event)
  }

  public resetForm() {
    this.$nextTick(() => {
      this.clientForm.reset()
    })
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    this.loading = true
    this.clientForm.resetValidation()
    setTimeout(async () => {
      if (this.clientForm.validate()) {
        try {
          await Store.dispatch('openClient/updateClient')
          Store.commit('openClient/resetState')
          await Store.dispatch('clients/fetchRoot', () => true)
          this.$emit('cancel')
        } catch (e) {
          this.error = true
        }
      }
      this.loading = false
    }, 1000)

    this.loading = false
  }
}
