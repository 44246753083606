
import { Component, Vue, Prop } from 'vue-property-decorator'
import RouterGoProductDetailsBtn from '@/components/RouterComponents/RouterGoProductDetailsBtn.vue'
import ProductOptions from '@/components/ProductComponents/ProductOptions.vue'
import Store from '@/store'

@Component({
  components: {
    RouterGoProductDetailsBtn,
    ProductOptions
  }
})
export default class ProductsDataTable extends Vue {
  @Prop(String)
  public readonly search!: string

  @Prop()
  public readonly value!: any

  async mounted() {
    Store.commit('openProduct/resetState', () => true)
  }

  public get headers() {
    const headers = [
      {
        text: 'Nombre',
        align: 'left',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Tipo de Moneda',
        align: 'center',
        value: 'currency',
        sortable: true
      },
      {
        text: 'Valor',
        align: 'center',
        value: 'formatted_price',
        sortable: true
      },
      {
        text: 'N° de dispositivos',
        value: 'number',
        align: 'center',
        sortable: true
      },
      {
        text: 'Grupos',
        align: 'center',
        value: 'groupStatus',
        sortable: false
      },
      {
        text: 'Opciones',
        value: 'options',
        sortable: false
      },
      {
        text: 'Detalle',
        value: 'detalle',
        sortable: false
      }
    ]

    return headers
  }
  get clientConfig() {
    return {
      thousands_sep: Store.state.openClient.config.data.thousands_sep,
      dec_point: Store.state.openClient.config.data.dec_point
    }
  }
}
