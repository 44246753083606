
import { Vue, Component, Ref } from 'vue-property-decorator'
import { VForm } from '@/types'
import Store from '@/store'

@Component({})
export default class DiagnosticTouch extends Vue {
  public cardDeactivation = true
  public screenValues = ''
  public error = false
  public loading = false

  @Ref('touchForm')
  private readonly touchForm!: VForm

  public changeScreenTouch(newColumnValue: '8' | '10' | '12') {
    this.$store.commit('openDiagnostic/mutate', {
      property: 'parameters.touch.screen_columns.value',
      with: newColumnValue
    })
    this.$store.commit('openDiagnostic/mutate', {
      property: 'parameters.touch.screen_rows.value',
      with: newColumnValue === '8' ? '16' : newColumnValue === '10' ? '20' : '24'
    })
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    setTimeout(() => (this.loading = true), 1)
    this.touchForm.resetValidation()
    setTimeout(async () => {
      if (this.touchForm.validate()) {
        try {
          await Store.dispatch('openDiagnostic/saveParameters')
        } catch (e) {
          this.error = true
        }
      }
      this.loading = false
    }, 1000)

    this.loading = false
  }
}
