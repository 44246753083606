
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({
  components: {}
})
export default class ProductRadioPrice extends Vue {
  public radioGroup = '1'
  public price = ''
  public switch_decimals = false

  public handleRadio(kind: number) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.free',
      with: kind === 2 ? 1 : 0
    })
    Store.commit('openProduct/mutate', {
      property: 'root.data.group',
      with: kind === 3 ? 1 : 0
    })
    Store.commit('openProduct/mutate', {
      property: 'root.data.price',
      with: kind === 3 ? '' : 0
    })
  }

  mounted() {
    this.switch_decimals = Store.state.openProduct.root.data.is_decimal
  }

  public toggleDecimals(event: boolean) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.is_decimal',
      with: event
    })
  }

  public nextStep() {
    this.$store.commit('openProduct/mutate', {
      property: 'root.data.price',
      with: this.price
    })
    this.$emit('nextStep')
  }
  public resetForm() {
    this.price = ''
  }
}
