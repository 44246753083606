
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import Store from '@/store'
import ProductRadioPrice from '@/components/ProductComponents/ProductRadioPrice.vue'
import ProductPriceDetails from '@/components/ProductComponents/ProductPriceDetails.vue'
import ProductFormEmbed from '@/components/ProductComponents/ProductFormEmbed.vue'
import DiagnosticPickerEmbed from '@/components/ProductComponents/DiagnosticPickerEmbed.vue'
import QuickView from '@/components/ProductComponents/QuickView.vue'
import { VForm } from '@/types'

@Component({
  components: {
    ProductRadioPrice,
    ProductFormEmbed,
    ProductPriceDetails,
    DiagnosticPickerEmbed,
    QuickView
  }
})
export default class CreationSteppers extends Vue {
  public loading = false
  public error1 = false
  public error2 = false
  public textError1 = ''
  public textError2 = ''
  public step = 1

  $refs!: {
    price: HTMLFormElement
    productForm: HTMLFormElement
    diagnosticPickerEmbed: HTMLFormElement
  }

  @Prop()
  public readonly originBtn!: any

  @Ref('productForm')
  public readonly productForm!: { validate: VForm['validate'] }

  public mounted() {
    this.step = Store.state.openProduct.step
  }

  public async handleSave() {
    setTimeout(() => (this.loading = true), 1)
    setTimeout(async () => {
      try {
        await Store.dispatch('openProduct/completeSave')
        this.$emit('cancel')
      } catch (e) {
        //this.error = true
      }

      Store.commit('openProduct/mutate', {
        property: 'root.data.company_id',
        with: Store.state.session.company[0].id
      })

      if (this.originBtn === 'dashboard') {
        await Store.dispatch('dashboard/fetchLatestProducts', () => true)
      } else {
        await Store.dispatch('products/fetchRoot', () => true)
      }
      this.loading = false
    }, 1000)
    this.loading = false
    setTimeout(() => {
      this.resetForm()
    }, 1250)
  }

  public handleStep(step: number) {
    switch (step) {
      case 1:
        if (
          (Store.state.openProduct.root.data.free == 0 &&
            Store.state.openProduct.root.data.group == 0 &&
            Store.state.openProduct.root.data.price > 0) ||
          Store.state.openProduct.root.data.free == 1 ||
          Store.state.openProduct.root.data.group == 1
        ) {
          this.step = 2
          this.error1 = false
          this.textError1 = ''
        } else {
          this.error1 = true
          this.textError1 = 'El precio debe ser un valor numérico mayor a 0'
        }
        break
      case 2:
        {
          this.productForm.validate()
          if (
            Store.state.openProduct.root.data.company_id !== '' &&
            Store.state.openProduct.root.data.name.length > 0 &&
            Store.state.openProduct.root.data.currency !== '' &&
            Store.state.openProduct.root.data.title_drop.length > 0
          ) {
            this.step = 3
            this.error2 = false
            this.textError2 = ''
          } else {
            this.error2 = true
            this.textError2 = 'Por favor ingrese todos los valores marcados con (*)'
          }
        }
        break
    }
  }

  public resetForm() {
    this.step = 1
    this.$refs.price.resetForm()
    this.$refs.productForm.resetForm()
    this.$refs.diagnosticPickerEmbed.resetForm()
  }
}
