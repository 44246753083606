
import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import { VForm } from '@/types'
import RouterGoBackBtn from '@/components/RouterComponents/RouterGoBackBtn.vue'
import Store from '@/store'

@Component({
  components: {
    RouterGoBackBtn
  }
})
export default class GroupForm extends Vue {
  public error = false
  public loading = false

  public menu = false

  @Prop(Boolean)
  public newly!: boolean

  @Ref('groupForm')
  private readonly groupForm!: VForm

  public resetForm() {
    this.$nextTick(() => {
      this.groupForm.reset()
    })
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    setTimeout(() => (this.loading = true), 1)
    this.groupForm.resetValidation()
    setTimeout(async () => {
      if (this.groupForm.validate()) {
        try {
          if (this.newly) await Store.dispatch('openGroup/create')
          else await Store.dispatch('openGroup/update')
          await Store.dispatch('groups/fetchRoot', () => true)
          this.$emit('cancel')
          Store.commit('openGroup/resetState')
        } catch (e) {
          this.error = true
        }
      }
      this.loading = false
    }, 1000)

    this.loading = false
  }
}
