
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component
export default class SnackbarWarning extends Vue {
  public error = false
  public loading = false

  @Prop()
  public readonly module!: any

  public async handleSave() {
    this.loading = true
    if (this.module == 'products') {
      try {
        await Store.dispatch('phones/save')
        await Store.dispatch('phones/fetchFiltered', () => true)
        await Store.dispatch('phones/fetchSelected', () => true)
      } catch (e) {
        this.error = true
      }
    } else if (this.module == 'diagnostics') {
      try {
        await Store.dispatch('openDiagnostic/save')
        await Store.dispatch('phones/fetchRoot', () => true)
      } catch (e) {
        this.error = true
      }
    }
    Store.commit('temp/resetState')
    this.loading = false
  }

  public async handleUndo() {
    this.loading = true
    try {
      Store.commit('temp/resetState')
      if (this.module == 'products') {
        await Store.dispatch('phones/fetchFiltered', () => true)
        await Store.dispatch('phones/fetchSelected', () => true)
      } else if (this.module == 'diagnostics') {
        await Store.dispatch('phones/fetchRoot', () => true)
      }
    } catch (e) {
      this.error = true
    }
    this.loading = false
  }
}
