
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class DGrid extends Vue {
  @Prop({ type: String, default: 'row' })
  public readonly autoFlow!: 'row' | 'column' | 'dense'

  @Prop({ type: String, default: 'start' })
  public readonly justifyItems!: 'start' | 'center' | 'end' | 'stretch'

  @Prop({ type: String, default: 'start' })
  public readonly alignItems!: 'start' | 'center' | 'end' | 'stretch'

  @Prop({ type: String, default: 'start' })
  public readonly justifyContent!: 'start' | 'end' | 'center' | 'stretch' | 'around' | 'between' | 'evenly'

  @Prop({ type: String, default: 'start' })
  public readonly alignContent!: 'start' | 'end' | 'center' | 'stretch' | 'around' | 'between' | 'evenly'

  @Prop({ type: String, default: 'repeat(auto-fit, 1fr)' })
  public readonly templateColumns!: string

  @Prop({ type: String, default: 'auto' })
  public readonly templateRows!: string

  @Prop({ type: String })
  public readonly autoColumns!: string

  @Prop({ type: String })
  public readonly autoRows!: string

  @Prop({ type: [Number, String] })
  public readonly gap!: number | string

  @Prop({ type: Array })
  public readonly areaNames?: string[]

  @Prop({ type: [Object, String] })
  public readonly templateAreas?:
    | string
    | {
        xs: string
        sm?: string
        md?: string
        lg?: string
        xl?: string
      }

  public get style(): any {
    let styleList: any = {
      'grid-template-columns': this.templateColumns,
      'grid-template-rows': this.templateRows,
      'grid-auto-columns': this.autoColumns,
      'grid-auto-rows': this.autoRows,
      'grid-auto-flow': this.autoFlow,
      'justify-items': this.justifyItems,
      'align-items': this.alignItems,
      'justify-content': this.justifyContent,
      'align-content': this.alignContent,
      gap: typeof this.gap === 'number' ? `${this.gap}px` : this.gap
    }

    if (typeof this.templateAreas === 'string') {
      styleList['grid-template-areas'] = this.templateAreas
    } else if (typeof this.templateAreas === 'object') {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          {
            if ('xl' in this.templateAreas) {
              styleList['grid-template-areas'] = this.templateAreas!.xl
              break
            }
          }
          break
        case 'lg':
          {
            if ('lg' in this.templateAreas) {
              styleList['grid-template-areas'] = this.templateAreas!.lg
              break
            }
          }
          break
        case 'md':
          {
            if ('md' in this.templateAreas) {
              styleList['grid-template-areas'] = this.templateAreas!.md
              break
            }
          }
          break
        case 'sm':
          {
            if ('sm' in this.templateAreas) {
              styleList['grid-template-areas'] = this.templateAreas!.sm
              break
            }
          }
          break
        case 'xs':
          styleList['grid-template-areas'] = this.templateAreas.xs
          break
      }
    }

    return styleList
  }

  private wrap(): void {
    if (
      this.areaNames &&
      Array.isArray(this.areaNames) &&
      this.$slots &&
      this.$slots.default &&
      Array.isArray(this.$slots.default) &&
      this.$slots.default.length > 0
    ) {
      this.$slots.default.forEach((slot, index) => {
        const element = slot.elm as HTMLElement
        if (element && element.style) {
          element.style['gridArea'] = this.areaNames![index]
        }
      })
    }
  }

  public mounted(): void {
    this.$nextTick(this.wrap)
  }

  public updated(): void {
    this.$nextTick(this.wrap)
  }
}
