var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-4"},[_c('v-form',{ref:"userForm",attrs:{"valid":"valid"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-card',{staticClass:"card-userform",attrs:{"width":"581px"}},[_c('v-layout',[_c('ClientSelect',{attrs:{"rules":[_vm.$rules.required]}})],1),_c('v-layout',[_c('RoleSelect',{attrs:{"rules":[_vm.$rules.required]}})],1),_c('v-layout',[_c('v-text-field',{attrs:{"color":"primary","flat":"","label":"Nombre","name":"name","value":_vm.$store.state.openUser.details.name,"required":"","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openUser/mutate', {
                  property: 'details.name',
                  with: $event
                })}}})],1),_c('v-layout',[_c('v-text-field',{attrs:{"color":"primary","flat":"","label":"Apellido","name":"last_name","value":_vm.$store.state.openUser.details.last_name,"required":"","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openUser/mutate', {
                  property: 'details.last_name',
                  with: $event
                })}}})],1),_c('v-layout',[_c('v-text-field',{attrs:{"color":"primary","flat":"","label":"DNI","name":"dni","value":_vm.$store.state.openUser.details.dni,"required":"","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openUser/mutate', {
                  property: 'details.dni',
                  with: $event
                })}}})],1),_c('SetPassword',{attrs:{"newly":_vm.newly}}),_c('v-layout',[_c('v-text-field',{attrs:{"color":"primary","flat":"","label":"Correo electrónico","name":"email","value":_vm.$store.state.openUser.details.email,"required":"","rules":[_vm.$rules.required, _vm.$rules.isEmail]},on:{"input":function($event){return _vm.$store.commit('openUser/mutate', {
                  property: 'details.email',
                  with: $event
                })}}})],1),(_vm.error)?_c('v-alert',{staticClass:"fill-width",attrs:{"type":"error","border":"top","dense":""}},[_c('p',{staticClass:"white--text"},[_vm._v(" Ocurrió un error al tratar de registrar a "+_vm._s(_vm.$store.state.openUser.details.name)+". Revisa que el correo electronico no se haya usado para registrar a otro usuario anteriorimente, o espera unos minutos para reintentar. ")])]):_vm._e()],1),_c('v-layout',{staticClass:"my-4",attrs:{"justify-end":""}},[_c('v-flex',{staticClass:"mr-5",attrs:{"shrink":""}},[_c('v-btn',{attrs:{"outlined":"","dark":"","color":"secondary","depressed":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancelar ")])],1),_c('v-flex',{attrs:{"shrink":""}},[(_vm.newly)?_c('v-btn',{attrs:{"color":"secondary","depressed":"","type":"submit","loading":_vm.loading}},[_vm._v(" Guardar ")]):_c('v-btn',{attrs:{"color":"secondary","depressed":"","type":"submit","loading":_vm.loading}},[_vm._v(" Editar ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }