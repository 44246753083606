
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'
import ProductForm from '@/components/ProductComponents/ProductForm.vue'

@Component({
  components: {
    ProductForm
  }
})
export default class ActionsForDevice extends Vue {
  public dialogDrop = false
  public valid = true
  public loading = false

  @Prop()
  public readonly value!: any

  onClickClose() {
    this.dialogDrop = false
  }

  public async handleDisable() {
    Store.commit('openDevice/mutate', {
      property: 'openProduct.id',
      with: this.value
    })

    await Store.dispatch('openDevice/disableProduct')
    Store.dispatch('openDevice/fetchProducts', () => true)
  }
}
