
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class CompanySelect extends Vue {
  public select = null

  @Prop({ type: Array, default: () => [] })
  public readonly rules!: any[]

  public mounted() {
    this.$store.dispatch('companies/fetchRoot', () => true)
    if (this.$store.state.session.data.role !== 'coverme_super_admin') {
      this.$store.commit('openUser/mutate', {
        property: 'details.company_uid',
        with: this.$store.state.session.userinfo.company_uid
      })
    }
  }

  public async handleSelectCompany($event: any) {
    await Store.commit('openClient/mutate', {
      property: 'details.data.company_uid',
      with: $event.id
    })
    await Store.commit('openClient/mutate', {
      property: 'details.data.name',
      with: $event.name
    })
    await Store.commit('openClient/mutate', {
      property: 'details.data.logo',
      with: $event.img
    })
  }
}
