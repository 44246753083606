import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const dashboard: Module<any, any> = {
  namespaced: true,
  state: () => ({
    latest: {
      products: {
        data: [],
        status: 'fetching'
      },
      boughts: {
        data: [],
        status: 'fetching'
      },
      inactives: {
        data: [],
        status: 'fetching'
      }
    },
    refresh: false
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        latest: {
          products: {
            data: [],
            status: 'fetching'
          },
          boughts: {
            data: [],
            status: 'fetching'
          }
        },
        refresh: false
      })
    }
  },
  actions: {
    async fetchLatestProducts({ commit, dispatch }) {
      try {
        if (this.state.session.company[0].id) {
          await Axios({
            method: 'post',
            url: process.env.VUE_APP_BACKEND + 'products/latest',
            data: {
              id: this.state.session.company[0].id,
              role: this.state.session.data.role
            },
            headers: {
              'Content-Type': 'text/plain',
              Authorization: `Bearer ${this.state.session.data.access_token}`
            }
          }).then((response) => {
            commit('mutate', {
              property: 'latest.products',
              with: response.data.original
            })
            commit('mutate', {
              property: 'latest.products.status',
              with: 'fulfilled'
            })
          })
        }
      } catch (e) {
        commit('mutate', {
          property: 'status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchLatestBought({ commit, dispatch }) {
      try {
        if (this.state.session.company[0].id) {
          await Axios({
            method: 'post',
            url: process.env.VUE_APP_BACKEND + 'products/latest_bought',
            data: {
              id: this.state.session.company[0].id,
              role: this.state.session.data.role
            },
            headers: {
              'Content-Type': 'text/plain',
              Authorization: `Bearer ${this.state.session.data.access_token}`
            }
          }).then((response) => {
            commit('mutate', {
              property: 'latest.boughts',
              with: response.data.original
            })
            commit('mutate', {
              property: 'latest.boughts.status',
              with: 'fulfilled'
            })
          })
        }
      } catch (e) {
        commit('mutate', {
          property: 'status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async fetchLatestInactive({ commit, dispatch }) {
      try {
        if (this.state.session.company[0].id) {
          await Axios({
            method: 'post',
            url: process.env.VUE_APP_BACKEND + 'products/inactive',
            data: {
              id: this.state.session.company[0].id,
              role: this.state.session.data.role
            },
            headers: {
              'Content-Type': 'text/plain',
              Authorization: `Bearer ${this.state.session.data.access_token}`
            }
          }).then((response) => {
            commit('mutate', {
              property: 'latest.inactives',
              with: response.data.original
            })
            commit('mutate', {
              property: 'latest.inactives.status',
              with: 'fulfilled'
            })
          })
        }
      } catch (e) {
        commit('mutate', {
          property: 'status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
