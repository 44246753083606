
import { Vue, Component, Ref } from 'vue-property-decorator'
import Store from '@/store'
import Axios from 'axios'
import Papaparse from 'papaparse'
import exportFromJSON from 'export-from-json'

import type { VForm } from '@/types'

@Component({})
export default class PhonesBulk extends Vue {
  public loading = false

  public inputKey = 0

  public fileLoad: any = {
    file: null,
    loading: false
  }

  public bulkOperation = {
    visible: false,
    message: '',
    type: ''
  }

  public headers = [
    {
      text: 'Nombre',
      align: 'start',
      sortable: false,
      value: '0'
    },
    { text: 'Capacidad', value: '1' },
    { text: 'Modelo', value: '2' },
    { text: 'Nombre del grupo', value: '3' },
    { text: 'Marca', value: '4' },
    { text: 'Motivo', value: '5' }
  ]

  public bulkResult: unknown

  public logsOverlay = false
  public logsHeader = 'Los siguentes dispositivos no han sido agregados'

  public bulkLogs: any = []

  public added = 0

  @Ref('bulkForm')
  private readonly bulkForm!: VForm

  public downloadLogs() {
    const data = this.bulkLogs
    const fileName = `Upload_logs`
    const exportType = exportFromJSON.types.csv
    const fields = {
      '0': 'Nombre',
      '1': 'Capacidad',
      '2': 'Modelo',
      '3': 'Nombre del grupo',
      '4': 'Marca',
      '5': 'Motivo'
    }
    exportFromJSON({ data, fileName, exportType, fields })
  }

  public onClickClose() {
    this.bulkLogs = []
    this.added = 0
    this.$emit('cancel')
  }

  public handleBulkFormSubmit(event: Event) {
    event.preventDefault()
    if (this.bulkForm.validate()) {
      this.uploadCurrentOpenFile()
    }
  }

  public async uploadCurrentOpenFile() {
    this.bulkLogs = []
    this.added = 0
    this.loading = true
    if (this.isValidExcel) {
      try {
        this.bulkResult = await Axios.post(
          process.env.VUE_APP_BACKEND + 'groups/import',
          {
            file: this.fileLoad.file,
            assistance_id: Store.state.openProduct.root.data.id
          },
          {
            headers: {
              'Content-Type': 'text/plain',
              Authorization: `Bearer ${Store.state.session.data.access_token}`
            }
          }
        ).then((res) => {
          this.added = res.data.log.add
          if (res.data.log.no_add > 1) {
            this.logsHeader = 'Los siguentes dispositivos no han sido agregados'
            const data = res.data.no_add
            for (let i = 0; i < data.length; i++) {
              const element = Object.assign({}, data[i])
              if (element[1] !== 'Falta Columna') {
                this.bulkLogs.push(element)
              }
            }
          } else {
            this.$store.dispatch('system/showSuccess', `Se han añadiddo ${this.added} modelos`)
          }
        })
        this.fileLoad.file = null
        this.inputKey++
      } catch (e) {
        if (this.fileLoad.file.data.length > 1) {
          this.logsHeader =
            'Hubo un error en nuestro servicio, por favor inténtalo más tarde, dispositivos no agregados:'
          let source = this.fileLoad.file.data
          for (let i = 0; i < source.length; i++) {
            const model = { '5': 'Error en el servidor' }
            let element = Object.assign(model, source[i])
            if (element[0] != 'nombre' && element[1]) {
              this.bulkLogs.push(element)
            }
          }
          this.logsOverlay = true
        }
        this.bulkOperation = {
          type: 'error',
          message: 'Ha ocurrido un error procesar tu archivo, porfavor contacta con el equipo de soporte.',
          visible: true
        }
      }
    }
    await Store.dispatch('groups/fetchRoot', () => true)
    this.loading = false
    if (this.bulkLogs.length > 1) {
      await setTimeout(() => {
        this.logsOverlay = true
      }, 1)
    } else {
      this.$emit('cancel')
    }
  }

  public loadFileIntoModel(file?: File) {
    if (!file) {
      this.fileLoad.file = null
    } else {
      this.fileLoad.file = Papaparse.parse(file, {
        complete: (data) => {
          this.fileLoad.file = data
        }
      })
    }
  }

  public get isValidExcel() {
    return this.validateExcel(this.fileLoad.file).length === 0
  }

  public get validationErrors() {
    return this.validateExcel(this.fileLoad.file)
  }

  public validateExcel(data: any) {
    if (!data) {
      return []
    }
    const errors: string[] = []

    if (
      data.data[0][0] !== 'nombre' ||
      data.data[0][1] !== 'capacidad' ||
      data.data[0][2] !== 'modelo' ||
      data.data[0][3] !== 'nombre_grupo' ||
      data.data[0][4] !== 'marca'
    ) {
      errors.push('El archivo no tiene las cabeceras válidas')
    }
    if (data.data[1][0] === '') {
      errors.push('El archivo parece estar vacío')
    }

    return errors
  }
}
