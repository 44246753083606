import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/src/locale/es'
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },
  icons: {
    iconfont: 'mdi',
    values: {
      'tdi-state-empty-apps': 'tdi tdi-state-empty-apps',
      'tdi-state-empty-devices': 'tdi tdi-state-empty-devices',
      'tdi-message-banner': 'tdi tdi-message-banner',
      'tdi-state-empty-chart': 'tdi tdi-state-empty-chart',
      'tdi-state-empty-maps': 'tdi tdi-state-empty-maps',
      'tdi-send': 'tdi tdi-send',
      'tdi-state-empty-labels': 'tdi tdi-state-empty-labels',
      'tdi-state-empty-identities': 'tdi tdi-state-empty-identities',
      'tdi-message-notification': 'tdi tdi-message-notification',
      'tdi-message-video': 'tdi tdi-message-video',
      'tdi-no-segments': 'tdi tdi-no-segments',
      'tdi-message-dialog': 'tdi tdi-message-dialog',
      'tdi-state-error': 'tdi tdi-state-error'
    }
  },
  theme: {
    options: {
      variations: true,
      customProperties: true,
      minifyTheme,
      themeCache: {
        get: (key) => localStorage.getItem(JSON.stringify(key)),
        set: (key, value) => localStorage.setItem(JSON.stringify(key), value)
      }
    },
    themes: {
      light: {
        warning: '#FFB132',
        error: '#DC143C',
        success: '#228B22',
        grey: {
          lighten1: '#EEF1F4',
          base: '#C6C6C6',
          darken1: '#a093a9'
        },
        primary: {
          base: '#1a2128'
        },
        secondary: {
          base: '#651FFF'
        },
        tertiary: {
          base: '#374D65'
        },
        clear: {
          base: '#50E3C2'
        }
      }
    }
  }
})
