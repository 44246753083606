
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store'

@Component
export default class PendingCard extends Vue {
  // Props
  @Prop({ default: '', type: String })
  id!: string

  public loading = true

  public page = 1

  public pageCount = 0

  public headers: any[] = [
    { text: 'Compañía', value: 'img', order: false },
    { text: 'Nombre', value: 'name', order: false },
    { text: 'Correo electrónico', value: 'email', order: false },
    { text: 'Opciones', value: 'options', order: false }
  ]

  @Watch('id')
  async idChanged(newVal: string) {
    console.log('CAMBIO DE ID => ACTUALIZARÁ SCREEN TEST')
    this.loading = true;
    await Store.dispatch('openAlert/fetchScreenTest', newVal)
    if(this.$route.params.id != newVal){
      this.$router.push({params: {id: newVal}})
    }
    this.loading = false;
  }

  get alertDetail() {
    return this.$store.getters['openAlert/data']
  }

  public async approveCurrent() {
    this.loading = true;
    let operator_email = this.$store.state.session.userinfo.email;
    await Store.dispatch('openAlert/evalScreenTest', { approved: true, email: operator_email })
    await Store.dispatch('openAlert/fetchScreenTest', this.$store.state.openAlert.openScreenTest.data.id)
    this.loading = false;
  }

  public async rejectCurrent() {
    this.loading = true;
    let operator_email = this.$store.state.session.userinfo.email;
    await Store.dispatch('openAlert/evalScreenTest', { approved: false, email: operator_email })
    await Store.dispatch('openAlert/fetchScreenTest', this.$store.state.openAlert.openScreenTest.data.id)
    this.loading = false;
  }

  public async isAnswered() {
    return false
  }

  get isLinkedToAssistance() {
    return !this.$store.state.openAlert.openScreenTest.data.assistance_device.assistances
  }
}
