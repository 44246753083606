import { render, staticRenderFns } from "./ClientColorPicker.vue?vue&type=template&id=3d4374d7&scoped=true"
import script from "./ClientColorPicker.vue?vue&type=script&lang=ts"
export * from "./ClientColorPicker.vue?vue&type=script&lang=ts"
import style0 from "./ClientColorPicker.vue?vue&type=style&index=0&id=3d4374d7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4374d7",
  null
  
)

export default component.exports