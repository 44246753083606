
import { Vue, Component } from 'vue-property-decorator'
import { IOn } from '@/types'
import CreationSteppers from '@/components/ProductComponents/CreationSteppers.vue'
import ProductForm from '@/components/ProductComponents/ProductForm.vue'
import Store from '@/store'
import ProductSmallCard from '@/components/ProductComponents/ProductSmallCard.vue'
import CreationSteppersConfirm from '@/components/ProductComponents/CreationSteppersConfirm.vue'

@Component({
  components: {
    ProductSmallCard,
    ProductForm,
    CreationSteppers,
    CreationSteppersConfirm
  }
})
export default class LatestProducts extends Vue {
  public dialog = false
  public confirmDialog = false

  $refs!: {
    form: HTMLFormElement
    productForm: HTMLFormElement
  }

  public async mounted() {
    await Store.dispatch('openClient/fetchConfig', () => true)
    await Store.dispatch('dashboard/fetchLatestProducts', () => true)
  }

  public confirmClose() {
    this.confirmDialog = true
  }

  public closeDialogs() {
    Store.commit('openProduct/resetState')
    this.$refs.form.resetForm()
    this.dialog = false
    this.confirmDialog = false
  }

  public async handleOpenDialog(on: IOn, event: Event) {
    Store.commit('openProduct/resetState')
    await Store.dispatch('misc/fetchCurrencies', () => true)
    await Store.dispatch('clients/fetchRoot', () => true)
    Store.commit('openProduct/mutate', {
      property: 'root.data.company_id',
      with: Store.state.session.company[0].id
    })

    if (this.$refs.productForm) {
      /* @ts-ignore */
      await this.$refs.productForm.resetForm()
    }

    Store.commit('openProduct/mutate', {
      property: 'step',
      with: 1
    })
    on.click(event)
  }
}
