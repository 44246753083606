
import { Vue, Component } from 'vue-property-decorator'

import DiagnosticList from '@/components/DiagnosticComponents/DiagnosticsList.vue'
import ClientSelect from '@/components/DiagnosticComponents/ClientSelect.vue'

@Component({
  components: {
    ClientSelect,
    DiagnosticList
  }
})
export default class openDiagnosticPage extends Vue {}
