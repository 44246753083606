
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator'
import { VForm } from '@/types'
import moment from 'moment'
import RouterGoBackBtn from '@/components/RouterComponents/RouterGoBackBtn.vue'
import Store from '@/store'

@Component({
  components: {
    RouterGoBackBtn
  }
})
export default class PromotionForm extends Vue {
  public error = false
  public loading = false

  public code = ''

  public menu = false
  public iniType = '0'
  public iniExpires = '0'

  get times() {
    return Store.state.openPromotion.root.times
  }

  public picker = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)

  @Prop(Boolean)
  public newly!: boolean

  @Ref('promoForm')
  private readonly promoForm!: VForm

  @Watch('picker')
  onChangeDate(val: any) {
    Store.commit('openPromotion/mutate', {
      property: 'root.formatDate',
      with: moment(val).format('DD/MM/YYYY')
    })
    Store.commit('openPromotion/mutate', {
      property: 'root.date',
      with: val
    })
    Store.commit('openPromotion/mutate', {
      property: 'root.stock',
      with: ''
    })
    this.menu = false
  }

  public handleCancel() {
    this.error = false
    this.code = ''
    this.menu = false
    this.iniType = '0'
    this.iniExpires = '0'
    this.$emit('cancel')
  }

  public type: any[] = [
    {
      text: 'Porcentaje',
      value: '1'
    },
    {
      text: 'Valor',
      value: '2'
    }
  ]
  public expires: any[] = [
    {
      text: 'Fecha',
      value: '1'
    },
    {
      text: 'Usos',
      value: '2'
    }
  ]

  public resetForm() {
    this.$nextTick(() => {
      this.promoForm.reset()
    })
  }

  handleType($event: any) {
    this.iniType = $event
    Store.commit('openPromotion/mutate', {
      property: 'root.type',
      with: $event
    })
  }

  uppercase() {
    this.code = this.code.toUpperCase()
  }

  handleExpires($event: any) {
    this.iniExpires = $event
    Store.commit('openPromotion/mutate', {
      property: 'root.expires',
      with: $event
    })
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    setTimeout(() => (this.loading = true), 1)
    this.promoForm.resetValidation()
    setTimeout(async () => {
      if (this.promoForm.validate()) {
        try {
          if (this.newly) await Store.dispatch('openPromotion/createPromotion')
          else await Store.dispatch('openPromotion/updateProduct')
          Store.commit('openPromotion/resetState')
          Store.dispatch('openProduct/fetchDiscounts', () => true)
          this.$emit('cancel')
        } catch (e) {
          this.error = true
        }
      }
      this.iniType = '0'
      this.iniExpires = '0'
      this.loading = false
    }, 1000)
    this.loading = false
  }
}
