
import { Component, Vue, Prop } from 'vue-property-decorator'
import RouterGoDeviceDetailsBtn from '@/components/RouterComponents/RouterGoDeviceDetailsBtn.vue'

@Component({
  components: {
    RouterGoDeviceDetailsBtn
  }
})
export default class DevicesDataTable extends Vue {
  @Prop(String)
  public readonly search!: string

  @Prop()
  public readonly value!: any

  @Prop(Boolean)
  public readonly hideControls!: boolean

  @Prop()
  public readonly type!: any

  public get headers() {
    const headers = [
      {
        text: 'Trust ID',
        align: 'left',
        sortable: true,
        value: 'trust_id'
      },
      {
        text: 'Correo asociado',
        align: 'left',
        sortable: true,
        value: 'user.email'
      },
      {
        text: 'Marca',
        value: 'code.phone.brand.name',
        sortable: true
      },
      {
        text: 'Modelo',
        value: 'code.phone.name',
        sortable: true
      },
      {
        text: 'Primer ingreso',
        value: 'date',
        sortable: true
      },
      {
        text: 'Detalle',
        value: 'detalle',
        sortable: false
      }
    ]

    return headers
  }
}
