
import { Vue, Component, Ref } from 'vue-property-decorator'
import { VForm } from '@/types'
import CreatePromotionClientSelect from '@/components/ProductComponents/CreatePromotionClientSelect.vue'
import CurrencyList from '@/components/ProductComponents/CurrencyList.vue'
import DiagnosticPickerForProduct from '@/components/ProductComponents/DiagnosticPickerForProduct.vue'

@Component({
  components: {
    CreatePromotionClientSelect,
    CurrencyList,
    DiagnosticPickerForProduct
  }
})
export default class ProductFormEmbed extends Vue {
  @Ref('productForm')
  private readonly productForm!: VForm
  public formContent = {
    title: '',
    currency: '',
    inAppTitle: '',
    description: ''
  }

  public changeCurrency(currency: any) {
    this.formContent.currency = currency
  }

  public resetForm() {
    this.$nextTick(() => {
      this.productForm.reset()
    })
  }

  public validate() {
    return this.productForm.validate()
  }
  public nextStep() {
    this.$store.commit('openProduct/mutate', {
      property: 'root.data.name',
      with: this.formContent.title
    })
    this.$store.commit('openProduct/mutate', {
      property: 'root.data.currency',
      with: this.formContent.currency
    })
    this.$store.commit('openProduct/mutate', {
      property: 'root.data.title_drop',
      with: this.formContent.inAppTitle
    })
    this.$store.commit('openProduct/mutate', {
      property: 'root.data.description',
      with: this.formContent.description
    })
    this.$emit('nextStep')
  }

  public prevStep() {
    this.$emit('prevStep')
  }
}
