
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class ClientSelectTable extends Vue {
  public async handleSelect($event: any) {
    Store.commit('users/resetState')
    Store.commit('users/mutate', {
      property: 'companyUid',
      with: $event
    })
    await Store.dispatch('users/fetchRoot', () => true)
  }
}
