import Vue from 'vue'
import moment from 'moment'
import VueMoment from 'vue-moment'
import 'moment/locale/es'

Vue.use(VueMoment, {
  moment
})

//@ts-ignore
Vue.moment().locale()
