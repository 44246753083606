
import { Vue, Component } from 'vue-property-decorator'
import DiagnosesGroup from './DiagnosesGroup.vue'

@Component({
  components: { DiagnosesGroup }
})
export default class DeviceDiagnosesGroups extends Vue {
  public expanded = null
  public get headers() {
    const headers = [
      {
        text: 'UUID',
        align: 'left',
        value: 'group_uuid'
      },
      {
        text: 'Estado',
        value: 'status'
      },
      {
        text: 'Fecha de Creación',
        value: 'created_at'
      },
      { text: '', value: 'data-table-expand' }
    ]

    return headers
  }
}
