
import { Vue, Component } from 'vue-property-decorator'
import YtLoadingBar from 'vue2-loading-bar'

@Component({
  components: {
    YtLoadingBar
  }
})
export default class LoadingBar extends Vue {
  public onProgressDone() {
    console.log('')
  }
  public onErrorDone() {
    this.$store.commit('system/mutate', {
      property: 'progressBar.error',
      with: false
    })
  }
}
