
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'
import BoughtSmallCard from '@/components/ProductComponents/BoughtSmallCard.vue'
import InactivesSmallCard from '@/components/ProductComponents/InactivesSmallCard.vue'

@Component({
  components: {
    BoughtSmallCard,
    InactivesSmallCard
  }
})
export default class LatestBoughts extends Vue {
  public listType = 'boughts'
  public inactivesFocused = false
  public boughtsFocused = true

  public changeList(mode: string) {
    this.listType = mode
    switch (this.listType) {
      case 'boughts':
        this.boughtsFocused = true
        this.inactivesFocused = false
        break

      case 'inactives':
        this.boughtsFocused = false
        this.inactivesFocused = true
        break
      default:
        break
    }
  }

  public async mounted() {
    await Store.dispatch('dashboard/fetchLatestBought', () => true)
    await Store.dispatch('dashboard/fetchLatestInactive', () => true)
  }
}
