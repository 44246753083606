
import { Vue, Component, Ref } from 'vue-property-decorator'
import { IOn, VForm } from '@/types'
import Store from '@/store'

@Component({
  components: {}
})
export default class ManageConfigsClient extends Vue {
  public error = false
  public loading = false
  public dialog = false

  public only_test = false
  public apple_login = true
  public certificate_view = false
  public validate_login = true

  public test_time = 0
  public hideOptionalTest = false
  public screen_shot_imei = false

  public deferred = false
  public advancedTest = true
  public screenWarning = false
  public screen_try = 0
  public show_email_text = false
  public show_video_tutorial = false

  public imei_items = [
    { method: 'Digitación', value: false },
    { method: 'Captura de pantalla', value: true }
  ]

  get company_name() {
    return Store.state.session.company[0].name
  }

  public setImeiMethod(value: boolean) {
    Store.commit('openClient/mutate', {
      property: `config.data.screen_shot_imei`,
      with: value
    })
  }

  public setSwitch(event: boolean, target: string) {
    switch (target) {
      case 'deferred':
        this.deferred = event
        break
      case 'show_screen_warning':
        this.screenWarning = event
        break
      case 'advanced_screen_test':
        this.advancedTest = event
        break
      case 'hide_optional_tests':
        this.hideOptionalTest = event
        break
      case 'only_test':
        this.only_test = event
        break
      case 'show_email_text':
        this.show_email_text = event
        break
      case 'show_video_tutorial':
        this.show_video_tutorial = event
        break
      case 'apple_login':
        this.apple_login = event
        break
      case 'certificate_view':
        this.certificate_view = event
        break
      case 'validate_login':
        this.validate_login = event
        break
      default:
        break
    }
    Store.commit('openClient/mutate', {
      property: `config.data.${target}`,
      with: event
    })
  }

  public set_test_time(hours: number) {
    Store.commit('openClient/mutate', {
      property: 'config.data.group_test_time',
      with: hours * 60
    })
  }

  @Ref('configForm')
  private readonly configForm!: VForm

  public async onClickHandler(on: IOn, $event: Event) {
    Store.commit('openClient/resetState')
    await Store.dispatch('openClient/fetchConfig', () => true)
    this.deferred = Store.state.openClient.config.data.deferred
    this.hideOptionalTest = Store.state.openClient.config.data.hide_optional_tests
    this.advancedTest = Store.state.openClient.config.data.advanced_screen_test
    this.screenWarning = Store.state.openClient.config.data.show_screen_warning
    this.test_time = Store.state.openClient.config.data.group_test_time / 60

    this.only_test = Store.state.openClient.config.data.only_test
    this.show_email_text = Store.state.openClient.config.data.show_email_text
    this.screen_try = Store.state.openClient.config.data.screen_try
    this.show_video_tutorial = Store.state.openClient.config.data.show_video_tutorial
    this.screen_shot_imei = Store.state.openClient.config.data.screen_shot_imei
    this.apple_login = Store.state.openClient.config.data.apple_login
    this.certificate_view = Store.state.openClient.config.data.certificate_view
    this.validate_login = Store.state.openClient.config.data.validate_login
    on.click($event)
    this.dialog = true
  }

  public onClickClose() {
    this.dialog = false
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    setTimeout(() => (this.loading = true), 1)
    setTimeout(async () => {
      if (this.configForm.validate()) {
        try {
          await Store.dispatch('openClient/updateConfig')
          this.dialog = false
        } catch (e) {
          this.error = true
        }
      }
      this.loading = false
    }, 1000)
    this.loading = false
  }
}
