
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class ProductSmallCard extends Vue {
  @Prop()
  public readonly product!: any

  get clientConfig() {
    return {
      thousands_sep: Store.state.openClient.config.data.thousands_sep,
      dec_point: Store.state.openClient.config.data.dec_point
    }
  }
}
