
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({
  components: {}
})
export default class ProductPriceDetails extends Vue {
  public items = [1, 3, 6, 12]
  public switch1 = true
  public select = ""

  mounted() {
     this.switch1 = Store.state.openProduct.root.data.show_month
     this.select = Store.state.openProduct.root.data.month_number
  }

 

  public toggleShow($event: boolean) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.show_month',
      with: $event
    })
  }
  public updateMonths($event: number) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.month_number',
      with: $event
    })
  }
}
