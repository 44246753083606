
import { Vue, Component } from 'vue-property-decorator'
import Greetings from '@/components/DashboardComponents/Greetings.vue'
import LoadingApp from '@/components/AppBaseComponents/LoadingApp.vue'
import LatestProducts from '@/components/DashboardComponents/LatestProducts.vue'
import LatestBoughts from '@/components/DashboardComponents/LatestBoughts.vue'
import Reload from '@/components/DashboardComponents/Reload.vue'

@Component({
  components: {
    Greetings,
    LoadingApp,
    LatestProducts,
    LatestBoughts,
    Reload
  }
})
export default class DashboardPage extends Vue {}
