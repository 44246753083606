import { render, staticRenderFns } from "./InactivesSmallCard.vue?vue&type=template&id=6dd49f11&scoped=true"
import script from "./InactivesSmallCard.vue?vue&type=script&lang=ts"
export * from "./InactivesSmallCard.vue?vue&type=script&lang=ts"
import style0 from "./InactivesSmallCard.vue?vue&type=style&index=0&id=6dd49f11&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dd49f11",
  null
  
)

export default component.exports