import { Module } from 'vuex'
import * as VueDeepSet from 'vue-deepset'
import Axios from 'axios'

export const openClient: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      id: '',
      company_uid: ''
    },
    details: {
      data: {
        company_uid: '',
        name: '',
        logo: '',
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        title_drop: '',
        phone_number: '',
        email_contact: '',
        whatsapp_contact: '',
        second_logo: '',
        web_url: ''
      }
    },
    config: {
      data: {
        name: 'default',
        url: '',
        tos_url: '',
        chat_url: '',
        channel_Client_secret: '',
        channel_client_id: '',
        channel_url: '',
        only_test: false,
        redirect_text_button: '',
        redirect_url: '',
        apple_login: true,
        certificate_view: true,
        validate_login: true,
        custom_msj: [],
        delete_msj: '',
        group_test_time: 0,
        hide_optional_tests: false,
        screen_shot_imei: false,
        deferred: false,
        advanced_screen_test: true,
        screen_test_endpoint: '',
        show_screen_warning: false,
        screen_warning_msg: '',
        show_email_text: false,
        screen_try: 0,
        screen_email_text: '',
        screen_email: '',
        show_video_tutorial: false,
        tutorial_url: '',
        thousands_sep: '',
        dec_point: ''
      }
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          id: '',
          company_uid: ''
        },
        details: {
          data: {
            company_uid: '',
            name: '',
            logo: '',
            primary: '#FFFFFF',
            secondary: '#FFFFFF',
            title_drop: '',
            phone_number: '',
            email_contact: '',
            whatsapp_contact: '',
            second_logo: ''
          }
        },
        config: {
          data: {
            name: '',
            url: '',
            tos_url: '',
            chat_url: '',
            channel_Client_secret: '',
            channel_client_id: '',
            channel_url: '',
            only_test: false,
            redirect_text_button: '',
            redirect_url: '',
            apple_login: true,
            certificate_view: true,
            validate_login: true,
            custom_msj: [],
            delete_msj: '',
            group_test_time: 0,
            hide_optional_tests: false,
            screen_shot_imei: false,
            deferred: false,
            advanced_screen_test: true,
            screen_test_endpoint: '',
            show_screen_warning: false,
            screen_warning_msg: '',
            show_email_text: false,
            screen_try: 0,
            screen_email_text: '',
            screen_email: '',
            show_video_tutorial: false,
            tutorial_url: '',
            thousands_sep: '',
            dec_point: ''
          }
        }
      })
    }
  },
  actions: {
    async fetchDetails({ commit, dispatch }) {
      try {
        if (!this.state.openClient.root.company_uid)
          this.state.openClient.root.company_uid = parseInt(this.state.session.company[0].company_uid)
        await Axios.get(process.env.VUE_APP_BACKEND + 'company/' + this.state.openClient.root.company_uid, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'details.data',
            with: response.data.original.data[0]
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async fetchConfig({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'config/' + parseInt(this.state.session.company[0].id), {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          if (response.data.original.data[0]) {
            const custom_msj = JSON.parse(response.data.original.data[0].custom_msj.replace(/(&quot;)/g, '"'))
            commit('mutate', {
              property: 'config.data',
              with: response.data.original.data[0]
            })
            commit('mutate', {
              property: 'config.data.custom_msj',
              with: custom_msj
            })
          }
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async updateClient({ dispatch }) {
      try {
        let color1 = this.state.openClient.details.data.primary
        if (this.state.openClient.details.data.primary === '') color1 = '#FFFFFF'

        let color2 = this.state.openClient.details.data.secondary
        if (this.state.openClient.details.data.secondary === '') color2 = '#FFFFFF'

        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'company/createUpdate',
          data: {
            company_uid: parseInt(this.state.openClient.details.data.company_uid),
            name: this.state.openClient.details.data.name,
            logo: this.state.openClient.details.data.logo,
            primary: color1,
            secondary: color2,
            title_drop: this.state.openClient.details.data.title_drop,
            phone_number: this.state.openClient.details.data.phone_number,
            email_contact: this.state.openClient.details.data.email_contact,
            whatsapp_contact: this.state.openClient.details.data.whatsapp_contact,
            second_logo: this.state.openClient.details.data.second_logo,
            web_url: this.state.openClient.details.data.web_url
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Operación realizada correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async updateConfig({ dispatch }) {
      try {
        if (this.state.openClient.config.data.name === '') this.state.openClient.config.data.name = 'default'
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'config/createUpdate',
          data: {
            company_id: parseInt(this.state.session.company[0].id),
            name: this.state.openClient.config.data.name,
            url: this.state.openClient.config.data.url,
            tos_url: this.state.openClient.config.data.tos_url,
            chat_url: this.state.openClient.config.data.chat_url,
            custom_msj: this.state.openClient.config.data.custom_msj,
            delete_msj: this.state.openClient.config.data.delete_msj,
            group_test_time: this.state.openClient.config.data.group_test_time,
            deferred: this.state.openClient.config.data.deferred,
            hide_optional_tests: this.state.openClient.config.data.hide_optional_tests,
            advanced_screen_test: this.state.openClient.config.data.advanced_screen_test,
            channel_Client_secret: this.state.openClient.config.data.channel_Client_secret,
            channel_client_id: this.state.openClient.config.data.channel_client_id,
            channel_url: this.state.openClient.config.data.channel_url,
            dec_point: this.state.openClient.config.data.dec_point,
            thousands_sep: this.state.openClient.config.data.thousands_sep,
            screen_warning_msg: this.state.openClient.config.data.screen_warning_msg,
            show_screen_warning: this.state.openClient.config.data.show_screen_warning,
            screen_test_endpoint: this.state.openClient.config.data.screen_test_endpoint,
            only_test: this.state.openClient.config.data.only_test,
            redirect_text_button: this.state.openClient.config.data.redirect_text_button,
            redirect_url: this.state.openClient.config.data.redirect_url,
            show_email_text: this.state.openClient.config.data.show_email_text,
            screen_email: this.state.openClient.config.data.screen_email,
            screen_email_text: this.state.openClient.config.data.screen_email_text,
            screen_try: this.state.openClient.config.data.screen_try,
            show_video_tutorial: this.state.openClient.config.data.show_video_tutorial,
            tutorial_url: this.state.openClient.config.data.tutorial_url,
            screen_shot_imei: this.state.openClient.config.data.screen_shot_imei,
            apple_login: this.state.openClient.config.data.apple_login,
            certificate_view: this.state.openClient.config.data.certificate_view,
            validate_login: this.state.openClient.config.data.validate_login
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Operación realizada correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async deleteClient({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'company/delete',
          data: {
            company_uid: parseInt(this.state.openClient.details.data.company_uid)
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Cliente eliminado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
