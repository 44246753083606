import { Module } from 'vuex'
import Axios from 'axios'
import { genericMutate, genericReset } from '../store.mutations'

const defaultState = () => ({
  data: [],
  client: {
    company_id: ''
  },
  parameters: {
    camera: null as any,
    touch: null as any,
    screen: null as any
  }
})

export const openDiagnostic: Module<ReturnType<typeof defaultState>, any> = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    mutate: genericMutate,
    resetState: genericReset(defaultState)
  },
  actions: {
    async fetchParameters({ commit, dispatch }) {
      try {
        await Axios.get<{ camera: any[]; screen: any[]; touch: any[] }>(
          process.env.VUE_APP_BACKEND + 'parameters/' + this.state.openDiagnostic.client.company_id,
          {
            withCredentials: false,
            responseType: 'json',
            headers: {
              Authorization: `Bearer ${this.state.session.data.access_token}`
            }
          }
        ).then((response) => {
          commit('mutate', {
            property: 'data',
            with: response.data
          })

          const { camera, touch, screen } = response.data

          const reductor = (value: any[]) =>
            value.reduce((accumulator, parameter) => {
              accumulator = { ...accumulator, [parameter.name]: parameter }
              return accumulator
            }, {} as any)

          commit('mutate', {
            property: 'parameters',
            with: {
              camera: reductor(camera),
              touch: reductor(touch),
              screen: reductor(screen)
            }
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async saveParameters({ dispatch, state }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'parameters',
          data: {
            company_id: this.state.openDiagnostic.client.company_id,
            /**
             * Como transformamos a objeto lo que nos llega del backend, debemos transformarlo a un arreglo denuevo
             * ? El backend deberia hacer esta pega ?
             */
            parameters: Object.entries(state.parameters).reduce((accumulator, [pKey, pValue]) => {
              accumulator = { ...accumulator, [pKey]: Object.values(pValue) }
              return accumulator
            }, {})
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Parámetros de diagnóstico actualizados', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async save({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'phones/save',
          data: {
            phones: [],
            tests: this.state.temp.test
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Operación realizada correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
