if (module.hot) {
  // eslint-disable-next-line
  module.hot.accept(function (err: any) {
    console.log('An error occurred while accepting new version')
  })
}

/**
 * ? We really need polyfills ?
 */
import 'babel-polyfill'

/**
 * * Import core functionalities
 */
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import vuetify from './plugins/vuetify'
import { thousandsSeparator } from './plugins/thousands-separator'
/**
 * * Import all plugins from Plugins folder
 */
import './plugins/class-component-hooks'
import './plugins/global-components'
import './plugins/moment'
import './plugins/simple-rules'

/**
 * * Import all styles from Styles folder
 */
import '@/assets/styles/index.scss'

import App from '@/App.vue'

/**
 * * Main program execution
 */
async function bootstrap() {
  try {
    Vue.config.productionTip = false
    Vue.config.performance = true
    Vue.use(thousandsSeparator)
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App)
    }).$mount('#app')
  } catch (e) {
    console.error(e)
  }
}

bootstrap()
