
import { Component, Vue } from 'vue-property-decorator'
import ProductsDataTable from '@/components/ProductComponents/ProductsDataTable.vue'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import CreateProductBtn from '@/components/ProductComponents/CreateProductBtn.vue'
import ClientSelect from '@/components/ProductComponents/ClientSelect.vue'

@Component({
  components: {
    ProductsDataTable,
    SearchBox,
    CreateProductBtn,
    ClientSelect
  }
})
export default class products extends Vue {
  public search = ''
}
