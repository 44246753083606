
import { Vue, Component } from 'vue-property-decorator'
import { IOn } from '@/types'
import PromotionForm from '@/components/ProductComponents/Promotions/PromotionForm.vue'
import Store from '@/store'

@Component({
  components: {
    PromotionForm
  }
})
export default class CreatePromotionBtn extends Vue {
  public dialog = false

  public async handleOpenDialog(on: IOn, event: Event) {
    Store.commit('openPromotion/resetState')
    if (this.$refs.formPromotion) {
      /* @ts-ignore */
      await this.$refs.formPromotion.resetForm()
    }
    on.click(event)
  }
}
