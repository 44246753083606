
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CreationSteppersConfirm extends Vue {
  public cancelExit() {
    this.$emit('cancelExit')
  }

  public confirmExit() {
    this.$emit('confirmExit')
  }
}
