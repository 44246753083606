
import { Vue, Component, Ref } from 'vue-property-decorator'
import UserForm from '@/components/UserComponents/UserForm.vue'
import { VForm, IOn } from '@/types'
import Store from '@/store'

@Component({
  components: {
    UserForm
  }
})
export default class CreateUserBtn extends Vue {
  public dialog = false
  public valid = true
  public loading = false

  @Ref('form')
  public formRef!: VForm

  @Ref('bulkForm')
  public bulkFormRef!: VForm

  public mounted() {
    this.$store.dispatch('companies/fetchRoot')
  }

  public async handleOpenDialog(on: IOn, event: Event) {
    Store.commit('openUser/resetState')
    if (this.$refs.formUser) {
      /* @ts-ignore */
      await this.$refs.formUser.resetForm()
    }
    on.click(event)
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    this.loading = true
    if (this.formRef.validate()) {
      try {
        await Store.dispatch('openUser/createUserFromDetails')
        Store.commit('openUser/resetState')
        Store.dispatch('users/fetchRoot', () => true)
        this.$router.push({ path: '/users' })
      } catch (error) {
        Store.dispatch('system/showError', { error })
      } finally {
        this.dialog = false
        this.loading = false
      }
    }
  }
}
