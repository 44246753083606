import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const alerts: Module<any, any> = {
  namespaced: true,
  state: () => ({
    index: null, // ID
    root: {
      data: [],
      status: 'fetching'
    },
    pending: {
      data: [],
      status: 'fetching'
    },
    approved: {
      data: [],
      status: 'fetching'
    },
    rejected: {
      data: [],
      status: 'fetching'
    }
  }),
  getters: {
    data(state) {
      return state.root.data
    },
    pending(state) {
      return state.pending.data
    },
    approved(state) {
      return state.approved.data
    },
    rejected(state) {
      return state.rejected.data
    }
  },
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        pending: {
          data: [],
          status: 'fetching'
        },
        approved: {
          data: [],
          status: 'fetching'
        },
        rejected: {
          data: [],
          status: 'fetching'
        }
      })
    }
  },
  actions: {
    async fetchTable({ commit, dispatch }, table) {
      try {
        commit('mutate', {
          property: `${table}.status`,
          with: 'fetching'
        })
        commit('mutate', {
          property: `${table}.data`,
          with: []
        })
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'alerts?filter=' + table,
          data: {
            company_id: this.state.session.company[0].id,
            role: this.state.session.data.role
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: `${table}.data`,
            with: response.data.original.data
          })
          commit('mutate', {
            property: `${table}.status`,
            with: 'fulfilled'
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'root.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
