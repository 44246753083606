
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class ClientSelect extends Vue {
  public async handleSelect($event: any) {
    Store.commit('openDiagnostic/mutate', {
      property: 'client.company_id',
      with: $event
    })

    await Store.dispatch('openDiagnostic/fetchParameters', () => true)
  }
}
