
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOn } from '@/types'
import Store from '@/store'
import Axios from 'axios'

@Component({})
export default class DeepLink extends Vue {
  public dialog1 = false
  public dialog2 = false
  public dialog3 = false
  public loading = false
  public link = ''
  public code = ''
  public platform = ''
  public resp: any = null

  @Prop()
  public readonly value!: any

  public async handleOpenDialog(on: IOn, event: Event, value: any, os: any) {
    setTimeout(() => {
      this.link = ''
      this.code = value
      this.platform = os
      on.click(event)
    }, 1)
  }

  onClickClose() {
    this.dialog1 = false
    this.dialog2 = false
    this.dialog3 = false
  }

  public async handleGet() {
    this.loading = true

    try {
      const response = await Axios.post(
        process.env.VUE_APP_BACKEND + 'discounts/link',
        JSON.stringify({
          code: this.code,
          platform: this.platform
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.state.session.data.access_token}`
          }
        }
      )
      this.link = response.data
    } catch (e) {
      this.link = ''
    } finally {
      this.loading = false
    }
  }
}
