import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const companies: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {}
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, { root: {} })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_COMPANY, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(response => {
          commit('mutate', {
            property: 'root',
            with: response.data
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
