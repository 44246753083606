
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class CurrencyList extends Vue {
  @Prop({ type: Array, default: () => [] })
  public readonly rules!: any[]
  public currencyValue = Store.state.openProduct.root.data.currency

  public async handleSelect($event: any) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.currency',
      with: $event
    })
  }

  public showCurrency() {
    this.$emit('currencyChange', this.currencyValue)
  }
}
