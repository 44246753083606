
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class CreatePromotionClientSelect extends Vue {
  @Prop({ type: Array, default: () => [] })
  public readonly rules!: any[]

  public async handleSelect($event: any) {
    Store.commit('openProduct/mutate', {
      property: 'root.data.company_id',
      with: $event
    })
  }
}
