
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOn } from '@/types'
import Store from '@/store'
import ClientForm from '@/components/ClientComponents/ClientForm.vue'

@Component({
  components: {
    ClientForm
  }
})
export default class EditClientListItem extends Vue {
  public dialogEdit = false
  public dialogDelete = false

  @Prop()
  private readonly value!: any

  public async handleOpenDialog(on: IOn, event: Event) {
    Store.commit('openClient/mutate', {
      property: 'root.company_uid',
      with: this.value.company_uid
    })
    Store.commit('openClient/mutate', {
      property: 'root.name',
      with: this.value.name
    })
    await Store.dispatch('openClient/fetchDetails', () => true)
    on.click(event)
  }

  onClickClose() {
    this.dialogEdit = false
    this.dialogDelete = false
  }

  public async handleDelete() {
    //this.loading = true;
    await Store.dispatch('openClient/deleteClient')
    //this.loading = false;
    await Store.dispatch('clients/fetchRoot', () => true)
    this.dialogDelete = false
  }
}
