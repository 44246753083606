
import { Vue, Component, Prop } from 'vue-property-decorator'
import debounce from 'lodash/debounce'

@Component({})
export default class SearchBox extends Vue {
  public debouncedInput = ''
  public toggleMenu = false
  public debounceInput: any = debounce((event) => {
    this.emitEvent(event)
  }, 500)

  public defaultPlaceholder = 'Buscar'

  @Prop(Boolean)
  public readonly reversed!: boolean

  @Prop()
  public readonly value!: any

  @Prop(String)
  public placeholder!: string

  public emitEvent(value: any) {
    this.$emit('input', value)
  }
}
