
import { Vue, Component } from 'vue-property-decorator'
import PendingCard from '@/components/AlertComponents/PendingCard.vue'
import RouterGoAlertListBtn from '@/components/RouterComponents/RouterGoAlertsListBtn.vue'
import Store from '@/store'

@Component({
  components: {
    RouterGoAlertListBtn,
    PendingCard
  }
})
export default class ProductDetailPage extends Vue {
  public gt = "welcome to my app"

  public mounted() {
    console.log('created')
    if(this.$route.params.id){
      console.log(this.$route.params.id)
      this.test(this.$route.params.id)
    }else {
      console.log('must be find')
    }
  }

  public async next() {
    console.log(this.$store.state.openAlert.openScreenTest.data)
    console.log(this.$store.state.openAlert.openScreenTest.data.test_uuid)
    await Store.dispatch('openAlert/fetchNextScreenTest',  {
      direction: 'next',
      screen_test_id: this.$store.state.openAlert.openScreenTest.data.test_uuid
    })
    this.gt = this.$store.state.openAlert.openScreenTest.data.test_uuid;
  }

  public async prev() {
    const response = await Store.dispatch('openAlert/fetchNextScreenTest', {
      direction: 'prev',
      screen_test_id: this.$store.state.openAlert.openScreenTest.data.test_uuid
    })
    this.gt = this.$store.state.openAlert.openScreenTest.data.test_uuid;
  }

  public test(id:string) {
    if(!this.$route.params.id){
      this.$router.push({params: {id: this.gt}})
    } else {
      console.log('ENVIANDO PARÁMETRO : ' + id)
      this.gt = id
    }
  }
}
