
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'
import _ from '@/main.utils'

@Component({
  components: {}
})
export default class DiagnosticPickerForProduct extends Vue {
  public tests = []
  public result = [
    {
      test_id: 0,
      assistance_id: 0
    }
  ]

  public diagnostics = [
    {
      id: 1,
      name: 'Pantalla Táctil'
    },
    {
      id: 2,
      name: 'Botones'
    },
    {
      id: 3,
      name: 'Cámara Trasera'
    },
    {
      id: 4,
      name: 'Pantalla'
    },
    {
      id: 5,
      name: 'Wi-fi'
    },
    {
      id: 6,
      name: 'Bluetooth'
    },
    {
      id: 7,
      name: 'Flash'
    },
    {
      id: 8,
      name: 'Auricular'
    },
    {
      id: 9,
      name: 'GPS'
    },
    {
      id: 10,
      name: 'IMEI'
    }
  ]

  public mounted() {
    this.tests = Store.state.openProduct.root.data.tests.map((a: any) => a.test_id)
  }

  handleTest(test: any, status: boolean) {
    if (status) {
      this.result = _.union(Store.state.openProduct.root.data.tests, [
        { test_id: test, assistance_id: Store.state.openProduct.root.data.id }
      ])
    } else {
      this.result = _.reject(Store.state.openProduct.root.data.tests, function (pt) {
        return pt.test_id == test
      })
    }
    /*
    Store.commit('temp/mutate', {
      property: 'test.' + this.phone.id + '_' + test + '.status',
      with: status
    })
    Store.commit('temp/mutate', {
      property: 'test.' + this.phone.id + '_' + test + '.id',
      with: this.phone.id
    })
    Store.commit('temp/mutate', {
      property: 'test.' + this.phone.id + '_' + test + '.test',
      with: test
    })
    Store.commit('temp/mutate', {
      property: 'changes',
      with: true
    })
    */
    Store.commit('openProduct/mutate', {
      property: 'root.data.tests',
      with: this.result
    })

    this.tests = Store.state.openProduct.root.data.tests.map((a: any) => a.test_id)
  }
}
