var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{staticClass:"pa-2",attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.onClickHandler(on, $event)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-badge-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Datos de "+_vm._s(_vm.$store.getters['session/userCompanyName'])+" ")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-container',{staticClass:"px-6"},[_c('v-form',{ref:"clientForm",attrs:{"valid":"valid"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"row":"","mx-0":"","mb-5":"","mt-2":"","justify-center":"","align-center":""}},[_c('v-flex',[_c('v-container',[_c('v-layout',{attrs:{"justify-space-between":""}},[_c('v-flex',{staticClass:"ml-4",attrs:{"shrink":""}},[_c('d-text',{staticClass:"primary--text",attrs:{"size":20}},[_vm._v(" "+_vm._s(_vm.$store.getters['session/userCompanyName'])+" ")])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-icon',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.onClickClose()}}},[_vm._v("mdi-close")])],1)],1),_c('v-layout',[_c('v-flex',{staticClass:"ml-4 mt-1 mb-8",attrs:{"shrink":""}},[_c('d-text',{staticClass:"primary--text",attrs:{"size":12}},[_vm._v(" Puedes modificar los datos de tu compañía. ")])],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs8":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Logo","required":"","rules":[_vm.$rules.required, _vm.$rules.isURL],"value":_vm.$store.state.openClient.details.data.logo},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                        property: 'details.data.logo',
                        with: $event
                      })}}})],1),_c('v-flex',{staticClass:"px-5",attrs:{"xs2":""}},[_c('v-img',{attrs:{"src":_vm.$store.state.openClient.details.data.logo,"contain":"","width":"50px"}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs8":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Segundo logo","value":_vm.$store.state.openClient.details.data.second_logo},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                        property: 'details.data.second_logo',
                        with: $event
                      })}}})],1),_c('v-flex',{staticClass:"px-5",attrs:{"xs2":""}},[_c('v-img',{attrs:{"src":_vm.$store.state.openClient.details.data.second_logo,"contain":"","width":"50px"}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs10":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Nombre en aplicación","required":"","rules":[_vm.$rules.required],"value":_vm.$store.state.openClient.details.data.title_drop},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                        property: 'details.data.title_drop',
                        with: $event
                      })}}})],1)],1),_c('v-layout',{staticClass:"mt-2"},[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs10":""}},[_c('v-layout',[_c('v-flex',{attrs:{"shrink":""}},[_c('d-text',{staticClass:"primary--text mx-2",attrs:{"size":12}},[_vm._v(" Principal: ")])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-dialog',{attrs:{"width":"250px","height":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',{staticClass:"color-picker__colorbox cursor-pointer",style:({
                              backgroundColor: _vm.$store.state.openClient.details.data.primary
                            }),on:{"click":function($event){return _vm.handleOpenDialog(on, $event)}}})]}}]),model:{value:(_vm.color1),callback:function ($$v) {_vm.color1=$$v},expression:"color1"}},[_c('v-card',{staticClass:"pa-4"},[_c('client-color-picker',{attrs:{"storeVar":"details.data.primary","colorStore":_vm.$store.state.openClient.details.data.primary},on:{"cancel":function($event){_vm.color1 = false}}})],1)],1)],1),_c('v-flex',{staticClass:"ml-2",attrs:{"shrink":""}},[_c('v-text-field',{staticClass:"color-hexa__colorbox",attrs:{"color":"primary","placeholder":"#","label":"HEX","outlined":"","value":_vm.$store.state.openClient.details.data.primary},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                            property: 'details.data.primary',
                            with: $event
                          })}}})],1),_c('v-flex',{staticClass:"ml-4",attrs:{"shrink":""}},[_c('d-text',{staticClass:"primary--text mx-2",attrs:{"size":12}},[_vm._v(" Secundario: ")])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-dialog',{attrs:{"width":"250px","height":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',{staticClass:"color-picker__colorbox cursor-pointer",style:({
                              backgroundColor: _vm.$store.state.openClient.details.data.secondary
                            }),on:{"click":function($event){return _vm.handleOpenDialog(on, $event)}}})]}}]),model:{value:(_vm.color2),callback:function ($$v) {_vm.color2=$$v},expression:"color2"}},[_c('v-card',{staticClass:"pa-4"},[_c('client-color-picker',{attrs:{"storeVar":"details.data.secondary","colorStore":_vm.$store.state.openClient.details.data.secondary},on:{"cancel":function($event){_vm.color2 = false}}})],1)],1)],1),_c('v-flex',{staticClass:"ml-2",attrs:{"shrink":""}},[_c('v-text-field',{staticClass:"color-hexa__colorbox",attrs:{"color":"primary","placeholder":"#","label":"HEX","outlined":"","value":_vm.$store.state.openClient.details.data.secondary},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                            property: 'details.data.secondary',
                            with: $event
                          })}}})],1)],1)],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs10":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Número de teléfono","required":"","value":_vm.$store.state.openClient.details.data.phone_number},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                        property: 'details.data.phone_number',
                        with: $event
                      })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs10":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Correo de contacto","required":"","value":_vm.$store.state.openClient.details.data.email_contact},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                        property: 'details.data.email_contact',
                        with: $event
                      })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs10":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Whatsapp de contacto","required":"","value":_vm.$store.state.openClient.details.data.whatsapp_contact},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                        property: 'details.data.whatsapp_contact',
                        with: $event
                      })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs1":""}}),_c('v-flex',{attrs:{"xs10":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Medio de contacto","required":"","value":_vm.$store.state.openClient.details.data.web_url},on:{"input":function($event){return _vm.$store.commit('openClient/mutate', {
                        property: 'details.data.web_url',
                        with: $event
                      })}}})],1)],1),_c('v-layout',{attrs:{"row":"","mr-2":"","align-center":"","justify-end":"","mt-5":""}},[_c('v-flex',{staticClass:"mr-5",attrs:{"shrink":""}},[_c('v-btn',{attrs:{"outlined":"","color":"secondary","depressed":""},on:{"click":function($event){return _vm.onClickClose()}}},[_vm._v("Cancelar")])],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","type":"submit","loading":_vm.loading}},[_vm._v("Actualizar")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }