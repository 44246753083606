
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({
  components: {}
})
export default class Auth extends Vue {
  public response = {}

  async mounted() {
    if (Store.state.session.auth === false) {
      if (this.$route.query.code) {
        Store.commit('session/mutate', { property: 'auth', with: true })
        Store.commit('session/mutate', {
          property: 'code',
          with: this.$route.query.code
        })
        this.$router.push('/')
      } else {
        window.location.replace(Store.getters['system/fullUrlAuth'])
      }
    }
    await Store.dispatch('session/fetchData')
    await Store.dispatch('session/fetchUserData')
    Store.commit('temp/resetState')
  }
}
