import { Module } from 'vuex'
import * as VueDeepSet from 'vue-deepset'

interface INavigationDrawer {
  model: boolean
}

export const navigationDrawer: Module<INavigationDrawer, any> = {
  namespaced: true,
  state: () => ({
    model: true,
    module: ''
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    }
  },
  actions: {
    toggleModel({ state, commit }) {
      commit('mutate', { property: 'model', with: !state.model })
    }
  }
}
