
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ProductTests extends Vue {
  public get headers() {
    const headers = [
      {
        text: 'Nombre',
        align: 'left',
        sortable: false,
        value: 'assistance.name'
      },
      {
        text: 'Precio',
        value: 'price',
        sortable: false
      },
      {
        text: 'Estado',
        value: 'status',
        sortable: false
      }
    ]

    return headers
  }

  public diagnostics = [
    {
      id: 1,
      name: 'Pantalla Táctil'
    },
    {
      id: 2,
      name: 'Botones'
    },
    {
      id: 3,
      name: 'Cámara Trasera'
    },
    {
      id: 4,
      name: 'Pantalla'
    },
    {
      id: 5,
      name: 'Wi-fi'
    },
    {
      id: 6,
      name: 'Bluetooth'
    },
    {
      id: 7,
      name: 'Flash'
    },
    {
      id: 8,
      name: 'Auricular'
    },
    {
      id: 9,
      name: 'GPS'
    }
    /* {
      id: 10,
      name: 'IMEI'
    } */
  ]
}
