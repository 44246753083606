
import { Vue, Component } from 'vue-property-decorator'
import EditClientListItem from '@/components/ClientComponents/EditClientListItem.vue'

@Component({
  components: {
    EditClientListItem
  }
})
export default class ClientsList extends Vue {
  public headers: any[] = [
    { text: 'Logo', value: 'logo' },
    { text: 'Nombre', value: 'name' },
    { text: 'Color primario', value: 'primary', align: 'center' },
    { text: 'Color secundario', value: 'secondary', align: 'center' },
    { text: 'Opciones', value: 'options', align: 'center' }
  ]
}
