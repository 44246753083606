<template>
  <v-app>
    <v-container v-if="this.$store.state.session.auth">
      <loading-bar />
      <toolbar />
      <navigation-drawer :key="this.$store.state.session.data.role" />
      <system-snackbar />
    </v-container>
    <!--<ModalSessionError :status-error="$store.state.system.progressBar.error" />-->
    <v-main>
      <v-container fluid>
        <transition mode="out-in" name="slide-right">
          <router-view />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from '@/components/AppBaseComponents/Toolbar.vue'
import NavigationDrawer from '@/components/AppBaseComponents/NavigationDrawer.vue'
import LoadingBar from '@/components/AppBaseComponents/LoadingBar.vue'
import SystemSnackbar from '@/components/AppBaseComponents/SystemSnackbar.vue'

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    Toolbar,
    LoadingBar,
    SystemSnackbar
  }
}
</script>

<style lang="scss">
.v-main__wrap {
  margin: 40px 21px;
  & > * {
    max-width: auto;
    min-width: auto;
    margin: auto;
  }
}

.theme--light {
  &.v-app-bar,
  &.v-application {
    background-color: #f8f9fa !important;
  }
}

.theme--dark {
  &.v-app-bar {
    background-color: #121212 !important;
  }
}
</style>
