
import { Vue, Component } from 'vue-property-decorator'
import { IOn } from '@/types'
import PhonesBulk from '@/components/ProductComponents/Groups/PhonesBulk.vue'

@Component({
  components: {
    PhonesBulk
  }
})
export default class CreateBulkBtn extends Vue {
  public dialog = false

  public async handleOpenDialog(on: IOn, event: Event) {
    on.click(event)
  }
}
