
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component({
  components: {}
})
export default class DiagnosticPickerEmbed extends Vue {
  public tests: number[] = []

  public diagnostics = [
    {
      id: 1,
      name: 'Pantalla Táctil'
    },
    {
      id: 2,
      name: 'Botones'
    },
    {
      id: 3,
      name: 'Cámara Trasera'
    },
    {
      id: 4,
      name: 'Pantalla'
    },
    {
      id: 5,
      name: 'Wi-fi'
    },
    {
      id: 6,
      name: 'Bluetooth'
    },
    {
      id: 7,
      name: 'Flash'
    },
    {
      id: 8,
      name: 'Auricular'
    },
    {
      id: 9,
      name: 'GPS'
    },
    {
      id: 10,
      name: 'IMEI'
    }
  ]
  @Prop()
  public readonly phone!: any

  @Prop()
  public readonly indexBrand!: any

  @Prop()
  public readonly indexModel!: any

  public handleTest(id: number, status: boolean) {
    if (status) {
      this.tests.push(id)
      Store.commit('openProduct/mutate', {
        property: 'root.data.tests',
        with: this.tests
      })
    } else {
      this.tests = this.tests.filter(function (item) {
        return item !== id
      })
      Store.commit('openProduct/mutate', {
        property: 'root.data.tests',
        with: this.tests
      })
    }
  }

  public handleTestToggle(diagnostic: any) {
    if (this.tests.includes(diagnostic.id)) {
      this.tests = this.tests.filter((item) => item !== diagnostic.id)
    } else {
      this.tests.push(diagnostic.id)
    }

    Store.commit('openProduct/mutate', {
      property: 'root.data.tests',
      with: this.tests
    })
  }

  public resetForm() {
    this.tests = []
  }
}
