
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'
import ProductForm from '@/components/ProductComponents/ProductForm.vue'

@Component({
  components: {
    ProductForm
  }
})
export default class ActionsForPromotion extends Vue {
  public dialogDrop = false
  public valid = true
  public loading = false

  @Prop()
  public readonly value!: any

  onClickClose() {
    this.dialogDrop = false
  }

  public async handleDelete() {
    Store.commit('openPromotion/mutate', {
      property: 'root.id',
      with: this.value
    })
    await Store.dispatch('openPromotion/deletePromotion')
    Store.dispatch('openProduct/fetchDiscounts', () => true)
  }
}
