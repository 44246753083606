var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',{staticClass:"px-6 py-4"},[_c('v-form',{ref:"companyForm",attrs:{"valid":"valid"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"row":"","mx-0":"","mb-5":"","mt-2":"","justify-center":"","align-center":""}},[_c('v-flex',[_c('v-container',[_c('v-layout',{staticClass:"mt-2"},[_c('v-flex',{attrs:{"md4":""}},[_c('v-subheader',[_vm._v("Datos Compañía")])],1),_c('v-flex',{attrs:{"md7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Nombre Companía","required":"","value":_vm.$store.state.openCompany.details.company.name,"rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openCompany/mutate', {
                      property: 'details.company.name',
                      with: $event
                    })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"md4":""}}),_c('v-flex',{attrs:{"md7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Descripción","rules":[_vm.$rules.required],"required":"","value":_vm.$store.state.openCompany.details.company.description},on:{"input":function($event){return _vm.$store.commit('openCompany/mutate', {
                      property: 'details.company.description',
                      with: $event
                    })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"md4":""}}),_c('v-flex',{attrs:{"md7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Correo electrónico","value":_vm.$store.state.openCompany.details.company.email,"rules":[_vm.$rules.required, _vm.$rules.isEmail]},on:{"input":function($event){return _vm.$store.commit('openCompany/mutate', {
                      property: 'details.company.email',
                      with: $event
                    })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"md4":""}},[_c('v-subheader',[_vm._v("Cargar logo")])],1),_c('v-flex',{attrs:{"md7":""}})],1),_c('v-layout',[_c('v-flex',{attrs:{"md4":""}}),_c('v-flex',{attrs:{"md7":""}},[_c('v-text-field',{attrs:{"rules":[_vm.$rules.required],"label":"Imagen URL","value":_vm.$store.state.openCompany.details.company.img},on:{"input":function($event){return _vm.$store.commit('openCompany/mutate', {
                      property: 'details.company.img',
                      with: $event
                    })}}})],1)],1),_c('v-layout',{attrs:{"row":"","mr-2":"","align-center":"","justify-end":"","mt-5":""}},[_c('v-flex',{staticClass:"mr-5",attrs:{"shrink":""}},[_c('v-btn',{attrs:{"outlined":"","color":"secondary","depressed":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancelar")])],1),_c('v-flex',{attrs:{"shrink":""}},[(_vm.newly)?_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","type":"submit","loading":_vm.loading}},[_vm._v("Crear")]):_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","type":"submit","loading":_vm.loading}},[_vm._v("Actualizar")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }