
import { Vue, Component, Prop } from 'vue-property-decorator'

const types = ['app', 'device', 'map', 'chart', 'user', 'segment'] as const

@Component({})
export default class OverlayEmpty extends Vue {
  @Prop({
    type: String,
    default: 'app',
    validator: (value: unknown): boolean => {
      if (!value || typeof value !== 'string') {
        return false
      }
      const val = value as typeof types[number]
      return types.includes(val)
    }
  })
  public readonly type!: typeof types[number]

  @Prop({ type: String, default: 'NO EXISTEN DATOS AÚN' })
  public readonly text!: string

  @Prop(Boolean)
  public readonly value!: boolean

  @Prop({ type: Boolean, default: true })
  public readonly showArrows!: boolean

  @Prop({ type: Number, default: 0.8 })
  public readonly opacity!: number
}
