
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'
import RouterGoProductDetailsBtn from '@/components/RouterComponents/RouterGoProductDetailsBtn.vue'
import ActionsForDevice from '@/components/ProductComponents/Devices/ActionsForDevice.vue'

@Component({
  components: {
    RouterGoProductDetailsBtn,
    ActionsForDevice
  }
})
export default class ProductDevicesDataTable extends Vue {
  @Prop(String)
  public readonly search!: string

  @Prop()
  public readonly value!: any

  @Prop()
  public readonly status!: any

  @Prop(Boolean)
  public readonly hideControls!: boolean

  public get headers() {
    const headers = [
      {
        text: 'Trust Id',
        align: 'center',
        sortable: true,
        value: 'device.trust_id'
      },
      {
        text: 'Dispositivo',
        value: 'code',
        align: 'center',
        sortable: true
      },
      {
        text: 'Estado',
        align: 'center',
        value: 'states',
        sortable: true
      },
      {
        text: 'Valor',
        value: 'formatted_price',
        align: 'center',
        sortable: true
      },
      {
        text: 'Fecha compra',
        value: 'formatted_date',
        align: 'center',
        sortable: true
      },
      {
        text: 'Fecha expiración',
        value: 'formatted_expires',
        align: 'center',
        sortable: true
      },
      {
        text: 'Anular',
        value: 'action',
        sortable: false
      }
    ]

    return headers
  }
  get clientConfig() {
    return {
      thousands_sep: Store.state.openClient.config.data.thousands_sep,
      dec_point: Store.state.openClient.config.data.dec_point
    }
  }
}
