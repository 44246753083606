import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'
import moment from 'moment'

export const session: Module<any, any> = {
  namespaced: true,

  state: () => ({
    data: {
      access_token: '',
      created_at: 0,
      expires_in: 0,
      id_token: '',
      refresh_token: '',
      scope: '',
      token_type: ''
    },
    userinfo: {
      id: 0,
      email: '',
      dni: '',
      phone_number: '',
      name: '',
      last_name: '',
      company_uid: 0,
      company_name: '',
      role: '',
      img: ''
    },
    deadline: '',
    auth: false
  }),

  getters: {
    userFullName(state) {
      return `${state.userinfo.name} ${state.userinfo.last_name}`
    },
    userRole(state) {
      let role
      if (state.data.role === 'coverme_super_admin') role = 'Super Administrador'
      else if (state.data.role === 'coverme_client_admin') role = 'Administrador Cliente'
      else role = 'Administrador'
      return role
    },
    userImage() {
      return '/img/avatars/admin_profile.svg'
    },
    userInitials(state) {
      return `${state.userinfo.name.charAt(0).toUpperCase()}${state.userinfo.last_name.charAt(0).toUpperCase()}`
    },
    userCompanyName(state) {
      return state.company ? `${state.company[0].name}` : 'Compañía no Cliente de Coverme'
    },
    appFullName(state) {
      return `${state.environment.name.charAt(0).toUpperCase()}${state.environment.name.slice(1)} v${
        state.environment.version
      }`
    }
  },

  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        data: {
          access_token: '',
          created_at: 0,
          expires_in: 0,
          id_token: '',
          refresh_token: '',
          scope: '',
          token_type: ''
        },
        userinfo: {
          id: 0,
          email: '',
          dni: '',
          phone_number: '',
          name: '',
          last_name: '',
          company_uid: 0,
          company_name: '',
          role: '',
          img: ''
        },
        deadline: '',
        auth: false
      })
    }
  },
  actions: {
    toggleModel({ state, commit }) {
      commit('mutate', { property: 'model', with: !state.model })
    },
    async fetchData({ commit, dispatch }) {
      try {
        const response = await Axios({
          method: 'post',
          url:
            process.env.VUE_APP_OAUTH_TOKEN +
            '/?client_id=' +
            process.env.VUE_APP_CLIENT_ID +
            '&redirect_uri=' +
            process.env.VUE_APP_BASE_URL +
            'auth&grant_type=authorization_code&code=' +
            this.state.session.code,
          data: [],
          headers: { 'Content-Type': 'application/json' }
        })
        commit('mutate', {
          property: 'data',
          with: response['data']
        })
        commit('mutate', {
          property: 'deadline',
          with: moment()
            .add(response['data'].expires_in, 'seconds')
            .format('YYYY-MM-DD HH:mm:ss')
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async fetchUserData({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_USER_INFO, {
          withCredentials: false,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(response => {
          commit('mutate', {
            property: 'userinfo',
            with: response['data']
          })

          Axios.get(process.env.VUE_APP_BACKEND + 'company/' + response['data'].company_uid, {
            withCredentials: false,
            responseType: 'json',
            headers: {
              Authorization: `Bearer ${this.state.session.data.access_token}`
            }
          }).then(response => {
            commit('mutate', {
              property: 'company',
              with: response.data.original.data
            })
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
