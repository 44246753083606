var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('v-form',{ref:"groupForm",attrs:{"valid":"valid"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-layout',{staticClass:"px-6 py-4",attrs:{"row":"","mx-0":"","mb-5":"","mt-2":"","justify-center":"","align-center":""}},[_c('v-flex',[_c('v-container',[_c('v-layout',{staticClass:"mt-2"},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-subheader',[_vm._v("Información")])],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Nombre","value":_vm.$store.state.openGroup.root.data.name,"required":"","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openGroup/mutate', {
                    property: 'root.data.name',
                    with: $event
                  })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Precio","value":_vm.$store.state.openGroup.root.data.price,"required":"","rules":[_vm.$rules.required, _vm.$rules.minValue(0), _vm.$rules.maxValue(1000000000)]},on:{"input":function($event){return _vm.$store.commit('openGroup/mutate', {
                    property: 'root.data.price',
                    with: $event
                  })}}})],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","mr-2":"","mb-2":"","align-center":"","justify-end":"","mt-5":""}},[_c('v-flex',{staticClass:"mr-5",attrs:{"shrink":""}},[_c('v-btn',{attrs:{"outlined":"","color":"primary","depressed":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancelar")])],1),_c('v-flex',{attrs:{"shrink":""}},[(_vm.newly)?_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","type":"submit","loading":_vm.loading}},[_vm._v("Crear")]):_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","type":"submit","loading":_vm.loading}},[_vm._v("Actualizar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }