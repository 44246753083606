
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { VForm } from '@/types'

import Store from '@/store'
import ClientSelect from '@/components/UserComponents/ClientSelect.vue'
import RoleSelect from '@/components/UserComponents/RoleSelect.vue'
import SetPassword from '@/components/UserComponents/SetPassword.vue'

@Component({
  components: {
    ClientSelect,
    RoleSelect,
    SetPassword
  }
})
export default class UserForm extends Vue {
  public error = false

  public loading = false

  @Prop(Boolean)
  public newly!: boolean

  @Prop()
  public readonly value!: any

  @Ref('userForm')
  private readonly userForm!: VForm

  async beforeEnter(next: any) {
    if (this.newly) await Store.commit('openUser/resetState')
    else await Store.dispatch('openUser/fetchDetails')
    next
  }
  public resetForm() {
    this.$nextTick(() => {
      this.userForm.reset()
    })
  }
  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    this.loading = true
    this.userForm.resetValidation()
    setTimeout(async () => {
      if (this.userForm.validate()) {
        try {
          if (this.newly) await Store.dispatch('openUser/createUserFromDetails')
          else await Store.dispatch('openUser/EditUserFromDetails')

          Store.commit('openUser/resetState')
          Store.dispatch('users/fetchRoot', () => true)
          this.$emit('cancel')
        } catch (e) {
          this.error = true
        }
      }

      this.loading = false
    }, 1000)

    this.loading = false
  }
}
