var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',{staticClass:"px-6 py-4"},[_c('v-form',{ref:"promoForm",attrs:{"valid":"valid"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-layout',{attrs:{"row":"","mx-0":"","mb-5":"","mt-2":"","justify-center":"","align-center":""}},[_c('v-flex',[_c('v-container',[_c('v-layout',{staticClass:"mt-2"},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-subheader',[_vm._v("Información")])],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":_vm.times !== '1' ? 'Título del grupo de códigos' : 'Título del cupon',"value":_vm.$store.state.openPromotion.root.name,"required":"","rules":[_vm.$rules.required]},on:{"input":function($event){return _vm.$store.commit('openPromotion/mutate', {
                      property: 'root.name',
                      with: $event
                    })}}})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"counter":"","maxlength":"8","color":"primary","label":_vm.times !== '1' ? 'Prefijo para cada cupón generado' : 'Cupón',"value":_vm.$store.state.openPromotion.root.code,"required":"","rules":[_vm.$rules.required, _vm.$rules.minLength(4), _vm.$rules.maxLength(8)]},on:{"keyup":_vm.uppercase,"input":function($event){return _vm.$store.commit('openPromotion/mutate', {
                      property: 'root.code',
                      with: $event
                    })}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),(_vm.newly)?_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}},[_c('v-subheader',[_vm._v(" Cantidad de códigos ")])],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Cantidad","value":_vm.$store.state.openPromotion.root.times,"type":"number","required":"","rules":[_vm.$rules.required, _vm.$rules.minValue(0)]},on:{"input":function($event){return _vm.$store.commit('openPromotion/mutate', {
                      property: 'root.times',
                      with: $event
                    })}}})],1)],1):_vm._e(),_c('v-layout',[_c('v-flex',{attrs:{"xs4":""}},[_c('v-subheader',[_vm._v(" Detalles ")])],1),_c('v-flex',{attrs:{"xs7":""}},[_c('v-select',{attrs:{"label":"Tipo de descuento","hide-details":"","items":_vm.type,"required":"","rules":[_vm.$rules.required]},on:{"change":function($event){return _vm.handleType($event)}}})],1)],1),_c('v-layout',{staticClass:"mt-4"},[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[(_vm.iniType === '1')?_c('v-text-field',{attrs:{"color":"primary","label":"Porcentaje del descuento","value":_vm.$store.state.openPromotion.root.value,"required":"","rules":[_vm.$rules.required, _vm.$rules.minTimeValue(0), _vm.$rules.maxValue(100)]},on:{"input":function($event){return _vm.$store.commit('openPromotion/mutate', {
                      property: 'root.value',
                      with: $event
                    })}}}):_c('div',[(_vm.iniType === '2')?_c('v-text-field',{attrs:{"color":"primary","label":"Valor del descuento","value":_vm.$store.state.openPromotion.root.value,"required":"","rules":_vm.$store.state.openProduct.root.data.group == 0 && _vm.$store.state.openProduct.root.data.free == 0
                        ? [
                            _vm.$rules.required,
                            _vm.$rules.minTimeValue(0),
                            _vm.$rules.maxValue(parseInt(_vm.$store.state.openProduct.root.data.price))
                          ]
                        : [_vm.$rules.required, _vm.$rules.minTimeValue(0), _vm.$rules.maxValue(1000000)]},on:{"input":function($event){return _vm.$store.commit('openPromotion/mutate', {
                        property: 'root.value',
                        with: $event
                      })}}}):_vm._e()],1)],1)],1),_c('v-layout',{staticClass:"mt-1"},[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[_c('v-select',{attrs:{"label":"Tipo de expiración","hide-details":"","items":_vm.expires,"required":"","rules":[_vm.$rules.required]},on:{"change":function($event){return _vm.handleExpires($event)}}})],1)],1),(_vm.iniExpires === '1')?_c('v-layout',{staticClass:"mt-4"},[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$store.state.openPromotion.root.formatDate,"label":"Fecha límite","append-icon":"mdi-calendar","readonly":"","required":"","rules":[_vm.$rules.required]}},'v-text-field',attrs,false),on))]}}],null,false,2552281562),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","show-current":false},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}})],1)],1)],1):_vm._e(),(_vm.iniExpires === '2')?_c('v-layout',{staticClass:"mt-4"},[_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs7":""}},[_c('v-text-field',{attrs:{"color":"primary","label":"Número de usos","value":_vm.$store.state.openPromotion.root.stock,"required":"","rules":[_vm.$rules.required, _vm.$rules.minValue(0)]},on:{"input":function($event){_vm.$store.commit('openPromotion/mutate', {
                      property: 'root.stock',
                      with: $event
                    }),
                      _vm.$store.commit('openPromotion/mutate', {
                        property: 'root.formatDate',
                        with: ''
                      }),
                      _vm.$store.commit('openPromotion/mutate', {
                        property: 'root.date',
                        with: ''
                      })}}})],1)],1):_vm._e(),_c('v-layout',{attrs:{"row":"","mr-2":"","mb-2":"","align-center":"","justify-end":"","mt-5":""}},[_c('v-flex',{staticClass:"mr-5",attrs:{"shrink":""}},[_c('v-btn',{attrs:{"outlined":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.handleCancel()}}},[_vm._v("Cancelar")])],1),_c('v-flex',{attrs:{"shrink":""}},[(_vm.newly)?_c('v-btn',{staticClass:"secondary",attrs:{"depressed":"","type":"submit","loading":_vm.loading}},[_vm._v("Crear")]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }