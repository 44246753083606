
import { Component, Vue, Prop } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class IndicatorCards extends Vue {
  @Prop({ type: String, default: 'fulfilled' })
  public readonly status!: any

  @Prop({ type: [Number, String], required: false })
  public readonly quantity!: number

  @Prop({ type: String, required: false })
  public readonly price: any

  @Prop({ type: Boolean, required: false })
  public readonly is_decimal!: boolean

  @Prop({ type: String, default: '' })
  public readonly currency!: string

  @Prop({ type: String })
  public readonly text!: string

  @Prop({ type: Number, default: 156 })
  public readonly height!: number

  @Prop({ type: String, required: false })
  public readonly width!: number

  @Prop({ type: String, default: 'blue-grey lighten-6' })
  public readonly colorBase!: string

  @Prop({ type: String, default: 'blue-grey--text text--darken-1' })
  public readonly colorText!: string

  @Prop({ type: Boolean, default: false })
  public readonly tooltip!: boolean

  @Prop({ type: String })
  public readonly tooltipName!: string

  @Prop({ type: String })
  public readonly image!: string

  @Prop({ type: String })
  public readonly imageCircle!: string

  @Prop({ type: String, default: 'Error' })
  public readonly errorText!: string

  get localeQuantity() {
    if (this.quantity && typeof this.quantity === 'number') {
      return this.quantity.toLocaleString()
    } else {
      return this.quantity
    }
  }

  get clientConfig() {
    return {
      thousands_sep: Store.state.openClient.config.data.thousands_sep,
      dec_point: Store.state.openClient.config.data.dec_point
    }
  }
}
