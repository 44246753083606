import { Module } from 'vuex'
import Axios from 'axios'
import { genericMutate, genericReset } from '../store.mutations'

const defaultState = () => ({
  root: {
    data: [],
    status: 'fetching'
  },
  products: {
    data: [],
    status: 'fetching'
  },
  openProduct: {
    id: ''
  },
  identifiers: {
    data: [],
    status: 'fetching'
  },
  groups: { state: 'fetching', data: [] },
  groupDiagnoses: { state: 'fetching', data: [] }
})

export const openDevice: Module<any, any> = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    mutate: genericMutate,
    resetState: genericReset(defaultState)
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'devices/details/' + this.state.openDevice.root.data.trust_id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root.data',
            with: response.data.original.data[0]
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
        commit('mutate', {
          property: 'status',
          with: 'fulfilled'
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async fetchProducts({ commit, dispatch }) {
      try {
        const response = await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'devices/products',
          data: {
            id: this.state.openDevice.root.data.id,
            company_id: this.state.session.company[0].id,
            role: this.state.session.data.role
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        commit('mutate', {
          property: 'products.data',
          with: response.data.original.data
        })

        commit('mutate', {
          property: 'products.status',
          with: 'fulfilled'
        })
      } catch (e) {
        commit('mutate', {
          property: 'products.status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async disableProduct({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/device/disable',
          data: {
            id: this.state.openDevice.openProduct.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        dispatch('system/showSuccess', 'Producto anulado', {
          root: true
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async sinisterProduct({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/device/sinister',
          data: {
            id: this.state.openDevice.openProduct.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        dispatch('system/showSuccess', 'Producto siniestrado', {
          root: true
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async fetchGroups(context, trustId) {
      try {
        context.state.groups.state = 'fetching'
        const response = await Axios({
          method: 'GET',
          baseURL: process.env.VUE_APP_BACKEND,
          url: `/device/${trustId}/groups`,
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        context.state.groups.data = response.data.data
        context.state.groups.state = 'fulfilled'
      } catch (error) {
        context.dispatch('system/showError', { error }, { root: true })
        context.state.groups.state = 'error'
      }
    },
    async fetchGroupDiagnoses(context, groupId) {
      try {
        context.commit('mutate', {
          property: 'groupDiagnoses.data',
          with: []
        })
        context.commit('mutate', {
          property: 'groupDiagnoses.state',
          with: 'fetching'
        })
        const response = await Axios({
          method: 'GET',
          baseURL: process.env.VUE_APP_BACKEND,
          url: `/group/${groupId}/diagnostics`,
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        })
        context.commit('mutate', {
          property: 'groupDiagnoses.data',
          with: response.data.data
        })
        context.commit('mutate', {
          property: 'groupDiagnoses.state',
          with: 'fulfilled'
        })
      } catch (error) {
        context.commit('mutate', {
          property: 'groupDiagnoses.state',
          with: 'error'
        })
        context.dispatch('system/showError', { error }, { root: true })
      }
    },
    async searchDeviceIds({ commit }, payload) {
      try {
        await Axios({
          method: 'POST',
          baseURL: process.env.VUE_APP_BACKEND,
          url: `/devices/search`,
          data: {
            indetifier: payload.identifier,
            type: payload.type
          }
        }).then((response) => {
          commit('mutate', {
            property: 'identifiers.data',
            with: response.data
          })
          commit('mutate', {
            property: 'identifiers.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
