import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const users: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        total: 0
      },
      data: [],
      filters: {
        search: ''
      },
      status: 'idle'
    },
    companyUid: '0'
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          pagination: {
            currentPage: 1,
            itemsPerPage: 10,
            total: 0
          },
          data: [],
          filters: {
            search: ''
          },
          status: 'idle'
        },
        companyUid: '0'
      })
    }
  },
  actions: {
    async fetchRoot({ commit }) {
      try {
        commit('mutate', {
          property: 'root.status',
          with: 'fetching'
        })
        await Axios.get(
          process.env.VUE_APP_USERS +
            '?page=' +
            this.state.users.root.pagination.currentPage +
            '&per_page=' +
            this.state.users.root.pagination.itemsPerPage +
            '&company_uid=' +
            this.state.users.companyUid +
            '&search=' +
            this.state.users.root.filters.search +
            '&fields=email,name,last_name,dni,role&status=true',

          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.state.session.data.access_token}`
            }
          }
        ).then((response) => {
          commit('mutate', {
            property: 'root.data',
            with: response.data.user
          })
          commit('mutate', {
            property: 'root.pagination.total',
            with: response.data.total
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        if (e.response && e.response.status === 404) {
          console.clear()
        }
        commit('mutate', {
          property: 'root.data',
          with: []
        })
        commit('mutate', {
          property: 'root.status',
          with: 'empty'
        })
      }
    }
  }
}
