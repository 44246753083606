
import { Component, Vue } from 'vue-property-decorator'

import PhoneTable from '@/components/DiagnosticComponents/PhoneTable.vue'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import SnackbarWarning from '@/components/HelpComponents/SnackbarWarning.vue'
import OverlayLoading from '@/components/AppBaseComponents/OverlayLoading.vue'
@Component({
  components: {
    SearchBox,
    PhoneTable,
    SnackbarWarning,
    OverlayLoading
  }
})
export default class indexDiagnostics extends Vue {
  public search = ''
}
