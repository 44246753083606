import { Module } from 'vuex'
import * as VueDeepSet from 'vue-deepset'
import Axios from 'axios'

export const openModel: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      id: '',
      brandId: '',
      name: ''
    },
    associate: {
      name: '',
      phoneId: ''
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          id: '',
          brandId: '',
          name: ''
        },
        associate: {
          name: '',
          phoneId: ''
        }
      })
    }
  },
  actions: {
    async associatePhone({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'codes/create',
          data: {
            name: this.state.openModel.associate.name,
            phoneId: this.state.openModel.associate.phoneId,
            id: this.state.openDevice.root.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Modelo de dispositivo asociado con éxito', { root: true })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
