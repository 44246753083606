import { Module } from 'vuex'
import Axios from 'axios'
import * as VueDeepSet from 'vue-deepset'

export const openProduct: Module<any, any> = {
  namespaced: true,
  state: () => ({
    root: {
      data: {
        id: '',
        name: '',
        price: '',
        type: 2,
        title_drop: '',
        description: '',
        models: {},
        group: 0,
        free: 0,
        tests: [],
        currency: '',
        is_decimal: false,
        show_month: true,
        month_number: ''
      },
      status: 'fetching'
    },
    devices: {
      data: [],
      status: 'fetching'
    },
    discounts: {
      data: [],
      status: 'fetching'
    },
    openDevice: {
      id: ''
    },
    status: 'fetching',
    created: false,
    step: 1,
    reloadSub: {
      phones: true,
      devices: true,
      groups: true,
      discounts: true
    }
  }),
  mutations: {
    mutate(state, payload: { property: string; with: unknown }) {
      VueDeepSet.VUEX_DEEP_SET(state, {
        path: payload.property,
        value: payload.with
      })
    },
    resetState(state) {
      Object.assign(state, {
        root: {
          data: {
            id: '',
            name: '',
            price: '',
            type: 2,
            title_drop: '',
            description: '',
            models: {},
            group: 0,
            free: 0,
            currency: '',
            is_decimal: false,
            tests: [],
            show_month: true,
            month_number: 1
          },
          status: 'fetching'
        },
        devices: {
          data: [],
          status: 'fetching'
        },
        discounts: {
          data: [],
          status: 'fetching'
        },
        openDevice: {
          id: ''
        },
        status: 'fetching',
        created: false,
        step: 1,
        reloadSub: {
          phones: true,
          devices: true,
          groups: true,
          discounts: true
        }
      })
    }
  },
  actions: {
    async fetchRoot({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'products/' + this.state.openProduct.root.data.id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root.data',
            with: response.data.original.data[0]
          })
          commit('mutate', {
            property: 'root.status',
            with: 'fulfilled'
          })
        })

        await Axios.get(process.env.VUE_APP_BACKEND + 'products/tests/' + this.state.openProduct.root.data.id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'root.data.tests',
            with: response.data.original.data
          })
        })
      } catch (e) {
        commit('mutate', {
          property: 'status',
          with: 'error'
        })
        dispatch('system/showError', { error: e }, { root: true })
      }
      /*
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'phones/assistance/' + this.state.openProduct.root.data.id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(response => {
          commit('mutate', {
            property: 'root.data.models',
            with: response.data.data
          })
        })
        
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
      */
    },

    async fetchDiscounts({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'discounts/' + this.state.openProduct.root.data.id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'discounts.data',
            with: response.data.original.data
          })
          commit('mutate', {
            property: 'discounts.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
        commit('mutate', {
          property: 'discounts.status',
          with: 'error'
        })
      }
    },
    async fetchDevices({ commit, dispatch }) {
      try {
        await Axios.get(process.env.VUE_APP_BACKEND + 'products/details/' + this.state.openProduct.root.data.id, {
          withCredentials: false,
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then((response) => {
          commit('mutate', {
            property: 'devices.data',
            with: response.data.original.data
          })
          commit('mutate', {
            property: 'devices.status',
            with: 'fulfilled'
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
        commit('mutate', {
          property: 'devices.status',
          with: 'error'
        })
      }
    },
    /*
    async fetchPurchases({ commit, dispatch }) {
      try {
        await Axios.get(
          process.env.VUE_APP_BACKEND +
            "phones/purchases/" +
            this.state.openProduct.root.data.id,
          {
            withCredentials: false,
            responseType: "json",
            headers: {
              Authorization: `Bearer ${this.state.session.data.access_token}`,
            },
          }
        ).then((response) => {
          commit("mutate", {
            property: "purchases",
            with: response.data.original,
          });
        });

      } catch (e) {
        dispatch("system/showError", { error: e }, { root: true });
      }
    },
    */
    async createProduct({ dispatch, commit }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/create',
          data: {
            company_id: this.state.openProduct.root.data.company_id,
            name: this.state.openProduct.root.data.name,
            price: this.state.openProduct.root.data.price,
            title_drop: this.state.openProduct.root.data.title_drop,
            description: this.state.openProduct.root.data.description,
            type: this.state.openProduct.root.data.type,
            models: this.state.openProduct.root.data.models,
            free: this.state.openProduct.root.data.free,
            group: this.state.openProduct.root.data.group,
            currency: this.state.openProduct.root.data.currency,
            is_decimal: this.state.openProduct.root.data.is_decimal,
            show_month: this.state.openProduct.root.data.show_month,
            month_number: this.state.openProduct.root.data.month_number
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          commit('mutate', {
            property: 'created',
            with: true
          })
          dispatch('system/showSuccess', 'Producto creado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async updateProduct({ dispatch, state }) {
      try {
        if (!this.state.openProduct.root.data.tests) {
          this.state.openProduct.root.data.tests = []
        }
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/update',
          data: state.root.data,
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Producto actualizado correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async deleteProduct({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/delete',
          data: {
            id: this.state.openProduct.root.data.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Producto eliminado correctamente', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },
    async disableDevice({ dispatch }) {
      try {
        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/device/disable',
          data: {
            id: this.state.openProduct.openDevice.id
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          dispatch('system/showSuccess', 'Dispositivo deshabilitado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    },

    async completeSave({ dispatch, commit }) {
      try {
        if (!this.state.openProduct.root.data.tests) this.state.openProduct.root.data.tests = []

        await Axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND + 'products/create_full',
          data: {
            company_id: this.state.openProduct.root.data.company_id,
            name: this.state.openProduct.root.data.name,
            price: this.state.openProduct.root.data.price,
            title_drop: this.state.openProduct.root.data.title_drop,
            description: this.state.openProduct.root.data.description,
            type: this.state.openProduct.root.data.type,
            free: this.state.openProduct.root.data.free,
            group: this.state.openProduct.root.data.group,
            currency: this.state.openProduct.root.data.currency,
            is_decimal: this.state.openProduct.root.data.is_decimal,
            phones: this.state.phones.selected.data,
            tests_assistance: this.state.openProduct.root.data.tests,
            tests_phones: [],
            show_month: this.state.openProduct.root.data.show_month,
            month_number: this.state.openProduct.root.data.month_number
          },
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${this.state.session.data.access_token}`
          }
        }).then(() => {
          commit('mutate', {
            property: 'created',
            with: true
          })
          dispatch('system/showSuccess', 'Producto creado', {
            root: true
          })
        })
      } catch (e) {
        dispatch('system/showError', { error: e }, { root: true })
      }
    }
  }
}
