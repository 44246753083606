
import { Vue, Component, Ref } from 'vue-property-decorator'
import { VForm } from '@/types'
import Store from '@/store'

@Component({
  components: {}
})
export default class ManageProfileListItem extends Vue {
  public fileLoading = false

  public dialog = false

  public valid = true

  public loading = false

  @Ref('form')
  public formRef!: VForm

  public get appName(): string {
    const { appName } = Store.state.session.environment
    return appName.charAt(0).toUpperCase() + appName.slice(1)
  }
}
