
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ClientColorPicker extends Vue {
  public menu = false

  public colors: string[] = [
    '#343434',
    '#2A5E78',
    '#8B5010',
    '#EA5080',
    '#863CFF',
    '#2150D2',
    '#38AEB1',
    '#32AD00',
    '#B9E52F',
    '#F9B503',
    '#F78704',
    '#DD3B00'
  ]

  @Prop(String)
  public readonly value!: string

  @Prop(String)
  public readonly storeVar!: string
  @Prop(String)
  public readonly colorStore!: string
}
