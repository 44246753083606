
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Axios from 'axios'

@Component({})
export default class DiagnosesGroup extends Vue {
  @Prop()
  public readonly group!: any
  public score = '0'

  @Watch('group', { immediate: true })
  public handleOnGroupChange(val: any) {
    if (val) {
      this.$store.dispatch('openDevice/fetchGroupDiagnoses', val.group_uuid)
    }
  }

  async showDetails(item: any) {
    if (item.score_screen_test != null) {
      this.score = parseFloat(item.score_screen_test).toFixed(2)
    } else {
      this.score = '0'
    }
    return this.score
  }
  async updateApprovedStatus(status: boolean, test: any) {
    try {
      await Axios({
        method: 'PUT',
        baseURL: process.env.VUE_APP_BACKEND,
        url: `/group/test`,
        data: {
          group_uuid: test.group_uuid,
          test_uuid: test.test_uuid,
          approved: status
        },
        headers: {
          Authorization: `Bearer ${this.$store.state.session.data.access_token}`
        }
      })
      this.$store.dispatch('system/showSuccess', 'Test actualizado con exito')
      test.dialog = false
    } catch (error) {
      this.$store.dispatch('system/showError', { error })
    }
  }

  public openUrl(url: string) {
    window.open(url)
  }

  public testType(testType: number) {
    switch (testType) {
      case 1:
        return 'Táctil de pantalla'
      case 2:
        return 'Botones'
      case 3:
        return 'Cámara trasera'
      case 4:
        return 'Pantalla'
      case 5:
        return 'WiFi'
      case 6:
        return 'Bluetooth'
      case 7:
        return 'Flash'
      case 8:
        return 'Auricular'
      case 9:
        return 'GPS'
      case 10:
        return 'IMEI'
      default:
        break
    }
  }
}
