const PhoneModels = [
  {
    name: 'Iphone X (gsm)',
    brand: 'Apple'
  },
  {
    name: 'Mi 10',
    brand: 'Xiaomi'
  },
  {
    name: 'Mi 9',
    brand: 'Xiaomi'
  },
  {
    name: 'Iphone 8 Plus (gsm)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 6s',
    brand: 'Apple'
  },
  {
    name: 'Mi 10t',
    brand: 'Xiaomi'
  },
  {
    name: 'Mi 10 Lite 5g',
    brand: 'Xiaomi'
  },
  {
    name: 'Mi 9t Pro',
    brand: 'Xiaomi'
  },
  {
    name: 'Ipad Air 4 (cellular)',
    brand: 'Apple'
  },
  {
    name: 'Mi Note',
    brand: 'Xiaomi'
  },
  {
    name: 'Mi 8',
    brand: 'Xiaomi'
  },
  {
    name: 'Find X2',
    brand: 'Oppo'
  },
  {
    name: 'A9 2020',
    brand: 'Samsung'
  },
  {
    name: 'Galaxy S21 Ultra 5G',
    brand: 'Samsung'
  },
  {
    name: 'Ipad Mini 2 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'One M9plus',
    brand: 'HTC'
  },
  {
    name: 'Smart Tab 7',
    brand: 'Alcatel'
  },
  {
    name: 'Zte Axon 10 Pro',
    brand: 'ZTE'
  },
  {
    name: 'Zte A2121e',
    brand: 'ZTE'
  },
  {
    name: 'Zte A2019g Pro',
    brand: 'ZTE'
  },
  {
    name: 'Zte Blade V 2020',
    brand: 'ZTE'
  },
  {
    name: 'Zte Blade V2020 Vita',
    brand: 'ZTE'
  },
  {
    name: 'Zte Blade V1000ru',
    brand: 'ZTE'
  },
  {
    name: 'Zte Fanfare 3',
    brand: 'ZTE'
  },
  {
    name: 'Zte V0840',
    brand: 'ZTE'
  },
  {
    name: 'Zxy-zte V6700',
    brand: 'ZTE'
  },
  {
    name: 'Galaxy Tab Active Pro',
    brand: 'Samsung'
  },
  {
    name: 'Hisense H40',
    brand: 'Hisense'
  },
  {
    name: 'Mexico',
    brand: 'Custom'
  },
  {
    name: 'Hisense H30',
    brand: 'Hisense'
  },
  {
    name: 'Hisense E40',
    brand: 'Hisense'
  },
  {
    name: 'Iphone Xs',
    brand: 'Apple'
  },
  {
    name: 'Iphone 11 Pro Max',
    brand: 'Apple'
  },
  {
    name: 'Hisense V40',
    brand: 'Hisense'
  },
  {
    name: 'Hisense E30',
    brand: 'Hisense'
  },
  {
    name: 'Hisense V50',
    brand: 'Hisense'
  },
  {
    name: 'Hisense E20',
    brand: 'Hisense'
  },
  {
    name: 'Hisense U3 2021',
    brand: 'Hisense'
  },
  {
    name: 'Hisense U605',
    brand: 'Hisense'
  },
  {
    name: 'Iphone Xs Max (china)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 11 Pro',
    brand: 'Apple'
  },
  {
    name: 'Iphone 11',
    brand: 'Apple'
  },
  {
    name: 'Iphone 8 Plus (global)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 7 Plus (global)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 7 (global)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 6',
    brand: 'Apple'
  },
  {
    name: 'Ipad Pro 12.9-inch (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Air (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad 7 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Mini (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Air 2 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Pro 9.7-inch (cellular)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Mini 3 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Air (cellular)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Mini 4 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad 6 (Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 7 (gsm)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Pro 3 (12.9-inch, Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Pro (10.5-inch, Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Moto G6 Play',
    brand: 'Motorola'
  },
  {
    name: 'Iphone 8 (global)',
    brand: 'Apple'
  },
  {
    name: 'Iphone Xr',
    brand: 'Apple'
  },
  {
    name: 'Galaxy Tab S6',
    brand: 'Samsung'
  },
  {
    name: 'Redmi Note 9s',
    brand: 'Xiaomi'
  },
  {
    name: 'Mi Note 10 Lite',
    brand: 'Xiaomi'
  },
  {
    name: 'Pixel 5',
    brand: 'Google'
  },
  {
    name: 'Mate 10',
    brand: 'Huawei'
  },
  {
    name: 'Iphone 7 Plus (gsm)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 6s+',
    brand: 'Apple'
  },
  {
    name: 'Iphone Se (2020)',
    brand: 'Apple'
  },
  {
    name: 'Iphone Xs Max',
    brand: 'Apple'
  },
  {
    name: 'Ipad Pro 3 (11-inch, Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Wildfire E',
    brand: 'HTC'
  },
  {
    name: 'Desire 12',
    brand: 'HTC'
  },
  {
    name: 'U11 Life',
    brand: 'HTC'
  },
  {
    name: 'Hisense H40 Lite',
    brand: 'Hisense'
  },
  {
    name: 'Hisense F19',
    brand: 'Hisense'
  },
  {
    name: 'Pixel 3 Xl',
    brand: 'Google'
  },
  {
    name: '荣耀畅玩7',
    brand: 'Honor'
  },
  {
    name: 'Iphone 12 Mini',
    brand: 'Apple'
  },
  {
    name: 'Ipad Air 2 (cellular)',
    brand: 'Apple'
  },
  {
    name: 'Moto G(4) Plus',
    brand: 'Motorola'
  },
  {
    name: 'Galaxy A02s',
    brand: 'Samsung'
  },
  {
    name: 'C21',
    brand: 'Realme'
  },
  {
    name: 'Ipad Pro 4 (12.9-inch, Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad 5 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Lg Reflect',
    brand: 'LG'
  },
  {
    name: '荣耀畅玩8c',
    brand: 'Honor'
  },
  {
    name: 'Ipad Air 3 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 8 (gsm)',
    brand: 'Apple'
  },
  {
    name: 'Galaxy Note8',
    brand: 'Samsung'
  },
  {
    name: 'Iphone 5s (global)',
    brand: 'Apple'
  },
  {
    name: 'Iphone 6+',
    brand: 'Apple'
  },
  {
    name: 'A72',
    brand: 'Samsung'
  },
  {
    name: 'Moto E6s',
    brand: 'Motorola'
  },
  {
    name: 'Nokia G20',
    brand: 'Nokia'
  },
  {
    name: 'Zte Blade A3 2020',
    brand: 'ZTE'
  },
  {
    name: 'Oneplus 8',
    brand: 'Oneplus'
  },
  {
    name: 'Stellar P3',
    brand: 'Zuum'
  },
  {
    name: 'Xperia Z5 Compact',
    brand: 'Sony'
  },
  {
    name: 'Galaxy S20 Fe 5g',
    brand: 'Samsung'
  },
  {
    name: 'Moto G(8) Play',
    brand: 'Motorola'
  },
  {
    name: 'Huawei Mate 20 X',
    brand: 'Huawei'
  },
  {
    name: 'Huawei P Smart Z',
    brand: 'Huawei'
  },
  {
    name: 'Motorola One',
    brand: 'Motorola'
  },
  {
    name: 'Oneplus Nord N200 5g',
    brand: 'Oneplus'
  },
  {
    name: 'U5 3g',
    brand: 'Alcatel'
  },
  {
    name: 'Galaxy A21s',
    brand: 'Samsung'
  },
  {
    name: 'Redmi 9a',
    brand: 'Xiaomi'
  },
  {
    name: 'Nativo',
    brand: 'Custom'
  },
  {
    name: 'Galaxy Note20 5g',
    brand: 'Samsung'
  },
  {
    name: 'Honor 10 Lite',
    brand: 'Honor'
  },
  {
    name: 'Honor 10 Lite',
    brand: 'Honor'
  },
  {
    name: 'Galaxy A21',
    brand: 'Samsung'
  },
  {
    name: 'Realme 7 Pro',
    brand: 'Realme'
  },
  {
    name: 'Nokia 7.2',
    brand: 'Nokia'
  },
  {
    name: 'Galaxy A02s',
    brand: 'Samsung'
  },
  {
    name: 'Galaxy Note10',
    brand: 'Samsung'
  },
  {
    name: 'Pixel 4a',
    brand: 'Google'
  },
  {
    name: 'Moto G(7) Plus',
    brand: 'Motorola'
  },
  {
    name: 'Galaxy A30',
    brand: 'Samsung'
  },
  {
    name: 'Honor 8a',
    brand: 'Honor'
  },
  {
    name: 'Redmi Note 10s',
    brand: 'Xiaomi'
  },
  {
    name: 'Poco X3 Pro',
    brand: 'Xiaomi'
  },
  {
    name: 'Moto G(7) Power',
    brand: 'Motorola'
  },
  {
    name: '华为畅享 8e 青春',
    brand: 'Huawei'
  },
  {
    name: 'Iphone 13 Pro Max',
    brand: 'Apple'
  },
  {
    name: 'Iphone 13 Pro Max',
    brand: 'Apple'
  },
  {
    name: 'Galaxy S7',
    brand: 'Samsung'
  },
  {
    name: 'Y5p',
    brand: 'Huawei'
  },
  {
    name: 'Oneplus5t',
    brand: 'Oneplus'
  },
  {
    name: 'Iphone X (global)',
    brand: 'Apple'
  },
  {
    name: 'Iphone X (global)',
    brand: 'Apple'
  },
  {
    name: 'Moto G Stylus 5g',
    brand: 'Motorola'
  },
  {
    name: 'Iphone 13',
    brand: 'Apple'
  },
  {
    name: 'Lenovo Tab M10 (hd)',
    brand: 'Lenovo'
  },
  {
    name: 'Galaxy Note20 Ultra 5g',
    brand: 'Samsung'
  },
  {
    name: 'Galaxy S8 Active',
    brand: 'Samsung'
  },
  {
    name: 'Xiaomi 11 Lite 5g Ne',
    brand: 'Xiaomi'
  },
  {
    name: 'One',
    brand: 'Motorola'
  },
  {
    name: 'Redmi 9c',
    brand: 'Xiaomi'
  },
  {
    name: 'Redmi 9t',
    brand: 'Xiaomi'
  },
  {
    name: 'Galaxy Z Fold3 5g',
    brand: 'Samsung'
  },
  {
    name: '荣耀畅玩8',
    brand: 'Honor'
  },
  {
    name: 'Oneplus 8t+ 5g',
    brand: 'Oneplus'
  },
  {
    name: 'Google Android Emulator',
    brand: 'Custom'
  },
  {
    name: 'Galaxy A32',
    brand: 'Samsung'
  },
  {
    name: 'Oneplus5',
    brand: 'Oneplus'
  },
  {
    name: 'Iphone 13 Pro',
    brand: 'Apple'
  },
  {
    name: 'Iphone 13 Mini',
    brand: 'Apple'
  },
  {
    name: 'Ipad 6 (cellular)',
    brand: 'Apple'
  },
  {
    name: 'Iphone Se',
    brand: 'Apple'
  },
  {
    name: 'Galaxy A72',
    brand: 'Samsung'
  },
  {
    name: 'Ipad Mini 5 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipod Touch 7',
    brand: 'Apple'
  },
  {
    name: 'Note 20',
    brand: 'Samsung'
  },
  {
    name: 'Ipad 9 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad 9 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Redmi Note 10',
    brand: 'Xiaomi'
  },
  {
    name: 'Realme C21-y',
    brand: 'Realme'
  },
  {
    name: 'Redmi Note 10 Pro',
    brand: 'Xiaomi'
  },
  {
    name: 'A9 Pro Eea',
    brand: 'Umidigi'
  },
  {
    name: 'Motorola One Zoom',
    brand: 'Motorola'
  },
  {
    name: 'Xiaomi 11t Pro',
    brand: 'Xiaomi'
  },
  {
    name: 'Xiaomi 11t Pro',
    brand: 'Xiaomi'
  },
  {
    name: '华为畅享9 Plus',
    brand: 'Huawei'
  },
  {
    name: 'Redmi Note 10 Pro Max',
    brand: 'Xiaomi'
  },
  {
    name: 'Ipad 8 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad 8 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'V2027',
    brand: 'Vivo'
  },
  {
    name: 'Realme 5',
    brand: 'Realme'
  },
  {
    name: 'Cph2185',
    brand: 'Oppo'
  },
  {
    name: 'Galaxy A52s 5g',
    brand: 'Samsung'
  },
  {
    name: 'Tcl 20e',
    brand: 'TCL'
  },
  {
    name: 'Iphone Se (3rd Generation)',
    brand: 'Apple'
  },
  {
    name: 'Iphone Se (3rd Generation)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Pro (12.9-inch) (5th Generation)',
    brand: 'Apple'
  },
  {
    name: 'Redmi Note 11 Pro 5g',
    brand: 'Xiaomi'
  },
  {
    name: 'Galaxy M52 5g',
    brand: 'Samsung'
  },
  {
    name: 'Honor V10',
    brand: 'Honor'
  },
  {
    name: 'Ipad Pro 4 (11-inch, Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Galaxy Z Flip3 5g',
    brand: 'Samsung'
  },
  {
    name: 'Motorola Edge 20',
    brand: 'Motorola'
  },
  {
    name: 'Honor Play',
    brand: 'Honor'
  },
  {
    name: 'Moto G(9) Power',
    brand: 'Motorola'
  },
  {
    name: 'Redmi 10 2022',
    brand: 'Xiaomi'
  },
  {
    name: 'Ipad Pro 2 (12.9-inch, Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Pro 2 (12.9-inch, Wifi)',
    brand: 'Apple'
  },
  {
    name: 'Ipad Air 4 (wifi)',
    brand: 'Apple'
  },
  {
    name: 'Alcatel 1',
    brand: 'Alcatel'
  },
  {
    name: 'Galaxy S6',
    brand: 'Samsung'
  },
  {
    name: 'Q10',
    brand: 'Blackberry'
  },
  {
    name: 'Redmi 5',
    brand: 'Xiaomi'
  },
  {
    name: 'redmi note 9',
    brand: 'Xiaomi'
  },
  {
    name: 'Zte Blade V1000',
    brand: 'ZTE'
  },
  {
    name: 'Cricket® Icon 3',
    brand: 'Cricket'
  },
  {
    name: 'A',
    brand: 'Custom'
  },
  {
    name: 'B',
    brand: 'Custom'
  },
  {
    name: "Wiko Life 3",
    brand: "Wiko"
  },
  {
    name: "W170 128mb ",
    brand: "Sony"
  },
  {
    name: "Pearl 8130 ",
    brand: "Blackberry"
  },
  {
    name: "L5 Advance 16gb ",
    brand: "Blu"
  },
  {
    name: "E20 16gb ",
    brand: "Hisense"
  },
  {
    name: "L675 8gb ",
    brand: "Hisense"
  },
  {
    name: "L675 Pro 8gb ",
    brand: "Hisense"
  },
  {
    name: "U1 8gb ",
    brand: "Hisense"
  },
  {
    name: "U3 2021 8gb ",
    brand: "Hisense"
  },
  {
    name: "U3 8gb ",
    brand: "Hisense"
  },
  {
    name: "U963 8gb ",
    brand: "Hisense"
  },
  {
    name: "U965 8gb ",
    brand: "Hisense"
  },
  {
    name: "Vsn V40r ",
    brand: "Nextel"
  },
  {
    name: "V1 Tablet 8gb",
    brand: "Custom"
  },
  {
    name: "J7 32gb ",
    brand: "Samsung"
  },
  {
    name: "F20 8gb ",
    brand: "Hisense"
  },
  {
    name: "F23 16gb ",
    brand: "Hisense"
  },
  {
    name: "F23 Plus 16gb ",
    brand: "Hisense"
  },
  {
    name: "V3 16gb ",
    brand: "Hisense"
  },
  {
    name: "V5 16gb ",
    brand: "Hisense"
  },
  {
    name: "Family Watch Mt30 ",
    brand: "Alcatel"
  },
  {
    name: "Redmi 9a 32gb ",
    brand: "Xiaomi"
  },
  {
    name: "G70 32gb ",
    brand: "Blu"
  },
  {
    name: "E40 128gb ",
    brand: "Hisense"
  },
  {
    name: "E50 64gb ",
    brand: "Hisense"
  },
  {
    name: "F8 Mini S 16gb ",
    brand: "Hisense"
  },
  {
    name: "H30 Lite 32gb ",
    brand: "Hisense"
  },
  {
    name: "H40 Lite 64gb ",
    brand: "Hisense"
  },
  {
    name: "V8 16gb ",
    brand: "Hisense"
  },
  {
    name: "Desire 626s ",
    brand: "HTC"
  },
  {
    name: "Redmi 9c 32gb ",
    brand: "Xiaomi"
  },
  {
    name: "F24 16gb ",
    brand: "Hisense"
  },
  {
    name: "10 Se 64gb ",
    brand: "TCL"
  },
  {
    name: "Redmi 9 64gb ",
    brand: "Xiaomi"
  },
  {
    name: "H30 128gb ",
    brand: "Hisense"
  },
  {
    name: "Redmi 10 128gb ",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 10s 128gb ",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 9 128gb ",
    brand: "Xiaomi"
  },
  {
    name: "H40 Bundle 128gb ",
    brand: "Hisense"
  },
  {
    name: "11 Lite 5g Ne 128gb ",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 10 Pro 128gb ",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 9s (128gb) ",
    brand: "Xiaomi"
  },
  {
    name: "Realme C3",
    brand: "Realme"
  },
  {
    name: "Galaxy S10 5g",
    brand: "Samsung"
  },
  {
    name: "Reno5 Lite",
    brand: "Oppo"
  },
  {
    name: "Pixel 3a Xl",
    brand: "Google"
  },
  {
    name: "Galaxy A03s",
    brand: "Samsung"
  },
  {
    name: "Galaxy A13",
    brand: "Samsung"
  },
  {
    name: "Tbd",
    brand: "Custom"
  },
  {
    name: "Moto G(20)",
    brand: "Motorola"
  },
  {
    name: "Tcl 6125f",
    brand: "TCL"
  },
  {
    name: "Iphone X",
    brand: "Apple"
  },
  {
    name: "Motorola Edge 20 Pro",
    brand: "Motorola"
  },
  {
    name: "Nova 4",
    brand: "Huawei"
  },
  {
    name: "Galaxy A23",
    brand: "Samsung"
  },
  {
    name: "Q10 ",
    brand: "LG"
  },
  {
    name: "Poco M4 Pro 5g",
    brand: "Xiaomi"
  },
  {
    name: "Pearl 8130",
    brand: "Blackberry"
  },
  {
    name: "G70 32gb",
    brand: "Blu"
  },
  {
    name: "J7 32gb",
    brand: "Samsung"
  },
  {
    name: "L5 Advance 16gb",
    brand: "Blu"
  },
  {
    name: "W170 128mb",
    brand: "Sony"
  },
  {
    name: "E20 16gb",
    brand: "Hisense"
  },
  {
    name: "E40 128gb",
    brand: "Hisense"
  },
  {
    name: "E50 64gb",
    brand: "Hisense"
  },
  {
    name: "F20 8gb",
    brand: "Hisense"
  },
  {
    name: "F23 16gb",
    brand: "Hisense"
  },
  {
    name: "F23 Plus 16gb",
    brand: "Hisense"
  },
  {
    name: "F24 16gb",
    brand: "Hisense"
  },
  {
    name: "F8 Mini S 16gb",
    brand: "Hisense"
  },
  {
    name: "H30 128gb",
    brand: "Hisense"
  },
  {
    name: "H30 Lite 32gb",
    brand: "Hisense"
  },
  {
    name: "H40 Bundle 128gb",
    brand: "Hisense"
  },
  {
    name: "H40 Lite 64gb",
    brand: "Hisense"
  },
  {
    name: "L675 8gb",
    brand: "Hisense"
  },
  {
    name: "L675 Pro 8gb",
    brand: "Hisense"
  },
  {
    name: "U1 8gb",
    brand: "Hisense"
  },
  {
    name: "U3 2021 8gb",
    brand: "Hisense"
  },
  {
    name: "U3 8gb",
    brand: "Hisense"
  },
  {
    name: "U963 8gb",
    brand: "Hisense"
  },
  {
    name: "U965 8gb",
    brand: "Hisense"
  },
  {
    name: "V3 16gb",
    brand: "Hisense"
  },
  {
    name: "V5 16gb",
    brand: "Hisense"
  },
  {
    name: "V8 16gb",
    brand: "Hisense"
  },
  {
    name: "10 Se 64gb",
    brand: "TCL"
  },
  {
    name: "Family Watch Mt30",
    brand: "Alcatel"
  },
  {
    name: "Vsn V40r",
    brand: "Nextel"
  },
  {
    name: "11 Lite 5g Ne 128gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi 10 128gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi 9 64gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi 9a 32gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi 9c 32gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 10 Pro 128gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 10s 128gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 9 128gb",
    brand: "Xiaomi"
  },
  {
    name: "Redmi Note 9s (128gb)",
    brand: "Xiaomi"
  },
  {
    name: "Lg G3",
    brand: "LG"
  },
  {
    name: "Lg Q6",
    brand: "LG"
  },
  {
    name: "Lg Q6",
    brand: "LG"
  },
  {
    name: "Lg Q6",
    brand: "LG"
  },
  {
    name: "Lg Q6",
    brand: "LG"
  },
  {
    name: "Lg Q6",
    brand: "LG"
  },
  {
    name: "Motorola Edge (2021)",
    brand: "Motorola"
  },
  {
    name: "Motorola Edge 20 Pro",
    brand: "Motorola"
  },
  {
    name: "Moto C",
    brand: "Motorola"
  },
  {
    name: "Moto C",
    brand: "Motorola"
  },
  {
    name: "Moto C ",
    brand: "Motorola"
  },
  {
    name: "Moto Z",
    brand: "Motorola"
  },
  {
    name: "Moto Z (2) Play",
    brand: "Motorola"
  },
  {
    name: "Moto Z (2) Play",
    brand: "Motorola"
  },
  {
    name: "Moto Z (2) Play",
    brand: "Motorola"
  },
  {
    name: "Moto Z (2) Play",
    brand: "Motorola"
  },
  {
    name: "Moto Z3 Play 64gb",
    brand: "Motorola"
  },
  {
    name: "Motorola Razr (2020)",
    brand: "Motorola"
  },
  {
    name: "Motorola Razr 5g",
    brand: "Motorola"
  },
  {
    name: "Moto X Force",
    brand: "Motorola"
  },
  {
    name: "Moto X Force",
    brand: "Motorola"
  },
  {
    name: "Galaxy A7 (2017)",
    brand: "Samsung"
  },
  {
    name: "Galaxy A7(2017)",
    brand: "Samsung"
  },
  {
    name: "Galaxy A71",
    brand: "Samsung"
  },
  {
    name: "Galaxy A80",
    brand: "Samsung"
  },
  {
    name: "Galaxy A80",
    brand: "Samsung"
  },
  {
    name: "Galaxy Fold",
    brand: "Samsung"
  },
  {
    name: "Galaxy Fold",
    brand: "Samsung"
  },
  {
    name: "Galaxy Fold",
    brand: "Samsung"
  },
  {
    name: "Galaxy Fold",
    brand: "Samsung"
  },
  {
    name: "Galaxy Fold",
    brand: "Samsung"
  },
  {
    name: "Galaxy Note10+",
    brand: "Samsung"
  },
  {
    name: "Galaxy S6 Edge",
    brand: "Samsung"
  },
  {
    name: "Galaxy S6 Edge+",
    brand: "Samsung"
  },
  {
    name: "Xperia Xzs",
    brand: "Sony"
  },
  {
    name: "Lumia 920",
    brand: "Nokia"
  },
  {
    name: "Galaxy A22",
    brand: "Samsung"
  },
  {
    name: "Galaxy S20+ 5g",
    brand: "Samsung"
  },
  {
    name: "Moto E(6i)",
    brand: "Motorola"
  },
  {
    name: "华为畅享 Max",
    brand: "Huawei"
  },
  {
    name: "Ipad Pro (11-inch) (3rd Generation)",
    brand: "Apple"
  },
  {
    name: "荣耀 8x",
    brand: "Honor"
  },
  {
    name: "Iphone 14 Pro",
    brand: "Apple"
  },
  {
    name: "Cph2239",
    brand: "Oppo"
  },
  {
    name: "Pouvoir 2",
    brand: "Tecno"
  },
  {
    name: "Pixel 6",
    brand: "Google"
  },
  {
    name: "Redmi Note 11",
    brand: "Xiaomi"
  }
]
export default PhoneModels
