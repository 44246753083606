
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { IOn, VForm } from '@/types'
import UserForm from '@/components/UserComponents/UserForm.vue'
import Store from '@/store'

@Component({
  components: {
    UserForm
  }
})
export default class UsersCardsItemOptionsButton extends Vue {
  public valid = true
  public dialog = false
  public loading = false

  @Prop({ required: true, type: Object })
  public readonly user!: any

  @Ref('form')
  private formRef!: VForm

  public async beforeOpenDialog(on: IOn, $event: Event) {
    await Store.commit('openUser/mutate', {
      property: 'root.id',
      with: this.user.id
    })
    await Store.dispatch('openUser/fetchDetails', () => true)
    on.click($event)
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    this.loading = true
    if (this.formRef.validate()) {
      await this.$store.dispatch('openUser/updateUserFromDetails')
      this.$store.dispatch('users/fetchRoot', () => true)
      this.dialog = false
    }
    this.loading = false
  }
}
