
import { Vue, Component } from 'vue-property-decorator'
import ManageClienteBasic from '@/components/AccountComponents/ManageClientBasic.vue'
import ManageProfileListItem from '@/components/AccountComponents/ManageProfileListItem.vue'
import ManageConfigsClient from '@/components/AccountComponents/ManageConfigsClient.vue'
import LogoutListItem from '@/components/AccountComponents/LogoutListItem.vue'

@Component({
  components: {
    ManageClienteBasic,
    ManageProfileListItem,
    ManageConfigsClient,
    LogoutListItem
  }
})
export default class AccountBtn extends Vue {}
