
import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator'

@Component({})
export default class IObserver extends Vue {
  private observer: IntersectionObserver | null = null

  @Ref('stub')
  public stub!: Element

  @Prop({ type: Number, default: 0.5 })
  private readonly threshold!: number

  @Emit('enter')
  /** eslint-disable @typescript-eslint/no-empty-function */
  private onIntersectEnter(): void {
    void 0
  }

  @Emit('leave')
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  private onIntersectLeave(): void {}

  public mounted(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        const isIntersecting = entries.some((entry) => entry.isIntersecting)
        if (isIntersecting) {
          this.onIntersectEnter()
        } else {
          this.onIntersectLeave()
        }
      },
      {
        threshold: this.threshold
      }
    )

    this.observer.observe(this.stub)
  }

  public beforeDestroy() {
    if (this.observer) {
      this.observer.unobserve(this.stub)
    }
  }
}
