
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class Pagination extends Vue {
  public pageItems: number[] = [5, 10, 15]
  public resultado = 'Resultados'
  public numberPagesWidth = 240
  public newMarginFlexPerPage = 0
  public marginDifference = 0

  @Prop(Boolean)
  public readonly hideDivider!: boolean

  @Prop(Boolean)
  public readonly hideItemsPerPage!: boolean

  @Prop(Boolean)
  public readonly hidePagePagination!: boolean

  @Prop(Number)
  public readonly pagesLength!: number
  @Watch('pagesLength')
  onChangePagesLength(val: any) {
    if (val < 12) {
      this.numberPagesWidth = 240
      this.marginDifference = Math.floor(760 - this.marginFlexPerPage)
      this.newMarginFlexPerPage = 720 - this.marginDifference

      switch (this.pagesLength) {
        case 9:
          this.newMarginFlexPerPage = 730 - this.marginDifference
          break
        case 8:
          this.newMarginFlexPerPage = 740 - this.marginDifference
          break
        case 7:
          this.numberPagesWidth = 220
          this.newMarginFlexPerPage = 740 - this.marginDifference
          break
        case 6:
          this.numberPagesWidth = 200
          this.newMarginFlexPerPage = 785 - this.marginDifference
          break
        case 5:
          this.numberPagesWidth = 180
          this.newMarginFlexPerPage = 800 - this.marginDifference
          break
        case 4:
          this.numberPagesWidth = 140
          this.newMarginFlexPerPage = 840 - this.marginDifference
          break
        case 3:
          this.numberPagesWidth = 120
          this.newMarginFlexPerPage = 880 - this.marginDifference
          break
        case 2:
          this.numberPagesWidth = 85
          this.newMarginFlexPerPage = 915 - this.marginDifference
          break
      }
    } else {
      this.numberPagesWidth = 240
      this.newMarginFlexPerPage = Math.floor(this.marginFlexPerPage)
    }
  }

  @Prop(Number)
  public readonly itemsLength!: number
  @Watch('itemsLength')
  onChangeLength(val: any) {
    if (val < 0) {
      this.resultado = 'Sin resultados'
    } else if (val === 1) {
      this.resultado = 'Resultado'
    }
  }

  @Prop(Number)
  public readonly page!: number

  @Prop({ type: String, default: 'back' })
  public readonly modeOrigin!: string

  @Prop({ type: Number, default: 5 })
  public readonly itemsPerPage!: number

  @Prop(String)
  public readonly storeModule!: string

  @Prop({ type: String, default: 'pagination.itemsPerPage' })
  public readonly storeMutatePerPage!: string

  @Prop({ type: String, default: 'pagination.page' })
  public readonly storeMutatePage!: string

  @Prop(Number)
  public readonly storePagination!: number

  @Prop({ type: Number, default: 760 })
  public readonly marginFlexPerPage!: number
  @Watch('marginFlexPerPage')
  onChangeMarginFlexPerPage(val: any) {
    this.newMarginFlexPerPage = val
  }

  mounted() {
    this.resultado = 'Resultados'
    this.numberPagesWidth = 240
    this.newMarginFlexPerPage = Math.floor(this.marginFlexPerPage)
  }

  handleChangePerPage($event: any) {
    Store.commit(this.storeModule + '/mutate', {
      property: this.storeMutatePerPage,
      with: $event
    })
    Store.dispatch(this.storeModule + '/fetchRoot', () => true)
  }
}
