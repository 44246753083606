
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class ClientSelect extends Vue {
  public async handleSelect($event: any) {
    Store.commit('products/mutate', {
      property: 'root.status',
      with: 'fetching'
    })
    Store.commit('products/mutate', {
      property: 'companyId',
      with: $event
    })

    await Store.dispatch('products/fetchRoot', () => true)
  }
}
