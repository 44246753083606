
import { Vue, Component } from 'vue-property-decorator'
import SearchBox from '@/components/AppBaseComponents/SearchBox.vue'
import ProductDevicesDataTable from '@/components/ProductComponents/Devices/ProductDevicesDataTable.vue'

@Component({
  components: {
    SearchBox,
    ProductDevicesDataTable
  }
})
export default class DetailsDeviceCard extends Vue {
  public error = false
  public loading = false
  public searchDevice = ''
}
