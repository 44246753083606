
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IOn } from '@/types'
import CompanyForm from '@/components/CompanyComponents/CompanyForm.vue'
import Store from '@/store'

@Component({
  components: {
    CompanyForm
  }
})
export default class CreateCompanyBtn extends Vue {
  public dialog = false

  @Prop()
  public readonly value!: any

  public async handleOpenDialog(on: IOn, event: Event) {
    Store.commit('openCompany/resetState')
    if (this.$refs.formCompany) {
      /* @ts-ignore */
      await this.$refs.formCompany.resetForm()
    }
    on.click(event)
  }

  public onClickClose() {
    this.dialog = false
  }
}
