
import { Vue, Component } from 'vue-property-decorator'

import DiagnosticTouch from '@/components/DiagnosticComponents/DiagnosticTouch.vue'
import DiagnosticScreen from '@/components/DiagnosticComponents/DiagnosticScreen.vue'
import DiagnosticCamera from '@/components/DiagnosticComponents/DiagnosticCamera.vue'

@Component({
  components: {
    DiagnosticTouch,
    DiagnosticScreen,
    DiagnosticCamera
  }
})
export default class DiagnosticList extends Vue {}
