
import { Vue, Component, Prop } from 'vue-property-decorator'
import Store from '@/store'
import _ from '@/main.utils'

@Component({
  components: {}
})
export default class DiagnosticPicker extends Vue {
  public tests = [0]
  public result = [
    {
      test_id: 0,
      phone_id: 0
    }
  ]

  public diagnostics = [
    {
      id: 1,
      name: 'Pantalla Táctil'
    },
    {
      id: 2,
      name: 'Botones'
    },
    {
      id: 3,
      name: 'Cámara Trasera'
    },
    {
      id: 4,
      name: 'Pantalla'
    },
    {
      id: 5,
      name: 'Wi-fi'
    },
    {
      id: 6,
      name: 'Bluetooth'
    },
    {
      id: 7,
      name: 'Flash'
    },
    {
      id: 8,
      name: 'Auricular'
    },
    {
      id: 9,
      name: 'GPS'
    },
    {
      id: 10,
      name: 'IMEI'
    }
  ]
  @Prop()
  public readonly ctests!: any

  @Prop()
  public readonly indexPhone!: any

  public mounted() {
    this.tests = this.ctests.phone_tests.map((a: any) => a.test_id)
    this.result = this.ctests.phone_tests
  }

  handleTest(test: any, status: boolean) {
    if (status) {
      this.result = _.union(this.result, [{ test_id: test, phone_id: this.ctests.id }])
    } else {
      this.result = _.reject(this.result, function (pt) {
        return pt.test_id == test
      })
    }
    Store.commit('temp/mutate', {
      property: 'changes',
      with: true
    })

    this.tests = this.result.map((a) => a.test_id)

    Store.commit('temp/mutate', {
      property: 'test.' + this.ctests.id + '_' + '.tests',
      with: this.result
    })
    Store.commit('temp/mutate', {
      property: 'test.' + this.ctests.id + '_' + '.phone_id',
      with: this.ctests.id
    })
  }
}
