
import { Vue, Component } from 'vue-property-decorator'
import Store from '@/store'

@Component({})
export default class LogoutListItem extends Vue {
  public async logout() {
    try {
      await Store.commit('session/resetState')
      window.location.replace(Store.getters['system/fullUrlAuth'])
    } catch (error) {
      Store.dispatch('system/showError', { error })
    }
  }
}
