
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { VForm } from '@/types'
import Store from '@/store'

import RouterGoBackBtn from '@/components/RouterComponents/RouterGoBackBtn.vue'
//import FileUploadFab from '@/components/TrustComponents/FileUploadFab.vue'

@Component({
  components: {
    RouterGoBackBtn
    //FileUploadFab
  }
})
export default class CompanyForm extends Vue {
  public error = false
  public loading = false

  @Prop(Boolean)
  public readonly newly!: boolean

  @Ref('companyForm')
  private readonly companyForm!: VForm

  public resetForm() {
    this.$nextTick(() => {
      this.companyForm.reset()
    })
  }

  public async handleFormSubmit(event: Event) {
    event.preventDefault()
    this.loading = true
    this.companyForm.resetValidation()

    setTimeout(async () => {
      if (this.companyForm.validate()) {
        try {
          if (this.newly) await Store.dispatch('openCompany/createCompanyFromDetails')
          else await Store.dispatch('openCompany/updateCompanyFromDetails')
          await Store.dispatch('companies/fetchRoot', () => true)
          this.$emit('cancel')
          Store.commit('openCompany/resetState')
        } catch (e) {
          this.error = true
        }
      }
      this.loading = false
    }, 1000)

    this.loading = false
  }
}
