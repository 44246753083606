import { render, staticRenderFns } from "./LatestBoughts.vue?vue&type=template&id=8be7ab9c&scoped=true"
import script from "./LatestBoughts.vue?vue&type=script&lang=ts"
export * from "./LatestBoughts.vue?vue&type=script&lang=ts"
import style0 from "./LatestBoughts.vue?vue&type=style&index=0&id=8be7ab9c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8be7ab9c",
  null
  
)

export default component.exports